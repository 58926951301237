import React from 'react'

export const Account = (props) => {
    return (
        <React.Fragment>
            <div id="memberMenu" className={`${props.accountclick ? "member-menu active" : "member-menu "}`}>
                <div className="close" onClick={() => { props.Clickaccount() }} />
                <div className="member-header bonuswallet">
                    <div className="member-header-content">
                        <div className="pic pic-lv1" id="memberMenu_userId">N</div>
                        <div className="infor">
                            <div className="account">
                            <span id="memberMenu_userName">dewan sha</span></div>
                            <div id="menu-vip-div" className="vip-points active" style={{ display: 'none' }}>
                                <ui-translate key="fe.text.vip.points" lang="en">VIP Points (VP)</ui-translate>
                                <span id="vip-points">0</span>
                                <a className="myvip-text" data-pagetype={27}>
                                    <ui-translate key="fe.text.profile.my.vip" lang="en">My Vip</ui-translate>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="memberFeatures" className="member-menu-content bonuswallet">
                    <div className="member-menu-box balance-box" id="memberBalance">
                        <div className="balance balance-row">
                            <div className="text">
                                <ui-translate key="fe.text.main_account_balance" lang="en">Main Wallet</ui-translate>
                                <div className="icon refresh" data-reloadbalance="true" />
                                <div className="icon eyes active" />
                            </div>
                            <span className="amount totalBalanceWallet" data-currency="₹"><i>0</i></span>
                            <span id="balanceHeader" style={{ display: 'none' }}>-</span>
                        </div>
                    </div>
                    <div className="member-list member-menu-box">
                        <div className="title">
                            <h2>
                                <span>
                                    <ui-translate key="fe.text.profile.moneyManagement" lang="en">Funds</ui-translate>
                                </span>
                            </h2>
                        </div>
                        <ul className="align-center">
                            <li className="deposit">
                                <a data-pagetype={1}>
                                    <p><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                                    </p>
                                </a>
                            </li>
                            <li className="withdrawal">
                                <a data-pagetype={2}>
                                    <p><ui-translate key="fe.text.withdrawal" lang="en">Withdrawal</ui-translate>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="member-list member-menu-box">
                        <div className="title">
                            <h2>
                                <span><ui-translate key="fe.text.profile.moneyRecords" lang="en">History</ui-translate></span>
                            </h2>
                        </div>
                        <ul className="align-center">
                            <li className="total">
                                <a data-pagetype={3}>
                                    <p>
                                        <ui-translate key="fe.text.bettingRecord" lang="en">Betting Records</ui-translate></p>
                                </a>
                            </li>
                            <li id="turnoverFeature" className="water">
                                <a data-pagetype={4}>
                                    <p>
                                        <ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate></p>
                                </a>
                            </li>
                            <li className="history">
                                <a data-pagetype={5}>
                                    <p><ui-translate key="fe.text.txn_records" lang="en">Transaction Records</ui-translate>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="member-list member-menu-box">
                        <div className="title">
                            <h2><span><ui-translate key="fe.text.backOffice.breadcrumbs.profile" lang="en">Profile</ui-translate></span></h2>
                        </div>
                        <ul className="align-center">
                            <li className="account">
                                <a data-pagetype={6}>
                                    <p><ui-translate key="fe.text.personal_info" lang="en">Personal Info</ui-translate></p>
                                </a>
                            </li>
                            <li id="verifyAccountFeature" className="kyc">
                                <a data-pagetype={16}>
                                    <p><ui-translate key="fe.text.profile.verify_account" lang="en">Verify Account</ui-translate></p>
                                </a>
                            </li>
                            <li className="resetpasswords">
                                <a data-pagetype={7}>
                                    <p>
                                        <ui-translate key="fe.text.reset_pass.dialog_header" lang="en">Reset password</ui-translate>
                                    </p>
                                </a>
                            </li>
                            <li className="mail">
                                <a data-pagetype={8}>
                                    <p>
                                        <ui-translate key="fe.text.inbox" lang="en">Inbox</ui-translate>
                                    </p>
                                    <span className="notice message_count">3</span>
                                </a>
                            </li>
                            <li id="referralFeature" className="recommendfriend">
                                <a data-pagetype={12}>
                                    <p>
                                        <ui-translate key="fe.text.raf.referral" lang="en">Referral</ui-translate>
                                    </p>
                                </a>
                            </li>
                            <li id="biometricMenu" className="biometric" hidden>
                                <a data-pagetype={24}>
                                    <p>
                                        <ui-translate key="fe.text.biometric" lang="en">Biometrics Login</ui-translate>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="member-list member-menu-box csicons" id="customerServiceFeatures">
                        <div className="title">
                            <h2>
                                <span>
                                    <ui-translate key="fe.text.contactUs" lang="en">Contact Us</ui-translate>
                                </span>
                            </h2>
                        </div>
                        <ul className="align-center">
                            <li className="whatsapp">
                                <a target="_blank" href="">
                                    <p>WhatsApp</p>
                                </a>
                            </li>
                            <li className="Email">
                                <a target="_blank" href="mailto:support@sky247.com">
                                    <p>Support</p>
                                </a>
                            </li>
                            <li className="telegram">
                                <a target="_blank" href="https://t.me/sky247supportbot">
                                    <p>Telegram Support Channel</p>
                                </a>
                            </li>
                            <li className="Email">
                                <a target="_blank" href="mailto:mailto:skypartners@skyinfotechltd.com">
                                    <p>Marketing</p>
                                </a>
                            </li>
                            <li className="telegram">
                                <a target="_blank" href="https://t.me/joinchat/yZdSnpV0SgNlZGFk">
                                    <p>Telegram Group</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="member-menu-logout">
                        <a onClick={() => { props.CheckshowLogin(false) }}>
                            <div className="text" >
                                <p>
                                <ui-translate key="fe.text.logout" lang="en">Log out</ui-translate>
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

import React from 'react'
import Carousel from './Carousel'
import {Sliding} from "./Sliding"
import {SlidingCasino} from './SlidingCasino'

export const Carausel = () => {
    return (
        <React.Fragment>
            <div id="pageContentBody">
    <div className="content" style={{paddingTop: '145px'}}>
      {/* this is template */}
     <Carousel/>
     <div class="post">
		<div class="post-icon"></div>
		<marquee onmouseover="this.stop();" onmouseout="this.start();">
			<ul id="main_runngin_msg_container">
      <li id="running-25005" style={{marginRight: '25px'}} title="Deposit">
      <span ui-key="webContent"><p>
      <span style={{fontSize:'14px'}}>
      <strong>Always confirm deposit account details before transferring funds -&nbsp;कृपया पैसा जमा करने से पहले हमेशा जमा खाते के विवरण की जांच करें</strong></span></p>
</span></li></ul>
		</marquee>
	</div>
       <div style={{display: 'none'}}>
        <ul>
          <li id="main_announcement_template" className="banner data-image" data-image style={{backgroundImage: 'url("")'}}><a data-pk /></li>
        </ul>
        <ul>
          <li id="main_runngin_msg_template" style={{marginRight: '25px'}}><span ui-key="webContent">-</span></li>
        </ul>
      </div>
      <div className="container-1200">
        <div className="main-wrap-sport-1st">
          {/* Sport Event Left*/}
          <div id="main_wrap_sport_left" className="main-wrap-sport left">
            <h3>Sky247 Club
            </h3>
           
           <Sliding/>
            <div className="navigation-1">
              <a className="flex-next slick-arrow" style={{display: 'block'}}>next</a>
              <a className="flex-prev slick-arrow" style={{display: 'block'}}>prev</a>
            </div>
          </div>
          {/* Sport Event end*/}
          {/* Sport Event right*/}
          <div id="main_wrap_sport_right" className="main-wrap-sport right">
            <h3>Sky247 Club
            </h3>
            <div className="sport-event">
            <SlidingCasino/>
            </div>
          
           <div className="navigation-2">
              <a className="flex-next slick-arrow" style={{display: 'block'}}>next</a>
              <a className="flex-prev slick-arrow" style={{display: 'block'}}>prev</a>
            </div>
          </div>
          {/* Sport Event end*/}
          <div style={{display: 'none'}}>
            <ul>
              <li id="main_wrap_sport_template" data-id onclick="SportHandler.clickSportSlider(this);">
                <div className="sport-img data-image" data-image style={{backgroundImage: 'url("")'}} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
        </React.Fragment>
    )
}

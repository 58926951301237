import React from 'react'

export const TransactionRecords = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
    <main className="container-player-wrapper">
        <div className="container-player-group">
            <div className="player-title-box">
                <h2>
                    <ui-translate key="fe.text.txn_records" lang="en">Transaction Records</ui-translate>
                </h2>
            </div>
            <div className="main-table-info active">
                <div className="filter-box">
                    <div className="filter-group">
                        <label>
              <ui-translate key="fe.text.report.txnType" lang="en">Type</ui-translate>
            </label>
                        <ul className="choose-box">
                            <li>
                                <input type="radio" name="txnType" id="txnAll" defaultValue={-1} defaultChecked />
                                <label htmlFor="txnAll">
                  <ui-translate key="fe.text.all" lang="en">All</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="txnType" id="txnDeposit" defaultValue={0} />
                                <label htmlFor="txnDeposit">
                  <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="txnType" id="txnWithdraw" defaultValue={1} />
                                <label htmlFor="txnWithdraw">
                  <ui-translate key="fe.text.withdrawal" lang="en">Withdrawal</ui-translate>
                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-group">
                        <label>
              <ui-translate key="fe.text.status" lang="en">Status</ui-translate>
            </label>
                        <ul className="choose-box">
                            <li>
                                <input type="radio" name="txnStatus" id="stateAll" defaultValue={-1} defaultChecked />
                                <label htmlFor="stateAll">
                  <ui-translate key="fe.text.all" lang="en">All</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="txnStatus" id="state0" defaultValue={0} />
                                <label htmlFor="state0">
                  <ui-translate key="fe.text.processing" lang="en">Processing</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="txnStatus" id="state1" defaultValue={1} />
                                <label htmlFor="state1">
                  <ui-translate key="fe.text.success" lang="en">Success</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="txnStatus" id="state2" defaultValue={2} />
                                <label htmlFor="state2">
                  <ui-translate key="fe.text.fail" lang="en">Fail</ui-translate>
                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-group">
                        <label>
              <ui-translate key="fe.text.date" lang="en">Date</ui-translate>
            </label>
                        <ul className="choose-box">
                            <li>
                                <input type="radio" name="dateOption" id="dateOption0" defaultChecked defaultValue={0} />
                                <label htmlFor="dateOption0">
                  <ui-translate key="fe.text.today" lang="en">Today</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="dateOption" id="dateOption1" defaultValue={1} />
                                <label htmlFor="dateOption1">
                  <ui-translate key="fe.text.yesterday" lang="en">Yesterday</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="dateOption" id="dateOption7" defaultValue={7} />
                                <label htmlFor="dateOption7">
                  <ui-translate key="fe.text.lastDays" params="[7]" lang="en">Last 7 days</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="dateOption" id="dateOptionAll" defaultValue={-1} />
                                <label htmlFor="dateOptionAll">
                  <ui-translate key="fe.text.all" lang="en">All</ui-translate>
                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-btn-box">
                        <button className="btn-5th-xs query-txn-report">
              <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
            </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="table-change-wrap">
            <div className="table-change-inner" style={{left: '0px'}}>
                {/*have value*/}
                <div className="container-player-group no-info">
                    <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                        <div className="dataTables_length" id="DataTables_Table_2_length"><label><select onchange="setTimeout(function(){ if(!$('.table-box').DataTable().fnPageChange) { return; } $('.table-box').DataTable().fnPageChange(0);}, 10);"><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> records per page</label></div>
                        <div
                            id="DataTables_Table_2_processing" className="dataTables_processing" style={{display: 'none'}}>Processing...</div>
                    <table className="table-box display dataTable no-footer" id="DataTables_Table_2" role="grid" aria-describedby="DataTables_Table_2_info" style={{width: '888px'}}>
                        <thead>
                            <tr className="table-title" role="row">
                                <th className="column-s sorting_disabled" style={{width: '16px'}} rowSpan={1} colSpan={1} aria-label="&nbsp;">&nbsp;</th>
                                <th className="column-s sorting_disabled" rowSpan={1} colSpan={1} aria-label="
						Type
					" style={{width: '131px'}}>
                                    <ui-translate key="fe.text.report.txnType" lang="en">Type</ui-translate>
                                </th>
                                <th className="column-s sorting_disabled" rowSpan={1} colSpan={1} aria-label="
						NO
					" style={{width: '131px'}}>
                                    <ui-translate key="ui.text.report.txnNo" lang="en">NO</ui-translate>
                                </th>
                                <th className="column-s sorting" tabIndex={0} aria-controls="DataTables_Table_2" rowSpan={1} colSpan={1} aria-label="
						Amount
					: activate to sort column ascending" style={{width: '131px'}}>
                                    <ui-translate key="fe.text.amount" lang="en">Amount</ui-translate>
                                </th>
                                <th className="column-time sorting_desc" tabIndex={0} aria-controls="DataTables_Table_2" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="
						Txn Date
					: activate to sort column ascending" style={{width: '175px'}}>
                                    <ui-translate key="fe.text.report.txnDate" lang="en">Txn Date</ui-translate>
                                </th>
                                <th className="column-s sorting" tabIndex={0} aria-controls="DataTables_Table_2" rowSpan={1} colSpan={1} aria-label="
						Status
					: activate to sort column ascending" style={{width: '131px'}}>
                                    <ui-translate key="fe.text.status" lang="en">Status</ui-translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="odd">
                                <td valign="top" colSpan={6} className="dataTables_empty">
                                    <div className="no-info-txt">
                                        <div className="no-info-img"><img className="img-load" src="/images/player/table/no-deposit.svg" /></div>
                                        <p>
                                            <ui-translate key="fe.text.noData" lang="en">No Data</ui-translate>
                                        </p>
                                        <a className="btn-player-secendary btn-5th-xs" style={{width: 'fit-content'}} data-pagetype={1}>
                                            <ui-translate key="fe.text.deposit_now" lang="en">Deposit now</ui-translate>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="dataTables_info" id="DataTables_Table_2_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_2_paginate" style={{display: 'none'}}><a className="paginate_button previous disabled" aria-controls="DataTables_Table_2" data-dt-idx={0} tabIndex={-1} id="DataTables_Table_2_previous">Previous</a><span /><a className="paginate_button next disabled" aria-controls="DataTables_Table_2"
                            data-dt-idx={1} tabIndex={-1} id="DataTables_Table_2_next">Next</a></div>
                </div>
            </div>
            <div id="txnDetailModal" className="container-player-group hide">
                <div id="txn-bank-name" className="main-table-box">
                    <a className="btn-return">
                        <ui-translate key="fe.text.go_back" lang="en">back</ui-translate>
                    </a>
                    <div className="bank-name-block"><img src /><span>-</span></div>
                </div>
                <div className="details-box">
                    <div id="txn-progress" className="transaction-details-wrap timeline">
                        <div className="title">
                            <h3>
                                <ui-translate key="fe.msg.dialog.transaction.progress" lang="en">Transaction Progress</ui-translate>
                            </h3>
                        </div>
                        <div id="timeline" className="timeline-wrap">
                        </div>
                    </div>
                    <div id="txn-detail" className="transaction-details-wrap details">
                        <div className="title">
                            <h3>
                                <ui-translate key="fe.msg.dialog.transaction.details" lang="en">Transaction Record Details</ui-translate>
                            </h3><i><span /></i>
                        </div>
                        <div id="details-content" className="details-wrap">
                        </div>
                        <button id="txn-revert" className="btn-default"><ui-translate key="fe.text.revert" lang="en">Revert</ui-translate></button>
                        <div className="tips">
                            <ui-translate key="fe.text.reSubmitRemind" lang="en">You can only edit once every 5 minutes.</ui-translate>
                        </div>
                        <button id="txn-reSubmit" className="btn-default">
                <div className="countdown btn-gray">
                  <div className="timer" />
                  <span />
                </div>
                <ui-translate key="fe.text.reSubmit" lang="en">Edit and resubmit</ui-translate>
              </button>
                    </div>
                </div>
            </div>
        </div>
</div>
</main>
</div>
        </React.Fragment>
    )
}

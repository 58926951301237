import React,{useState,useEffect} from 'react'

import hotslot from "./SlotImages/JILI-SLOT-029.jpeg"
import jdb123 from "./SlotImages/15011.jpeg"
// import CraySeven from  
import  Nightout from "./SlotImages/hb.jpeg"
import SMG_5StarKnockout from "./SlotImages/SMG_5StarKnockout.png";
import DiamondsWowpot from "./SlotImages/DiamondsWowpot.png"
import appower from "./SlotImages/appower.jpeg"
import ashadv from "./SlotImages/ashadv.jpeg"
import aogetroy from "./SlotImages/aogetroy.jpeg"
import kodiakKingdom from "./SlotImages/kodiakKingdom.png"
import Zeus from "./SlotImages/zeus.jpeg"
import aogmm from "./SlotImages/aogmm.jpeg"
import aogmt1 from "./SlotImages/aogmt1.png"
import americanBlackjack from "./SlotImages/blackjack-us.jpeg"
export const Slotbanner = () => {
   
    return (
        <React.Fragment>
            <div className="container-1200">
    <div className="games-container">
        {/*feature game*/}
        <div className="week-top-game-wrap col-">
            <div className="week-top-title">
            </div>
            <div className="money-1" />
            <div className="money-2" />
            <div className="money-3" />
            <div className="games-slide slotpage">
                      </div>
        </div>
        {/*jackpot*/}
        <div className="total-award-wrap data-image" data-image="/images/slotgame/en-red-bg.png" id="rewarder">
            <div className="tag" />
            <div className="money-left" />
            <div className="child" />
            <div className="money"><i>₹</i><span id="jackpotMoney">104,404,817.94</span></div>
        </div>
        <ul id="gamePage_container" className="total-games-group slotpage">
            <li id="game-5412" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="JDB" data-type="Slot" data-code={15011} data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="JDB" data-type="Slot" data-code={15011} data-trialplaytype={0} style={{display: 'none'}}>
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">JDB Sun Archer</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={jdb123} alt="Sun Archer" />
            </li>
            <li id="game-15554" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="MG" data-type="Slot" data-code="SMG_5StarKnockout" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="MG" data-type="Slot" data-code="SMG_5StarKnockout" data-trialplaytype={0} style={{display: 'none'}}>
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">MG 5 Star Knockout</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={SMG_5StarKnockout} alt="5 Star Knockout" />
            </li>
            <li id="game-12275" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="MG" data-type="Slot" data-code="SMG_9BlazingDiamondsWowpot" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="MG" data-type="Slot" data-code="SMG_9BlazingDiamondsWowpot" data-trialplaytype={0} style={{display: 'none'}}>
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">MG 9 Blazing Diamonds Wowpot</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={DiamondsWowpot} alt="9 Blazing Diamonds Wowpot" />
            </li>
            <li id="game-7383" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" >
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" >
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT A Night Out</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={Nightout} alt="A Night Out" />
            </li>
            <li id="game-7289" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="ashadv" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="ashadv" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=ashadv">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Adventures in Wonderland</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={ashadv} alt="Adventures in Wonderland" />
            </li>
            <li id="game-10514" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="gpas_appower_pop" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="gpas_appower_pop" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=gpas_appower_pop">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Age Of The Gods : Apollo Power</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={appower} alt="Age Of The Gods : Apollo Power" />
            </li>
            <li id="game-10513" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="gpas_aogetroy_pop" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="gpas_aogetroy_pop" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=gpas_aogetroy_pop">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Age Of The Gods : Epic Troy</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={aogetroy} alt="Age Of The Gods : Epic Troy" />
            </li>
            <li id="game-7238" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="zeus" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="zeus" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=zeus">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Age Of The Gods : King Of Olympus</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={Zeus} alt="Age Of The Gods : King Of Olympus" />
            </li>
            <li id="game-10505" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="aogmm" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="aogmm" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=aogmm">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Age Of The Gods : Medusa &amp; Monsters</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={aogmm} alt="Age Of The Gods : Medusa & Monsters" />
            </li>
           
            <li id="game-10508" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="aogrots" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="aogrots" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=aogrots">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Age Of The Gods : Ruler Of the Sky</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={aogmt1} alt="Age Of The Gods : Ruler Of the Sky" />
            </li>
                  <li id="game-7316" className="games-icon">
                <div className="mask">
                    <div className="mask-inner">
                        <span className="mask-btn-wrap">
              <a className="btn-default-xs playBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="ftsis" data-play>
                Play Game</a>
              <a className="btn-2nd-xs trialPlayBtn" data-vendor="SilkStonePT" data-type="Slot" data-code="ftsis" data-trialplaytype={1} data-href="/guest/viewGame?t=Slot&v=SilkStonePT&g=ftsis">
                Free Trial</a>
            </span>
                    </div>
                </div>
                <div className="tag" ui-key="tag" />
                <div className="game-inner">
                    <div className="game-group">
                        <h4 ui-key="fullName">PT Fate Sisters</h4>
                    </div>
                </div>
                <img ui-key="gameIcon" src={kodiakKingdom} alt="Fate Sisters" />
            </li>
                  
        </ul>
        <div id="noGame" className="iplimit-card-wrap no-game-container" style={{display: 'none'}}>
            <div className="left-inner data-image" data-image="/images/slotgame/img-no-game.png" style={{backgroundImage: 'url("/images/slotgame/img-no-game.png")'}} />
            <div className="right-inner">
                <h3>SORRY</h3>
                <h4>Sorry, no matching games found</h4>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

import React from 'react'

export const Referral = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
    <main className="container-player-wrapper">
        <div className="container-player-group">
            <div className="player-title-box">
                <h2>
                    <ui-translate key="fe.text.raf.referral" lang="en">Referral</ui-translate>
                </h2>
            </div>
            <div className="tab-secondary-box">
                <ul id="referralTabContainer" className="tabs">
                    <li id="referralInfoTab" className="active">
                        <input type="radio" name="friend" id="referralInfoTabRadio" defaultChecked />
                        <label htmlFor="referralInfoTabRadio">
              <ui-translate key="ui.text.raf.my_referral" lang="en">My Referral</ui-translate>
            </label>
                    </li>
                    <li id="referralReportTab">
                        <input type="radio" name="friend" id="referralReportTabRadio" />
                        <label htmlFor="referralReportTabRadio">
              <ui-translate key="ui.text.raf.referral_bonus" lang="en">Referral Bonus</ui-translate>
            </label>
                    </li>
                </ul>
            </div>
            <div className="table-container">
                <ul id="referralPaneContainer" className="table-content">
                    <li id="referralInfoContainer" className="tab-pane active">
                        <div id="referralInfoTips" className="tips-group" style={{display: 'block'}}>
                            <b>Refer A Friend To Get 247 INR</b>
                            <p>Share your referral code to get bonus once your friend completed condition</p>
                        </div>
                        <div className="referral-code-box">
                            <div className="referral-code">
                                <label>
                  <ui-translate key="ui.text.raf.friendReferCode" lang="en">Refer Code</ui-translate>
                </label>
                                <div className="code-copy input-box">
                                    <input id="referralCode" type="text" defaultValue="ie7jEc" readOnly />
                                    <div className="tips-txt">
                                        <ui-translate key="ui.text.copied" lang="en">Copied</ui-translate>
                                    </div>
                                    <div id="copyButton" className="btn-copy">
                                        <i className="data-image" data-image="/images/player/recommend-friends/icon-copy.png" id="recommend-friends"  />
                                    </div>
                                </div>
                            </div>
                            <div className="referral-link">
                                <label>
                  <ui-translate key="ui.text.raf.referLink" lang="en">Referral Link</ui-translate>
                </label>
                                <div className="invite-friend-box">
                                    <button className="btn-default">
                    <ui-translate key="fe.text.raf.share" lang="en">Share</ui-translate>
                  </button>
                                    <ul className="invite-friend-list">
                                        <li className="copy-link" id="shareMenuCopy" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-copy-link" />
                                            <p>
                                                <ui-translate key="ui.text.copy_link" lang="en">Copy Link</ui-translate>
                                            </p>
                                            <div className="tips-txt">
                                                <ui-translate key="ui.text.copied" lang="en">Copied</ui-translate>
                                            </div>
                                        </li>
                                        <li data-sharer="facebook" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-fb" />
                                            <p>Facebook</p>
                                        </li>
                                        <li data-sharer="line" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-line" />
                                            <p>Line</p>
                                        </li>
                                        <li data-sharer="email" data-title data-url="http://www.sky247.com/?refcode=ie7jEc" data-subject="You're invited." data-to>
                                            <div className="share-link share-mail" />
                                            <p>Mail</p>
                                        </li>
                                        <li data-sharer="skype" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-skype" />
                                            <p>Skype</p>
                                        </li>
                                        <li data-sharer="telegram" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-telegram" />
                                            <p>Telegram</p>
                                        </li>
                                        <li data-sharer="whatsapp" data-title data-url="http://www.sky247.com/?refcode=ie7jEc">
                                            <div className="share-link share-whatsapp" />
                                            <p>Whatsapp</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="referral-reward-info">
                            <div className="referral-reward-left">
                                <h6>FREE BET ₹
                                    <span>247</span>
                                </h6>
                                <div className="input-box">
                                    <label>
                    <ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate>
                  </label>
                                    <input type="text" defaultValue={25247.00} readOnly />
                                </div>
                                <div className="input-box">
                                    <label>
                    <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                  </label>
                                    <input type="text" defaultValue={0} readOnly />
                                </div>
                            </div>
                            <div className="referral-reward-right">
                                <h6>
                                    <ui-translate key="ui.text.raf.yourReferStatus" lang="en">Your Referral Status</ui-translate>
                                </h6>
                                <ul>
                                    <li>
                                        <p>0
                                        </p>
                                        <label>
                      <ui-translate key="ui.text.raf.friendAcceptCount" lang="en">Friend's Accepted</ui-translate>
                    </label>
                                    </li>
                                    <li>
                                        <p>0
                                        </p>
                                        <label>
                      <ui-translate key="ui.text.raf.friendCompleteCount" lang="en">Friend's Complete</ui-translate>
                    </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li id="referralReportContainer" className="tab-pane">
                        <div id="referralReportFormSection" className="filter-box recommend-friends-filter">
                            <div className="filter-group">
                                <label htmlFor="dateRangePickerReferral">
                  <ui-translate key="fe.text.date" lang="en">Date</ui-translate>
                </label>
                                <input id="dateRangePickerReferral" name="dates" className="filter-date-input active" type="text" />
                                <input type="hidden" id="startDate" />
                                <input type="hidden" id="endDate" />
                            </div>
                            <div className="filter-btn-box">
                                <button className="btn-3rd-xs">
                  <ui-translate key="ui.text.reset" lang="en">Reset</ui-translate>
                </button>
                                <button className="btn-5th-xs btn-submit">
                  <ui-translate key="ui.text.submit" lang="en">Submit</ui-translate>
                </button>
                            </div>
                        </div>
                        <div id="referralReport">
                            <table className="display recommend-friends-table">
                                <thead>
                                    <tr className="table-title">
                                        <th>Friends</th>
                                        <th>
                                            <ui-translate key="ui.text.raf.report.linkedTime" lang="en">Linked Time</ui-translate>
                                        </th>
                                        <th>
                                            <ui-translate key="ui.text.raf.report.totalDeposit" lang="en">Total Deposit</ui-translate>
                                        </th>
                                        <th>
                                            <ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate>
                                        </th>
                                        <th>
                                            <ui-translate key="ui.text.raf.report.bonusAmount" lang="en">Bonus Amount</ui-translate>
                                        </th>
                                        <th>
                                            <ui-translate key="ui.text.raf.report.status" lang="en">Status</ui-translate>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody />
                            </table>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </main>
    <div id="referralBonusMessageSection" className="guild-list-wrapper" style={{float: 'right', display: 'none'}}>
        <aside className="record-aside-box terms-sdide-box more">
            <div className="list-aside active">
                <h4>
                    <ui-translate key="fe.text.terms" params="[&quot;&&quot;]" lang="en">Terms &amp; Conditions</ui-translate>
                </h4>
                {/* do not use "player-list" class, it's for deposit/transfer/withdraw records */}
                <div id="referralBonusMessageContainer" className="player-guild-cont" />
                <a className="link-more" data-messagetype={10} data-id="referralBonusMessage">
                    <ui-translate key="ui.text.more" lang="en">More</ui-translate>
                </a>
            </div>
        </aside>
    </div>
    <template id="referralShareText" />
    <link href="/css/plugins/daterangepicker.css" rel="stylesheet" />
</div>
        </React.Fragment>
    )
}

import React from 'react'
import providerawcmrt from "../images/Social/provider-awcmrt.png";
import awcmjili from "../images/Social/provider-awcmjili.png";
import providerawcmpp from "../images/Social/provider-awcmpp.png";
import netent from "../images/Social/provider-netent.png";
import providerawcmsg from "../images/Social/provider-awcmsg.png";
import providerpg from "../images/Social/provider-pg.png";
import providerjdb from "../images/Social/provider-jdb.png";
import providermg from "../images/Social/provider-mg.png";
const Sportslots = () => {
    return (
        <React.Fragment>
            <div _ngcontent-serverapp-c66 className="nav-wrap ng-star-inserted">
                <div _ngcontent-serverapp-c66 id="nav-title" className="content-title">
                    <h2 _ngcontent-serverapp-c66>
                        <span _ngcontent-serverapp-c66>Slots</span>
                    </h2>
                </div>
                <div _ngcontent-serverapp-c66 mcddragmove className="nav-content-wrap">
                    <div _ngcontent-serverapp-c66 className="nav-content-inner" >
                        <div _ngcontent-serverapp-c66 className="content-box ng-star-inserted" style={{}}>
                            <div _ngcontent-serverapp-c66 className="layout-brand ng-star-inserted">
                                <div _ngcontent-serverapp-c66 className="card1">
                                    <ul _ngcontent-serverapp-c66>
                                        <li _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="RT">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmrt" src={providerawcmrt} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>RT</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="JILI">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmjili" src={awcmjili} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>JILI</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="PP">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmpp" src={providerawcmpp} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>PP</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="NETENT">
                                                <img _ngcontent-serverapp-c66 alt="provider-netent" src={netent} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>NETENT</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="SG">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmsg" src={providerawcmsg} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>SG</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="PG">
                                                <img _ngcontent-serverapp-c66 alt="provider-pg" src={providerpg} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>PG</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="JDB">
                                                <img _ngcontent-serverapp-c66 alt="provider-jdb" src={providerjdb} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>JDB</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="MG">
                                                <img _ngcontent-serverapp-c66 alt="provider-mg" src={providermg} className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>MG</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="WorldMatch">
                                                <img _ngcontent-serverapp-c66 alt="provider-worldmatch" src="/assets/images/brand/white/provider-worldmatch.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>WorldMatch</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="FC">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmfc" src="/assets/images/brand/white/provider-awcmfc.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>FC</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="RICH88">
                                                <img _ngcontent-serverapp-c66 alt="provider-rich88" src="/assets/images/brand/white/provider-rich88.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>RICH88</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="PT">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmpt" src="/assets/images/brand/white/provider-awcmpt.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>PT</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="KA">
                                                <img _ngcontent-serverapp-c66 alt="provider-ka" src="/assets/images/brand/white/provider-ka.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>KA</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="FASTSPIN">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmfastspin" src="/assets/images/brand/white/provider-awcmfastspin.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>FASTSPIN</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="PLAYSTAR">
                                                <img _ngcontent-serverapp-c66 alt="provider-ps" src="/assets/images/brand/white/provider-ps.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>PLAYSTAR</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="7MOJ">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmsevenmojos" src="/assets/images/brand/white/provider-awcmsevenmojos.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>7MOJ</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="P8">
                                                <img _ngcontent-serverapp-c66 alt="provider-awcmp8" src="/assets/images/brand/white/provider-awcmp8.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>P8</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="CQ9">
                                                <img _ngcontent-serverapp-c66 alt="provider-cq9" src="/assets/images/brand/white/provider-cq9.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>CQ9</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66 tabIndex={-1} vender="Joker">
                                                <img _ngcontent-serverapp-c66 alt="provider-joker" src="/assets/images/brand/white/provider-joker.png?v=1675786428669" className="ng-star-inserted" />{/**/}{/**/}{/**/}
                                                <p _ngcontent-serverapp-c66>Joker</p>
                                            </a>
                                        </li>{/**/}
                                        <li
                                            _ngcontent-serverapp-c66 className="ng-star-inserted" />{/**/}
                                    </ul >
                                </div >
                            </div > {/**/}{/**/}
                        </div > {/**/}{/**/} {/**/}
                    </div >
                </div >
            </div >
        </React.Fragment >
    )
}

export default Sportslots

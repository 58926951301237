import React from 'react'
import provider from "./MobileImage/provider-jdb.png"
import providermg from "./MobileImage/provider-mg.png"
import providerawcmjili from "./MobileImage/provider-awcmjili.png"
import providerpg from "./MobileImage/provider-pg.png"
import providersilkstonept from "./MobileImage/provider-silkstonept.png"
import providerawcmsexy from "./MobileImage/provider-awcmsexy.png"
import providerawcmkm from "./MobileImage/provider-awcmkm.png"
import providerbpoker from "./MobileImage/provider-bpoker.png"
import providersaba from "./MobileImage/provider-saba.png"
import providerludo from "./MobileImage/provider-ludo.png"
import providerevo from "./MobileImage/provider-evo.png"
import providerawcmsevenmojos from "./MobileImage/provider-awcmsevenmojos.png"
export const Menusecond = (props) => {
    return (
        <React.Fragment>
            <div className="menu-second">
    <ul  className={`${(props.Sportstab==1) ? "sport active" : ""}`} id="leftMenu_sport">
        <li>
            <a data-play="/player/playGame?t=sport&v=CRICKET" data-vendor="CRICKET">
                <div className="iconSport exchange" />
                <p>Exchange</p>
            </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=sport&v=Saba&act=50" data-vendor="Saba">
                <div className="iconSport sportbook" />
                <p>Sports Book</p>
            </a>
        </li>
        <li style={{display: 'none'}}>
            <a data-play="/player/playGame?t=sport&v=Saba&act=180" data-vendor="Saba">
                <div className="iconSport virtual" />
                <p>Virtual</p>
            </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=sport&v=Saba&act=47" data-vendor="Saba">
                <div className="iconSport kabaddi" />
                <p>Kabaddi</p>
            </a>
        </li>
    </ul>
    <ul  className={`${(props.Sportstab==2) ? "games active" : ""}`} id="leftMenu_slot">
        <li>
            <a data-pagetype={10} data-vendor="JDB">
        <img src={provider} />
        <p>JDB</p>
      </a>
        </li>
        <li>
            <a data-pagetype={10} data-vendor="MG">
        <img src={providermg} />
        <p>MG</p>
      </a>
        </li>
        <li>
            <a data-pagetype={10} data-vendor="AWCMJILI">
        <img src={providerawcmjili} />
        <p>JILI</p>
      </a>
        </li>
        <li>
            <a data-pagetype={10} data-vendor="PG">
        <img src={providerpg} />
        <p>PG</p>
      </a>
        </li>
        <li>
            <a data-pagetype={10} data-vendor="SilkStonePT">
        <img src={providersilkstonept} />
        <p>PT</p>
      </a>
        </li>
        <li>
            <a data-pagetype={10} data-vendor="AWCMSEVENMOJOS">
        <img src={providerawcmsevenmojos} />
        <p>7MOJ</p>
      </a>
        </li>
    </ul>
    <ul className="casino"  className={`${(props.Sportstab==3) ? "games active" : ""}`}  id="leftMenu_casino">
        <li>
            <a data-play="/player/playGame?t=casino&v=AWCMSEXY" data-vendor="AWCMSEXY">
        <img src={providerawcmsexy} />
        <p>SEXY</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=casino&v=AWCMSEVENMOJOS" data-vendor="AWCMSEVENMOJOS">
        <img src={providerawcmsevenmojos}/>
        <p>7MOJ</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=casino&v=EVO" data-vendor="EVO">
        <img src={providerevo} />
        <p>EVO</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=casino&v=MG" data-vendor="MG">
        <img src={providermg} />
        <p>MG</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=casino&v=SilkStonePT" data-vendor="SilkStonePT">
        <img src={providersilkstonept}/>
        <p>PT</p>
      </a>
        </li>
    </ul>
    <ul className="tablegame" className={`${(props.Sportstab==4) ? "games active" : ""}`}  style={{maxWidth: 'initial', borderStyle: 'initial'}} id="leftMenu_table">
        <li>
            <a data-pagetype={11} data-vendor="AWCMKM">
        <img src={providerawcmkm} />
        <p>KM</p>
      </a>
        </li>
        <li>
            <a data-pagetype={11} data-vendor="AWCMJILI">
        <img src={providerawcmjili} />
        <p>JILI</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=p2p&v=BPOKER" data-vendor="BPOKER">
        <img src={providerbpoker} />
        <p>BPOKER</p>
      </a>
        </li>
        <li>
            <a data-play="/player/playGame?t=p2p&v=LUDO" data-vendor="LUDO">
        <img src={providerludo} />
        <p>LUDO</p>
      </a>
        </li>
    </ul>
    <ul className="lottery" className={`${(props.Sportstab==5) ? "games active" : ""}`} id="leftMenu_lottery">
        <li>
            <a data-play="/player/playGame?t=sport&v=Saba&act=161" data-vendor="Saba">
        <img src={providersaba}/>
        <p>Saba</p>
      </a>
        </li>
    </ul>
</div>
        </React.Fragment>
    )
}

import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Ipl1 from '../images/Banner/image_1.jpeg'
import Ipl2 from '../images/Banner/image_2.jpeg'
import Soccer from '../images/Banner/image_3.jpeg'
// import Tennis from '../images/photo14.png'


const photos = [
   {
      name:'photo 1',
      url:[Ipl1]
   },
   {
   name:'photo 2',
   url:[Ipl2]
},
{
   name:'photo 3',
   url:[Soccer]
},
// {
//    name:'photo 4',
//    url:[Tennis]
// }


 
 ]

 const settings ={
    dots: true,
    slide:true,
    infinite:true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll:1,
    className:'slidesN'
 }


export default function Carousel() {
    return (
        <React.Fragment>
            <Slider  {...settings} style={{height:'34vw'}}>
                  {photos.map((photo,id)=>{
                     return(
                     <div key ={id}>
                        <img width="100%" src={photo.url} alt="cric" style={{height:'34vw'}}/>
                     </div>
                        )
                  })}
                  </Slider>
        </React.Fragment>
    )
}

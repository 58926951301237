import React from 'react'

export const VerifyPhone = (props) => {
    return (
        <React.Fragment>
            <div id="pop-bg" class="pop-bg" style={{display: 'block'}}></div>
            <div id="verification-pop-phone" className="pop-wrap form-pop safe-verification active Ani" data-verifying="false">
    <div className="pop-title">
        <h3>
            <ui-translate key="fe.text.profile.verify" lang="en">Verify</ui-translate>
            <ui-translate key="fe.text.account.phoneNumber" lang="en">Phone Number</ui-translate>
        </h3>
        <a className="btn-close" onClick={()=>(props.Verifypopupfalse())} />
    </div>
    <div className="pop-inner">
        <div className="input-group">
            <div className="wrong phone-input">
                <label htmlFor="phone_number">
          <ui-translate key="fe.text.account.phoneNumber" lang="en">Phone Number</ui-translate>
        </label>
                <div className="flag">
                    <img alt="" name="countryImage" src="/images/flag/IN.png" />
                    <span name="countryCallingCode">91</span>
                </div>
             
                <input className="phone" type="text" name="phone_number" id="phone_number" readOnly />
            </div>
            <div className="verification-input">
                <label htmlFor="phone_verify_code">
          <ui-translate key="fe.text.plz_input_verification_code" lang="en">Please input verification code</ui-translate>
        </label>
                <div className="vcode-input">
                    <input type="text" name="verifyCode" id="phone_verify_code" />
                     {/* class: show */}
                    <span className="verify_timer vcode-sec">0s</span>
                    <span className="resend-set resendVerifyCode" data-contacttype={2}>Send Validation Code</span>
                    <div className="btn-vcode btn-2nd sendVerifyCode" data-contacttype={2}>
                        <div className="sms-svg-group">
                            <div className="svg-phone" />
                            <div className="svg-mail-group">
                                <ul className="line">
                                    <li style={{transform: 'translate(5px, 0px)'}} />
                                    <li style={{transform: 'translate3d(5px, 0px, 0px)'}} />
                                    <li style={{transform: 'translate(4.8973px, 0px)'}} />
                                </ul>
                                <div className="svg-mail" />
                            </div>
                        </div>
                        <p>
                            <ui-translate key="fe.text.profile.send_verify" lang="en">Send Validation Code</ui-translate>
                        </p>
                    </div>
                </div>
                <span className="error" style={{display: 'none'}} />
            </div>
        </div>
        <div className="pop-btn-box">
            <a className="btn-default-xs submit" data-contacttype={2}>
                <ui-translate key="fe.text.confirm" lang="en">Confirm</ui-translate>&nbsp;
                <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
            </a>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

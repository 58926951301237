import Slider from 'react-slick'
import React from 'react'
import Ipl1 from '../images/Banner/slide1.jpeg'
import Ipl2 from "../images/Banner/slide2.jpeg"


const Photos=[
    {
        name: 'banner1',
        url: [Ipl1]
    },
    {
        name: 'banner2',
        url: [Ipl2]
    },
 
]
const settings ={
    dots: false,
    slide:true,
    infinite:true,
    autoplay: false,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll:1,
    className:'slidesN'
 }

export const Sliding = () => {
    
    return (
        <React.Fragment>
            <Slider  {...settings}>
                  {Photos.map((photo,id)=>{
                     return(
                     <div key ={id}>
                        <img width="100%" src={photo.url} alt="cric"/>
                     </div>
                        )
                  })}
                  </Slider>
                  </React.Fragment>
    )
}

import React, { useState } from 'react'
import transparent from "./SportsImage/transparent.gif"
export const SoccerFullmarket = () => {
    const [Tabmenu, setTabmenu] = useState(0)
const [clickToexpand, setclickToexpand] = useState(false)
const [ClickPremiumFancy, setClickPremiumFancy] = useState(false)

const Clicktoexpanded=()=>{ 
    setclickToexpand(!clickToexpand)
}
const PremiumFancyClick=()=>{
    setClickPremiumFancy(!ClickPremiumFancy)
}

    return (
        <React.Fragment>
            <div>
                <div id="liveMatchGameHead" className="game-head">
                    <div id="matchTrackerWrap" className="match-tracker-wrap">
                        <div className="widgets">
                            <div>
                                <div className="sr-widget sr-widget-1" data-sr-widget="match.lmtplus" data-sr-loaded data-sr-input-props="{&quot;layout&quot;:&quot;double&quot;,&quot;scoreboard&quot;:&quot;extended&quot;,&quot;momentum&quot;:&quot;bars&quot;,&quot;collapseTo&quot;:&quot;momentum&quot;,&quot;matchId&quot;:&quot;27872624&quot;}">
                                    <div className="sr-wwrap srm-fullyloaded">
                                        <div className="sr-bb sr-lmt-plus sr-ltr sr-lmt-plus--large" data-responsive="{&quot;small&quot;:{&quot;width&quot;:&quot;- 310&quot;,&quot;classes&quot;:&quot;sr-lmt-plus--small&quot;},&quot;large&quot;:{&quot;width&quot;:&quot;420 -&quot;,&quot;classes&quot;:&quot;sr-lmt-plus--large&quot;},&quot;largeDouble&quot;:{&quot;width&quot;:&quot;- 560&quot;,&quot;classes&quot;:&quot;sr-lmt-plus--double-single&quot;}}"
                                            data-responsive-key="lmt-plus" data-resp-id={5} /></div>
                                </div>
                            </div>
                        </div>
                        <ul id="liveMatchTrackerBtn" className="match-btn">
                            <li><a id="liveMultiMarketPin" name="gameHeadPin" className="btn-pin" title="Add to Multi Markets" eventtype={4} eventid={30035305} marketid="1.173506114" style={{ cursor: 'pointer' }} /></li>
                            <li><a className="btn-refresh" style={{ cursor: 'pointer' }} /></li>
                        </ul>
                    </div>
                    <div id="scoresEvent" className="match-odds">Match Odds<img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></div>
                      <ul className="game-info" style={{ display: 'block' }}>
                        <li id="gameInfoDate" className="green">
                            <img className="icon-irun" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /> In-Play</li>
                    </ul>
                    <dl id="minMaxBox" className="fancy-info">
                        <dt id="minMaxDt" style={{ display: 'none' }}>Min/Max</dt><dt id="maxDt">Min</dt>
                        <dd id="minMaxInfo">1</dd><dt id="maxDt">Max</dt>
                        <dd id="minMaxInfo">250</dd>
                    </dl>
                </div>
                <div id="fullMarketBetsWrap" className="bets-wrap" eventtype={4} eventid={30035305} marketid="1.173506114">
                    <h3 id="lineMarketName" className="line_market-name" style={{ display: 'none' }}>
                    <span>1st Innings 10 Over Line</span>
                    </h3>
                    <span id="selectionCounts" className="refer_only">3 Selections</span>
                    <table id="fullMarketBoard" className="bets ">
                        <tbody>
                            <tr className="bet-all">
                                <td />
                                <td id="backPercent" className="refer-bet" colSpan={2}>101.7%</td>
                                <td>
                                    <a id="backAll" className="back-all">
                                    <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                    <span>Back all</span>
                                    </a>
                                    </td>
                                <td>
                                    <a id="layAll" className="lay-all">
                                    <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                    <span>Lay all</span>
                                    </a>
                                    </td>
                                <td id="layPercent" className="refer-book" colSpan={2}>97.9%</td>
                            </tr>
                            <tr id="fullSelectionTemplate" style={{ display: 'none' }}>
                                <th>
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}><dt>
                                        <a id="marketDepthBtn" href="#">
                                            <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                        </a>
                                        <ul id="horseBox" className="horse-box">
                                            <li id="clothNumberAlpha" />
                                            <li id="stallDraw" />
                                        </ul>
                                        <div id="uniform" className="uniform">
                                            <img src />
                                        </div>
                                    </dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover">
                                            <dt>Trainer:</dt>
                                                <dd id="trainerName" />
                                                <dt>Age/Weight:</dt>
                                                <dd id="ageWeight" />
                                                <dt>Form:</dt>
                                                <dd id="form" />
                                                <dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" />
                                                <dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" />
                                                <dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p /><span id="withoutBet" className="win" style={{ display: 'none' }} />
                                    <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                                    <span id="withBet" className="win" style={{ display: 'none' }} />
                                    <span id="lossWithBet" className="win"
                                        style={{ display: 'none' }} />
                                        <span id="zeroProfit" className="win" style={{ display: 'none' }} />
                                        <span id="zeroLiability" className="win" style={{ display: 'none' }} />
                                        <span id="zeroProfitWithBet" className="win" style={{display:'none'}} />
                                        <span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} /></th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                <span>Closed</span>
                                </td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                <span>Non Runner</span>
                                </td>
                                <td id="back_3" className="back-3" side="Back">
                                <a><span /></a></td>
                                <td id="back_2" className="back-2" side="Back">
                                <a><span /></a></td>
                                <td id="back_1" className="back-1" side="Back">
                                <a><span /></a></td>
                                <td id="lay_1" className="lay-1" side="Lay">
                                <a><span /></a></td>
                                <td id="lay_2" className="lay-2" side="Lay">
                                <a><span /></a></td>
                                <td id="lay_3" className="lay-3" side="Lay">
                                <a><span /></a></td>
                            </tr>
                            <tr id="fullSelection_4164048" eventtype={4} eventid={30035305} marketid="1_173506114" selectionid={4164048} eventname="Royal Challengers Bangalore v Rajasthan Royals" status={1} sortpriority={1} className="null">
                                <th className="predict">
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}>
                                    <dt><a id="marketDepthBtn" href="#">
                                    <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>
                                    <ul id="horseBox" className="horse-box">
                                    <li id="clothNumberAlpha" />
                                    <li id="stallDraw" />
                                    </ul>
                                    <div id="uniform" className="uniform">
                                    <img src /></div></dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                                <dd id="trainerName" /><dt>Age/Weight:</dt>
                                                <dd id="ageWeight" /><dt>Form:</dt>
                                                <dd id="form" /><dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" /><dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p><a><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>Floridsdorfer AC</p><span id="lossWithoutBet" className="win" style={{ display: 'none' }} /><span id="withBet"
                                        className="win" style={{ display: 'none' }} />
                                        <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                                        <span id="zeroProfit" className="win" style={{ display: 'none' }} />
                                        <span id="zeroLiability" className="win" style={{display:'none'}} />
                                        <span id="zeroProfitWithBet" className="win" style={{ display: 'none' }} />
                                        <span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} /></th>
                                <td id="suspend" colSpan={6} className="suspend" style={{display:'none'}}>
                                <span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Closed</span></td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Non Runner</span></td>
                                <td id="back_3" className="back-3 null" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.88" inplay={0}><a>1.02<span>4</span></a></td>
                                <td id="back_2" className="back-2 null " side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.89" inplay={0}><a>1.03<span>5</span></a></td>
                                <td id="back_1" className="back-1 null null" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.9" inplay={0}><a>1.04<span>208</span></a></td>
                                <td id="lay_1" className="lay-1 null null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.97" inplay={0}><a>1.05<span>453</span></a></td>
                                <td id="lay_2" className="lay-2 null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.98" inplay={0}><a>1.06<span>6</span></a></td>
                                <td id="lay_3" className="lay-3 null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}><a>1.07<span>11</span></a></td>
                            </tr>
                            <tr id="fullSelection_2954266" eventtype={4} eventid={30035305} marketid="1_173506114" selectionid={2954266} eventname="Royal Challengers Bangalore v Rajasthan Royals" status={1} sortpriority={2} className="null">
                                <th className="predict">
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}><dt><a id="marketDepthBtn" href="#"><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a><ul id="horseBox" className="horse-box"><li id="clothNumberAlpha" /><li id="stallDraw" /></ul><div id="uniform" className="uniform"><img src /></div></dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                                <dd id="trainerName" /><dt>Age/Weight:</dt>
                                                <dd id="ageWeight" /><dt>Form:</dt>
                                                <dd id="form" /><dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" /><dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p><a><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>Grazer AK</p><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet"
                                        className="win" style={{ display: 'none' }} /><span id="zeroProfit" className="win" style={{ display: 'none' }} /><span id="zeroLiability" className="win" style={{ display: 'none' }} /><span id="zeroProfitWithBet" className="win" style={{
                                            display:
                                                'none'
                                        }} /><span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} /></th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Closed</span></td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Non Runner</span></td>
                                <td id="back_3" className="back-3  spark" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay={0}><a>21<span>219</span></a></td>
                                <td id="back_2" className="back-2 null" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay={0}><a>26<span>28</span></a></td>
                                <td id="back_1" className="back-1 null null" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}><a>1<span>50</span></a></td>
                                <td id="lay_1" className="lay-1 null null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay={0}><a> <span /></a></td>
                                <td id="lay_2" className="lay-2 null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay={0}><a> <span /></a></td>
                                <td id="lay_3" className="lay-3 null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay={0}><a> <span /></a></td>
                            </tr>
                            <tr id="fullSelection_2954266" eventtype={4} eventid={30035305} marketid="1_173506114" selectionid={2954266} eventname="Royal Challengers Bangalore v Rajasthan Royals" status={1} sortpriority={2} className="null">
                                <th className="predict">
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}><dt><a id="marketDepthBtn" href="#"><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a><ul id="horseBox" className="horse-box"><li id="clothNumberAlpha" /><li id="stallDraw" /></ul><div id="uniform" className="uniform"><img src /></div></dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                                <dd id="trainerName" /><dt>Age/Weight:</dt>
                                                <dd id="ageWeight" /><dt>Form:</dt>
                                                <dd id="form" /><dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" /><dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p><a><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>The Draw</p><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet" className="win"
                                        style={{ display: 'none' }} /><span id="zeroProfit" className="win" style={{ display: 'none' }} /><span id="zeroLiability" className="win" style={{ display: 'none' }} /><span id="zeroProfitWithBet" className="win" style={{
                                            display:
                                                'none'
                                        }} /><span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} /></th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Closed</span></td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Non Runner</span></td>
                                <td id="back_3" className="back-3  null" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay={0}><a>15<span>835</span></a></td>
                                <td id="back_2" className="back-2 null" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay={0}><a>17.5<span>416</span></a></td>
                                <td id="back_1" className="back-1 null null" side="Back" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}><a>21<span>23</span></a></td>
                                <td id="lay_1" className="lay-1 null null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay={0}><a>36<span>160</span></a></td>
                                <td id="lay_2" className="lay-2 null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay={0}><a>38<span>121</span></a></td>
                                <td id="lay_3" className="lay-3 null" side="Lay" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay={0}><a>100<span>56</span></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="sportsBookWrap" className="bets-wrap sportsbook_bet" style={{}}>
                {/* Sportsbook bet head start */}
                <div id="sportsBookHead" className="sportsbook-head">
                    <h4>
                        <span id="sportsBookName">Premium Soccer</span>
                        <a id="sportsBookRules" className="btn-sportsbook_rules">Rules</a>
                    </h4>
                    <a id="showFancyBetBtn" className="other-tab" style={{ display: 'none' }}>Fancy Bet</a>
                    <div id="sportsBookRulesWrap_2" className="rules-wrap">
                        <h3 id="sportsbookRulesTitle" />
                        <div id="sportradarCricketRules" className="rules-content">
                            <dl className="download-list">
                                <dt className="icon-pdf"><a href="javascript: void(window.open('https://drive.google.com/open?id=1rkKwnG8Sv09iANXNYriOXOm0e0wjoQqf', '_blank', config = 'location=no'));">Pre Match Market Details</a></dt>
                                <dd><a href="javascript: void(window.open('https://drive.google.com/open?id=1rkKwnG8Sv09iANXNYriOXOm0e0wjoQqf', '_blank', config = 'location=no'));">download</a></dd>
                            </dl>
                            <dl className="download-list">
                                <dt className="icon-pdf"><a href="javascript: void(window.open('https://drive.google.com/open?id=13rBX3LjmSfNNKPbCyCxkqnvHZPdmIgLf', '_blank', config = 'location=no'));">In Play Market Details</a></dt>
                                <dd><a href="javascript: void(window.open('https://drive.google.com/open?id=13rBX3LjmSfNNKPbCyCxkqnvHZPdmIgLf', '_blank', config = 'location=no'));">download</a></dd>
                            </dl>
                        </div>
                        <div id="sportradarTennisRules" className="rules-content">
                            <p>General Rules:</p>
                            <ol>
                                <li>In case of default, retirement or walkover of any player, all undecided bets are considered void.</li>
                                <li>In case of any delay (rain, darkness…) all markets remain unsettled and the trading will be continued as soon as the match continues.</li>
                                <li>If penalty point(s) are awarded by the umpire, all bets on that game will stand.</li>
                                <li>In case of a match is finished before certain points/games were finished, all affected point/game related markets are considered void.</li>
                            </ol>
                            <p>Settlement and Cancellation Rules:</p>
                            <ol>
                                <li>If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</li>
                                <li>If the players/teams are displayed incorrectly, we reserve the right to void betting.</li>
                                <li>If a player retires all undecided markets are considered void.</li>
                                <li>If a match tie-break is played as a deciding set in Bo3 format, it will be considered as the 3rd set</li>
                                <li>Every tie-break or Match tie-break counts as 1 game</li>
                            </ol>
                        </div>
                        <div id="sportradarSoccerRules" className="rules-content">
                            <dl className="download-list">
                                <dt className="icon-pdf">
                                <a href="">Market Details</a></dt>
                                <dd>
                                <a href="">download</a></dd>
                            </dl>
                        </div>
                        <div id="sportradarKabaddiRules" className="rules-content">
                        </div>
                        <ul className="btn-wrap break">
                            <li><a  className="btn" href="#">OK</a></li>
                        </ul>
                    </div>
                    <dl id="minMaxWrap_1" className="fancy-info" style={{ display: 'none' }}>
                        <dt><span>Min/Max</span></dt>
                        <dd id="minMaxInfo_1">0</dd>
                        <dt id="rebateName_1">Rebate</dt>
                        <dd id="rebateRatio_1">0.00%</dd>
                    </dl>
                    <dl id="minMaxWrap_2" className="fancy-info" style={{}}>
                        <dt><span>Min</span></dt>
                        <dd id="minMaxInfo_2">100.00</dd>
                        <dt id="rebateName_2" style={{ display: 'none' }}>Rebate</dt>
                        <dd id="rebateRatio_2" style={{ display: 'none' }}>0.00%</dd>
                    </dl>
                </div>
                <div id="sportsBookTab_2_1" className="special_bets-tab-wrap" style={{}}>
                    <ul className="special_bets-tab">
                        <li>
                        <a id="tab_All" style={{color: "rgba(163,64,5,0.85)"}}>All</a></li>
                        <li>
                        <a id="tab_Popular" style={{color: "rgba(163,64,5,0.85)"}}>Popular</a></li>
                        <li><a id="tab_Half" style={{color: "rgba(163,64,5,0.85)"}}>Half</a></li>
                        <li><a id="tab_Goals" style={{color: "rgba(163,64,5,0.85)"}}>Goals</a></li>
                        <li><a id="tab_FastMarkets" style={{color: "rgba(163,64,5,0.85)"}}>Fast Markets</a></li>
                        <li><a id="tab_Specials" style={{color: "rgba(163,64,5,0.85)"}}>Specials</a></li>
                        <li><a id="tab_Corners" style={{color: "rgba(163,64,5,0.85)"}}>Corners</a></li>
                        <li><a id="tab_Cards" style={{color: "rgba(163,64,5,0.85)"}}>Cards</a></li>
                        <li><a id="tab_Players" style={{color: "rgba(163,64,5,0.85)"}}>Players</a></li>
                        <li><a id="tab_Others" style={{color: "rgba(163,64,5,0.85)"}}>Others</a></li>
                    </ul>
                </div>
                <table id="sportsBookMarket_2_2017126_134150326" className={` ${ClickPremiumFancy ? "bets" : "bets close"}`}>
                    <colgroup>
                        <col span={1} width={280} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                    </colgroup>
                    <tbody>
                        <tr className="colgroup-adjust">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr className="special_bet">
                            <td colSpan={7}>
                                <h3 id="marketHeader">
                                    <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                                    <a id="marketName" onClick={()=>{PremiumFancyClick()}} className="to-expand">Total</a>
                                </h3>
                            </td>
                        </tr>
                       
                       {ClickPremiumFancy === true &&
                       <>
                        <tr id="sportsBookSuspend_2_2017126_134150326_1834654946" className="fancy-suspend-tr" style={{ display: 'none' }} eventid={2017126} isactive={1} status={1}>
                            <th colSpan={3} />
                            <td className="fancy-suspend-td" colSpan={2}>
                                <div id="suspendClass" className="fancy-suspend-white">
                                <span>Suspend</span>
                                </div>
                            </td>
                            <td colSpan={2} />
                    
                        </tr>
                        <tr id="sportsBookSelection_2_2017126_134150326_1834654946" style={{ display: 'table-row' }} >
                            <th className colSpan={3}>
                                <dl className="fancy-th-layout">
                                    <dt>
                                        <p id="selectionName">over 2</p>
                                        <span id="before" className="win" style={{ display: 'none' }} />
                                        <span id="after" className="to-lose" style={{ display: 'none' }} />
                                    </dt>
                                </dl>
                            </th>
                            <td id="back_1" className="back-1 no-liq" colSpan={2}>
                                <a id="info">1.38</a>
                            </td>
                            <td className="refer-book" colSpan={2} />
                        </tr>
                        <tr id="sportsBookSuspend_2_2017126_134150326_1834654947" className="fancy-suspend-tr" style={{ display: 'none' }} >
                            <th colSpan={3} />
                            <td className="fancy-suspend-td" colSpan={2}>
                                <div id="suspendClass" className="fancy-suspend-white">
                                <span>Suspend</span>
                                </div>
                            </td>
                            <td colSpan={2} />
                        </tr>
                        <tr id="sportsBookSelection_2_2017126_134150326_1834654947" style={{ display: 'table-row' }}>
                            <th className colSpan={3}>
                                <dl className="fancy-th-layout">
                                    <dt>
                                        <p id="selectionName">under 2</p>
                                        <span id="before" className="win" style={{ display: 'none' }} />
                                        <span id="after" className="to-lose" style={{ display: 'none' }} />
                                    </dt>
                                </dl>
                            </th>
                            <td id="back_1" className="back-1 no-liq" colSpan={2}>
                                <a id="info">3.1</a>
                            </td>
                            <td className="refer-book" colSpan={2} />
                        </tr>
                </>
                  }
                    </tbody>
                </table>
                <table id="sportsBookMarket_2_2017126_134150331" className="bets close" style={{}}>
                    <colgroup>
                        <col span={1} width={280} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                    </colgroup>
                    <tbody>
                        <tr className="colgroup-adjust">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr className="special_bet">
                            <td colSpan={7}>
                                <h3 id="marketHeader">
                                    <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                                    <a id="marketName" className="to-expand">Total</a>
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table id="sportsBookMarket_2_2017126_134150327" className="bets close" style={{}}>
                    <colgroup>
                        <col span={1} width={280} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                    </colgroup>
                    <tbody>
                        <tr className="colgroup-adjust">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr className="special_bet">
                            <td colSpan={7}>
                                <h3 id="marketHeader">
                                    <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                                    <a id="marketName" className="to-expand">Total</a>
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table id="sportsBookMarket_2_2017126_134150332" className="bets close" style={{}}>
                    <colgroup>
                        <col span={1} width={280} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                        <col span={1} width={70} />
                    </colgroup>
                    <tbody>
                        <tr className="colgroup-adjust">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr className="special_bet">
                            <td colSpan={7}>
                                <h3 id="marketHeader">
                                    <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                                    <a id="marketName" className="to-expand">Total</a>
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul id="popularMarkets" className="tab-markets">
                <li id="popularTab_popular" style={{ display: 'list-item' }} tabname="popular" eventid={31367967}>
                    <a onClick={() => { setTabmenu(0) }} className={`${(Tabmenu === 0) ? "select" : "null"}`} style={{ cursor: 'pointer' }}>Popular</a>
                </li>
                <li id="popularTab_goals" style={{ display: 'list-item' }} tabname="goals" eventid={31367967}>
                    <a onClick={() => { setTabmenu(1) }} className={`${(Tabmenu === 1) ? "select" : "null"}`} style={{ cursor: 'pointer' }}>Goals</a>
                </li>
            </ul>
            <div id="fullMarketEventLeft" className="event-left" style={{ display: 'block' }}>
            <div id="popMarketItem_1_197730061" className={`${clickToexpand ? "game-wrap close" : "game-wrap "}`}  style={{ display: 'block' }} markettype="OVER_UNDER_25" eventid={31367967} marketid="1.197730061" totalmatched={-1} marketname="Over/Under 2.5 Goals">
                <h3  >
                    <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" />
                    <a id="marketName" onClick={()=>{Clicktoexpanded()}} className="to-expand" style={{ cursor: 'pointer' }}>Over/Under 2.5 Goals</a>
                </h3>
                <ul className="slip-head">
                    <li className="col-game" id="matched">
                    <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>Matched: PTE 0</li>
                    <li className="col-home">
                    <span>Back</span>
                     <span>Lay</span></li>
                </ul>
                <div id="marketGameList" className="game-list">
                    {/* Game Suspend */}
                    <div id="suspend" className="suspend" style={{ display: 'none' }}>
                        <span>Suspend</span>
                    </div>
                    {/* Game Closed */}
                    <div id="closed" className="suspend" style={{ display: 'none' }}>
                        <span>Closed</span>
                    </div>
                    <dl id="selectionTemplate" className="game-list-col" style={{ display: 'none' }}>
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner">{'{'}runner{'}'}
                            <span id="withoutBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                            <span id="withBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                        </dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back">--</a>
                            <a className="btn-lay" side="Lay">--</a>
                        </dd>
                    </dl>
                    <dl id="selection_47972" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31367967} marketid="1_197730061" popselectionitem={47972} eventname="Western Sydney Wanderers v Newcastle Jets">
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner"><a onclick="window.open('market/marketDepth.jsp?eventId=31367967&marketId=1.197730061&selectionId=47972','_blank','resizable=no,width=450,height=460')">
                            <img className="icon-predict" src={transparent} />
                        </a>Under 2.5 Goals<br /><span id="withoutBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithoutBet" className style={{ display: 'none' }} />
                            <span id="withBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithBet" className style={{ display: 'none' }} /></dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back" selectionid={47972} runner="Under 2.5 Goals" marketname="Over/Under 2.5 Goals" inplay={0} markettype="OVER_UNDER_25">&nbsp;</a>
                            <a className="btn-lay" side="Lay" selectionid={47972} runner="Under 2.5 Goals" marketname="Over/Under 2.5 Goals" inplay={0} markettype="OVER_UNDER_25">&nbsp;</a>
                        </dd>
                    </dl>
                    <dl id="selection_47973" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31367967} marketid="1_197730061" popselectionitem={47973} eventname="Western Sydney Wanderers v Newcastle Jets">
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner">
                            <a onclick="window.open('market/marketDepth.jsp?eventId=31367967&marketId=1.197730061&selectionId=47973','_blank','resizable=no,width=450,height=460')">
                                <img className="icon-predict" src={transparent} />
                            </a>Over 2.5 Goals<br />
                            <span id="withoutBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithoutBet" className style={{ display: 'none' }} />
                            <span id="withBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithBet" className style={{ display: 'none' }} />
                        </dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back" selectionid={47973} runner="Over 2.5 Goals" marketname="Over/Under 2.5 Goals" inplay={0} markettype="OVER_UNDER_25">&nbsp;</a>
                            <a className="btn-lay" side="Lay" selectionid={47973} runner="Over 2.5 Goals" marketname="Over/Under 2.5 Goals" inplay={0} markettype="OVER_UNDER_25">&nbsp;</a>
                        </dd>
                    </dl><a id="moreLink" className="more" style={{ cursor: 'pointer' }}>View Full Market</a>
                </div>
            </div><div id="popMarketItem_1_197730062" className="game-wrap" style={{ display: 'block' }} markettype="OVER_UNDER_15" eventid={31367967} marketid="1.197730062" totalmatched={-1} marketname="Over/Under 1.5 Goals">
                    <h3>
                        <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" />
                        <a id="marketName" className="to-expand" style={{ cursor: 'pointer' }}>Over/Under 1.5 Goals</a>
                    </h3>
                    <ul className="slip-head">
                        <li className="col-game" id="matched">
                        <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>Low Liquidity</span>Matched: PTE 0</li>
                        <li className="col-home"><span>Back</span>
                            <span>Lay</span></li>
                    </ul>
                    <div id="marketGameList" className="game-list">
                        {/* Game Suspend */}
                        <div id="suspend" className="suspend" style={{ display: 'none' }}>
                            <span>Suspend</span>
                        </div>
                        {/* Game Closed */}
                        <div id="closed" className="suspend" style={{ display: 'none' }}>
                            <span>Closed</span>
                        </div>
                        <dl id="selectionTemplate" className="game-list-col" style={{ display: 'none' }}>
                            <span id="vsName" vsinfo style={{ display: 'none' }} />
                            <dt id="runner">
                                <span id="withoutBet" className="win" style={{ display: 'none' }} />
                                <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                                <span id="withBet" className="win" style={{ display: 'none' }} />
                                <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                            </dt>
                            <dd className="col-home">
                                <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                    <span>Suspend</span>
                                </div>
                                <a className="btn-back" side="Back">--</a>
                                <a className="btn-lay" side="Lay">--</a>
                            </dd>
                        </dl>
                        <dl id="selection_1221385" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31367967} marketid="1_197730062" popselectionitem={1221385} eventname="Western Sydney Wanderers v Newcastle Jets">
                            <span id="vsName" vsinfo style={{ display: 'none' }} />
                            <dt id="runner"><a onclick="window.open('market/marketDepth.jsp?eventId=31367967&marketId=1.197730062&selectionId=1221385','_blank','resizable=no,width=450,height=460')">
                                <img className="icon-predict" src={transparent} />
                            </a>Under 1.5 Goals<br /><span id="withoutBet" className="win" style={{ display: 'none' }} /><span id="lossWithoutBet" className style={{ display: 'none' }} /><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet" className style={{ display: 'none' }} /></dt>
                            <dd className="col-home">
                                <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                    <span>Suspend</span>
                                </div>
                                <a className="btn-back" side="Back" selectionid={1221385} runner="Under 1.5 Goals" marketname="Over/Under 1.5 Goals" inplay={0} markettype="OVER_UNDER_15">&nbsp;</a>
                                <a className="btn-lay" side="Lay" selectionid={1221385} runner="Under 1.5 Goals" marketname="Over/Under 1.5 Goals" inplay={0} markettype="OVER_UNDER_15">&nbsp;</a>
                            </dd>
                        </dl><dl id="selection_1221386" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31367967} marketid="1_197730062" popselectionitem={1221386} eventname="Western Sydney Wanderers v Newcastle Jets">
                            <span id="vsName" vsinfo style={{ display: 'none' }} />
                            <dt id="runner"><a onclick="window.open('market/marketDepth.jsp?eventId=31367967&marketId=1.197730062&selectionId=1221386','_blank','resizable=no,width=450,height=460')">
                                <img className="icon-predict" src={transparent} />
                            </a>Over 1.5 Goals<br /><span id="withoutBet" className="win" style={{ display: 'none' }} /><span id="lossWithoutBet" className style={{ display: 'none' }} /><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet" className style={{ display: 'none' }} /></dt>
                            <dd className="col-home">
                                <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                    <span>Suspend</span>
                                </div>
                                <a className="btn-back" side="Back" selectionid={1221386} runner="Over 1.5 Goals" marketname="Over/Under 1.5 Goals" inplay={0} markettype="OVER_UNDER_15">&nbsp;</a>
                                <a className="btn-lay" side="Lay" selectionid={1221386} runner="Over 1.5 Goals" marketname="Over/Under 1.5 Goals" inplay={0} markettype="OVER_UNDER_15">&nbsp;</a>
                            </dd>
                        </dl><a id="moreLink" className="more" style={{ cursor: 'pointer' }}>View Full Market</a>
                    </div>
                </div></div>
            <div id="fullMarketEventRight" className="event-right" style={{ display: 'block' }}><div id="popMarketItem_1_197588975" className="game-wrap disable" style={{ display: 'block' }} markettype="OVER_UNDER_05" eventid={31372486} marketid="1.197588975" totalmatched="23718.79" marketname="Over/Under 0.5 Goals">
                <h3>
                    <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} title="Add to Multi Markets" />
                    <a id="marketName" className="to-expand" style={{ cursor: 'pointer' }}>Over/Under 0.5 Goals</a>
                </h3>
                <ul className="slip-head">
                    <li className="col-game" id="matched">
                    <span id="lowLiquidityTag" className="game-low_liq" style={{height:'15px',lineHeight:'13px'}}>Low Liquidity</span>Matched: PTH 23,719</li>
                    <li className="col-home">
                    <span>Back</span> 
                    <span>Lay</span></li>
                </ul>
                <div id="marketGameList" className="game-list">
                    {/* Game Suspend */}
                    <div id="suspend" className="suspend" style={{ display: 'none' }}>
                        <span>Suspend</span>
                    </div>
                    {/* Game Closed */}
                    <div id="closed" className="suspend" style={{ display: 'none' }}>
                        <span>Closed</span>
                    </div>
                    <dl id="selectionTemplate" className="game-list-col" style={{ display: 'none' }}>
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner">
                            <span id="withoutBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                            <span id="withBet" className="win" style={{ display: 'none' }} />
                            <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                        </dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back">--</a>
                            <a className="btn-lay" side="Lay">--</a>
                        </dd>
                    </dl>
                    <dl id="selection_5851482" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31372486} marketid="1_197588975" popselectionitem={5851482} eventname="Udinese v Salernitana">
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner"><a onclick="window.open('market/marketDepth.jsp?eventId=31372486&marketId=1.197588975&selectionId=5851482','_blank','resizable=no,width=450,height=460')"><img className="icon-predict" src="/images/transparent.gif" /></a>Under 0.5 Goals<br /><span id="withoutBet" className="win" style={{ display: 'none' }} /><span id="lossWithoutBet" className style={{ display: 'none' }} /><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet" className style={{ display: 'none' }} /></dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back" selectionid={5851482} runner="Under 0.5 Goals" marketname="Over/Under 0.5 Goals" inplay={0} markettype="OVER_UNDER_05">17</a>
                            <a className="btn-lay" side="Lay" selectionid={5851482} runner="Under 0.5 Goals" marketname="Over/Under 0.5 Goals" inplay={0} markettype="OVER_UNDER_05">17.5</a>
                        </dd>
                    </dl><dl id="selection_5851483" className="game-list-col" style={{ display: 'block' }} eventtype={1} eventid={31372486} marketid="1_197588975" popselectionitem={5851483} eventname="Udinese v Salernitana">
                        <span id="vsName" vsinfo style={{ display: 'none' }} />
                        <dt id="runner"><a onclick="window.open('market/marketDepth.jsp?eventId=31372486&marketId=1.197588975&selectionId=5851483','_blank','resizable=no,width=450,height=460')"><img className="icon-predict" src="/images/transparent.gif" /></a>Over 0.5 Goals<br /><span id="withoutBet" className="win" style={{ display: 'none' }} /><span id="lossWithoutBet" className style={{ display: 'none' }} /><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet" className style={{ display: 'none' }} /></dt>
                        <dd className="col-home">
                            <div id="selectionSuspend" className="suspend" style={{ display: 'none' }}>
                                <span>Suspend</span>
                            </div>
                            <a className="btn-back" side="Back" selectionid={5851483} runner="Over 0.5 Goals" marketname="Over/Under 0.5 Goals" inplay={0} markettype="OVER_UNDER_05">1.06</a>
                            <a className="btn-lay" side="Lay" selectionid={5851483} runner="Over 0.5 Goals" marketname="Over/Under 0.5 Goals" inplay={0} markettype="OVER_UNDER_05">1.07</a>
                        </dd>
                    </dl><a id="moreLink" className="more" style={{ cursor: 'pointer' }}>View Full Market</a>
                </div>
            </div></div>


        </React.Fragment>
    )
}

import React from 'react'

export const Subnav = () => {
    return (
        <React.Fragment>
            <div className="top-subnav-wrap">
    <div className="top-subnav-inner">
        <div className="select-wrap-expand select-platform-wrap">
            <div id="category_filter_container">
                <div id="group-121">
                    <h4 ui-key="displayName">Vendor</h4>
                    <ul ui-key="categories">
                        <li id="category-486">
                            <input type="checkbox" id="chk-category-486" data-category={486} />
                            <label htmlFor="chk-category-486" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">JDB</p>
                <span className="game-logo jdb" data-vendor="jdb" />
              </label>
                        </li>
                        <li id="category-652">
                            <input type="checkbox" id="chk-category-652" data-category={652} />
                            <label htmlFor="chk-category-652" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">JILI</p>
                <span className="game-logo awcmjili" data-vendor="awcmjili" />
              </label>
                        </li>
                        <li id="category-543">
                            <input type="checkbox" id="chk-category-543" data-category={543} />
                            <label htmlFor="chk-category-543" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">MG</p>
                <span className="game-logo mg" data-vendor="mg" />
              </label>
                        </li>
                        <li id="category-544">
                            <input type="checkbox" id="chk-category-544" data-category={544} />
                            <label htmlFor="chk-category-544" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">PG</p>
                <span className="game-logo pg" data-vendor="pg" />
              </label>
                        </li>
                        <li id="category-545">
                            <input type="checkbox" id="chk-category-545" data-category={545} />
                            <label htmlFor="chk-category-545" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">PT</p>
                <span className="game-logo silkstonept" data-vendor="silkstonept" />
              </label>
                        </li>
                        <li id="category-898">
                            <input type="checkbox" id="chk-category-898" data-category={898} />
                            <label htmlFor="chk-category-898" className>
                <span className="check-cube" />
                <p className="name" ui-key="displayName">7MOJOS</p>
                <span className="game-logo awcmsevenmojos" data-vendor="awcmsevenmojos" />
              </label>
                        </li>
                    </ul>
                </div>
                <div id="group-157">
                    <h4 ui-key="displayName">GameCategoryType</h4>
                    <ul ui-key="categories">
                        <li id="category-505">
                            <input type="checkbox" id="chk-category-505" data-category={505} />
                            <label htmlFor="chk-category-505" className="btn-gametype">
                <span className="check-cube" />
                <p className="name" ui-key="displayName">SLOTS</p>
                <span className="game-logo" data-vendor />
              </label>
                        </li>
                        <li id="category-555">
                            <input type="checkbox" id="chk-category-555" data-category={555} />
                            <label htmlFor="chk-category-555" className="btn-gametype">
                <span className="check-cube" />
                <p className="name" ui-key="displayName">Sky247 Specials</p>
                <span className="game-logo" data-vendor />
              </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="btn-select-more" data-select-more="close">Hide item</div>
        </div>
        <div id="category_filter_collapse_container" className="select-wrap-collapse">
            <ul className="select-platform-wrap">
                <li id={486} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-486" data-category={486} />
                    <label htmlFor="chk-category-collapse-486"><span className="check-cube" />
            <p className="name">JDB</p><span className="game-logo jdb" />
          </label>
                </li>
                <li id={652} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-652" data-category={652} />
                    <label htmlFor="chk-category-collapse-652"><span className="check-cube" />
            <p className="name">JILI</p><span className="game-logo awcmjili" />
          </label>
                </li>
                <li id={543} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-543" data-category={543} />
                    <label htmlFor="chk-category-collapse-543"><span className="check-cube" />
            <p className="name">MG</p><span className="game-logo mg" />
          </label>
                </li>
                <li id={544} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-544" data-category={544} />
                    <label htmlFor="chk-category-collapse-544"><span className="check-cube" />
            <p className="name">PG</p><span className="game-logo pg" />
          </label>
                </li>
                <li id={545} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-545" data-category={545} />
                    <label htmlFor="chk-category-collapse-545"><span className="check-cube" />
            <p className="name">PT</p><span className="game-logo silkstonept" />
          </label>
                </li>
                <li id={898} style={{display: ''}}>
                    <input type="checkbox" name="vendor_filter" id="chk-category-collapse-898" data-category={898} />
                    <label htmlFor="chk-category-collapse-898"><span className="check-cube" />
            <p className="name">7MOJOS</p><span className="game-logo awcmsevenmojos" />
          </label>
                </li>
            </ul>
            <div className="btn-select-more" data-select-more="open">More filters
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

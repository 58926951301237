import React from 'react'
import pay16 from "./MobileImage/pay16.png"
import pay17 from "./MobileImage/pay17.png"
import pay18 from "./MobileImage/pay18.png"
import pay19 from "./MobileImage/pay19.png"
import pay20 from "./MobileImage/pay20.png"
import pay21 from "./MobileImage/pay21.png"
import pay35 from "./MobileImage/pay35.png"
import pay36 from "./MobileImage/pay36.png"
import pay37 from "./MobileImage/pay37.png"
import pay43 from "./MobileImage/pay43.png"
import safe1 from "./MobileImage/safe1.png"
import safe2 from "./MobileImage/safe2.png"
import safe3 from "./MobileImage/safe3.png"
import iconwhatsapp from "./MobileImage/icon-whatsapp.png"
import iconmail from "./MobileImage/icon-mail-type02.svg"
export const FooterTop = () => {
    return (
        <React.Fragment>
            <div className="footer-top" style={{ padding: "12px 10px 4px 10px" }}>
                <div className="pay" data-pagetype={15}>
                    <h2>Payment Methods</h2>
                    <ul>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay16} alt="Bank Deposit" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay17} alt="RUPEE-O" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay18} alt="UPI" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay19} alt="PhonePe" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay20} alt="Paytm" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay21} alt="IMPS" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay35} alt="Free Charge" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay36} alt="GPay" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay37} alt="AirTel" /></a></li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src={pay43} alt="AstroPay" /></a></li>
                    </ul>
                </div>
                <div className="safe">
                    <h2>
                        <ui-translate key="fe.text.betting_responsibility" lang="en">Responsible Gaming</ui-translate>
                    </h2>
                    <ul>
                        <li><img src={safe1} /></li>
                        <li><img src={safe2} /></li>
                        <li><img src={safe3} /></li>
                    </ul>
                </div>
                <div className="safe" id="footer_socialMedia" style={{ display: 'none' }} />
            </div>
            <div className="footer-logo-wrap" style={{ padding: "8px 0px 1px 19px" }}>
                <div className="link-wrap">
                    <ul>
                        <li>
                            <a href="/h5/player/terms/about.jsp">
                                <ui-translate key="fe.text.about_us" lang="en">About us</ui-translate>
                            </a>
                        </li>
                        <li>
                            <a href="/h5/player/terms/privacy.jsp">
                                <ui-translate key="fe.text.privacy_policy" lang="en">Privacy Policy</ui-translate>
                            </a>
                        </li>
                        <li>
                            <a href="/h5/player/terms/conditions.jsp">
                                <ui-translate key="fe.text.terms" params="[&quot;&&quot;]" lang="en">Terms &amp; Conditions</ui-translate>
                            </a>
                        </li>
                        <li>
                            <a href="/h5/player/terms/inAndOut.jsp">
                                <ui-translate key="fe.text.kyc_policy" lang="en">KYC Policy</ui-translate>
                            </a>
                        </li>
                        <li>
                            <a href="/h5/player/terms/rule.jsp">
                                <ui-translate key="fe.text.rule" params="[&quot;And&quot;]" lang="en">Rules And Regulations</ui-translate>
                            </a>
                        </li>
                        <li>
                            <a href="/h5/player/terms/duty.jsp">
                                <ui-translate key="fe.text.betting_responsibility" lang="en">Responsible Gaming</ui-translate>
                            </a>
                        </li>
                        <li><a href="/h5/player/terms/selfExclusionPolicy.jsp">Self-exclusion policy</a></li>
                        <li><a href="/h5/player/terms/underagePolicy.jsp">Underage Policy</a></li>
                        <li><a href="https://skyinfopartners.com/?utm_source=sky247&utm_medium=footer&utm_campaign=sky247_footer_link" target="_blank">Affiliate</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-license">
                <div className="license-cont">
                    <p><a href="https://www.skylivecasino.com/" target="_blank">Sky247</a>&nbsp;is operated by&nbsp;by&nbsp;Sky Infotech N.V. a limited liability company incorporated under the laws of Curacao with company Registration number&nbsp;152377&nbsp;with registered
                        office at&nbsp;Abraham de Veerstraat 9 , Curacao P.O Box 3421 and is licensed and regulated by the Curacao authority as the regulatory body responsible holding a (Sub-license with License number 365/JAZ Sub-License GLH- OCCHKTW0707072017 granted
                        on 6.07.2019).&nbsp;</p>
                    <p>Players are requested not to contact any untrusted sources for&nbsp;<span>Sky247.com</span>&nbsp;accounts as this is an online site and they can only register independently without any agents. Only deposit through the account details generated
                        by the system or provided by our official support team.</p>
                </div>
                <div className="contect-cont">
                    <div className="contect-phone">
                        <img src={iconwhatsapp} />
                        <span>000</span></div>
                    <div className="contect-mail">
                        <img src={iconmail} />
                        <a href="mailto:support@sky247.com">support@sky247.com</a>
                    </div>
                </div>
                <div className="license-iframe">

                </div>
            </div>
            <div className="footer-bottom" style={{ marginBottom: "60px" }}>
                <div className="logo data-image" id="logoimage" />
                <div className="text">
                    <div className="title">Best Quality Platform</div>
                    <p>© 2022
                        <ui-translate key="fe.text.copyrights_reserved" params="[&quot;Sky247&quot;]" lang="en">Sky247 Copyrights. All Rights Reserved</ui-translate>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

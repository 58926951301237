import React from 'react'
import safe1 from "../images/FooterIamge/safe1.png"
import safe2 from "../images/FooterIamge/safe2.png"
import safe3 from "../images/FooterIamge/safe3.png"
import pay16 from "../images/FooterIamge/pay16.png"
import pay17 from "../images/FooterIamge/pay17.png"
import pay18 from "../images/FooterIamge/pay18.png"
import pay37 from "../images/FooterIamge/pay37.png"
import pay43 from "../images/FooterIamge/pay43.png"
import pay19 from "../images/FooterIamge/pay19.png"
import pay20 from "../images/FooterIamge/pay20.png"
import pay35 from "../images/FooterIamge/pay35.png"
import pay21 from "../images/FooterIamge/pay21.png"
import pay36 from "../images/FooterIamge/pay36.png"
import iconwhatsapp from '../images/FooterIamge/icon-whatsapp.png'
import iconmail from "../images/FooterIamge/icon-mail.png"
export const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer-wrap">
        <div className="footer-1200">
          <ul className="footer-link-group">
            <li>
              <div title="Online CS" className="icon-wrap icon1" />
              <div className="text-wrap">
                <h4>Online CS
                </h4>
                <h5>Provides 24/7 Quality service
                </h5>
                <a className="click_cs_link">Contact CS
                </a>
              </div>
            </li>
            <li>
              <div title="New Member Guide" className="icon-wrap icon2" />
              <div className="text-wrap">
                <h4>New Member Guide
                </h4>
                <h5>5 mins guide
                </h5>
                <a data-pagetype={23}>
                  Check now
                </a>
              </div>
            </li>
          </ul>
          <ul className="footer-partner-wrap">
            <li className="duty">
              <h5>Responsible Gambling
              </h5>
              <a href="">
                <img src={safe1} />
              </a>
              <a href="">
                <img src={safe2} /></a>
              <a href="">
                <img src={safe3} /></a>
            </li>
            <li className="payment" data-pagetype={20} style={{ cursor: 'pointer' }}>
              <h5>Payment Methods
              </h5>
              <a href="">
                <img src={pay16} alt="Bank Deposit" /></a>
              <a href="">
                <img src={pay17} alt="RUPEE-O" /></a>
              <a href="">
                <img src={pay18} alt="Unified Payments Interface" /></a>
              <a href="">
                <img src={pay19} alt="PhonePe" /></a>
              <a href="">
                <img src={pay20} alt="Paytm" /></a>
              <a href="">
                <img src={pay21} /></a>
              <a href="">
                <img src={pay35} alt="Free Charge" /></a>
              <a href="">
                <img src={pay36} alt="Google Pay" /></a>
              <a href="">
                <img src={pay37} alt="AirTel Payments Bank" /></a>
              <a href="">
                <img src={pay43} alt="AstroPay" /></a>
            </li>

          </ul>
          <div className="footer-license">
            <div className="license-cont">
              <p>
                <a href="https://www.skylivecasino.com/" target="_blank">Sky247</a>&nbsp;is operated by&nbsp;by&nbsp;Sky Infotech
                N.V. a limited liability company incorporated under the laws of Curacao with company Registration
                number&nbsp;152377&nbsp;with registered office at&nbsp;Abraham de Veerstraat 9 , Curacao P.O Box 3421 and is
                licensed and regulated by the Curacao authority as the regulatory body responsible holding a
                (Sub-license with License number 365/JAZ Sub-License GLH- OCCHKTW0707072017 granted on 6.07.2019).&nbsp;
              </p>
              <p>Players are requested not to contact any untrusted sources for&nbsp;<span>Sky247.com</span>&nbsp;accounts as
                this is an online site and they can only register independently without any agents. Only deposit
                through the account details generated by the system or provided by our official support team.</p>
              <div className="contect-cont">
                <div className="contect-phone">
                  <img src={iconwhatsapp} />
                  <span>000</span>
                </div>
                <div className="contect-mail">
                  <img src={iconmail} />
                  <a href="mailto:support@sky247.com">support@sky247.com</a></div>
              </div>
            </div>
            <div className="license-iframe">
              <iframe src="https://licensing.gaming-curacao.com/validator/?lh=7a83475c9e54450a218a18bd28e33fad&template=tseal" width={150} height={50} style={{ border: 'none' }} />
            </div>
          </div>
          <div className="footer-logo-wrap" style={{ padding: "2.6666666667vw" }}>
            <div className="footer-logo no-sponsor">
              <div className="logo data-image" id="footer_logo" />
            </div>
            <div className="link-wrap">
              <ul style={{ display: 'flex' }}>
                <li>
                  <a data-pagetype={21}>
                    About us
                  </a>
                </li>
                <li>
                  <a data-pagetype={22}>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a data-pagetype={23}>
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a data-pagetype={24}>
                    Rules and Regulations
                  </a>
                </li>
                <li>
                  <a data-pagetype={25}>
                    Responsible Gambling
                  </a>
                </li>
                <li>
                  <a data-pagetype={26}>
                    FAQ
                  </a>
                </li>
              </ul>
              <p>© 2022 Sky247 Copyrights. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

import React from 'react'
import {Link} from "react-router-dom"

export const Soccermatches = () => {
    return (
        <React.Fragment>
         <ul className="slip-head">
                <li className="col-game" style={{ width: 'calc(64% - 20)' }} />
                <li className="col-visit">1</li>
                <li className="col-draw">x</li>
                <li className="col-home">2</li>
                <li className="col-info" />
            </ul>
            <div>
                <dl className="game-list-col  " style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <Link to="/soccerfullmarket">FK Babrungas v FK Panevezys II</Link>
                        <span className="game-list-time  ">4:30 PM</span>
                        <span class="in_play">In-Play</span>
                        <span class="game-live" id="streamingIcon" style={{display: 'inline-flex'}}>Live</span>
                    </dt>
                    <dd id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">2.12</a>
                        <a id="lay1_0" className="btn-lay ">2.18</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">3.9</a>
                        <a id="btnLay" className="btn-lay">4.6</a>
                    </dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back">3.25</a>
                        <a id="lay2_0" className="btn-lay">3.7</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/1/31368285/1.197489381">Middlesbrough v Huddersfield</a>
                        <span className="game-list-time  ">5:00 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">1.85</a>
                        <a id="lay1_0" className="btn-lay ">1.86</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">3.65</a>
                        <a id="btnLay" className="btn-lay ">3.7</a>
                    </dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">5.2</a>
                        <a id="lay2_0" className="btn-lay ">5.3</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/1/31372844/1.197592676">Ternana v Frosinone</a>
                        <span className="game-list-time  ">9:30 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">2.76</a>
                        <a id="lay1_0" className="btn-lay ">2.98</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">3.35</a>
                        <a id="btnLay" className="btn-lay ">3.45</a>
                    </dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">2.7</a>
                        <a id="lay2_0" className="btn-lay ">2.86</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/1/31352838/1.197163707">Napoli v Roma</a>
                        <span className="game-list-time  ">10:30 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">2.04</a>
                        <a id="lay1_0" className="btn-lay ">2.06</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">3.65</a>
                        <a id="btnLay" className="btn-lay ">3.7</a>
                    </dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">4.1</a>
                        <a id="lay2_0" className="btn-lay ">4.2</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" /><a href="/exchange/member/fullmarket/1/31352941/1.197176280">Barcelona v Cadiz</a><span className="game-list-time  ">Tommorow 12:30 AM</span></dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">1.29</a>
                        <a id="lay1_0" className="btn-lay ">1.3</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">6.4</a>
                        <a id="btnLay" className="btn-lay ">6.6</a>
                    </dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">14</a>
                        <a id="lay2_0" className="btn-lay ">14.5</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}><dt>
                    <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                    <a href="/exchange/member/fullmarket/1/31352835/1.197164095">Atalanta v Verona</a>
                    <span className="game-list-time  ">Tommorow 12:30 AM</span>
                </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">1.69</a>
                        <a id="lay1_0" className="btn-lay ">1.7</a></dd>
                    <dd className="col-draw"><a id="btnBack" className="btn-back ">4.3</a>
                        <a id="btnLay" className="btn-lay ">4.4</a></dd>
                    <dd className="col-home"><a id="back2_0" className="btn-back ">5.5</a>
                        <a id="lay2_0" className="btn-lay ">5.6</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
            </div>
        </React.Fragment>
    )
}

import React from 'react'

export const Signup = () => {
    return (
        <React.Fragment>
            <header className="member">
                <div className="header-left-btn-group">
                    <div className="back-btn">
                        <a href="/" />
                    </div>
                    <div className="menu-btn">
                        <ul>
                            <li />
                            <li />
                            <li />
                        </ul>
                    </div>
                </div>
                <div className="header-title">Sign up</div>
            </header>
            <div className="content member-content">
                <form id="registerForm" noValidate="novalidate">
                    <div className="banner" style={{}}>
                        <section className="banner-v1 slider slick-initialized slick-slider slick-dotted" id="register_banner_container">
                            <div className="slick-list draggable" style={{ padding: '0px 50px' }}>
                                <div className="slick-track" style={{ opacity: 1, width: '275px', transform: 'translate3d(0px, 0px, 0px)' }}>
                                    <div id="slick-slide00" title="Sky247 Welcome Offer" className="slick-slide slick-current slick-active slick-center" data-slick-index={0} aria-hidden="false" tabIndex={0} role="tabpanel" aria-describedby="slick-slide-control00" style={{ width:'265px'}}>
                                        {/* href="" */}
                                        <a data-pk tabIndex={0}>
                                            <div className="banner-image data-image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ul className="slick-dots" role="tablist">
                                <li className="slick-active" role="presentation">
                                    <button type="button" role="tab" id="slick-slide-control00" aria-controls="slick-slide00" aria-label="1 of 1" tabIndex={0} aria-selected="true">1</button>
                                    </li>
                            </ul>
                        </section>
                    </div>
                    <div style={{ display: 'block' }}>
                        <div id="register_banner_template">
                          
                            <a data-pk >
                                <div className="banner-image data-image" data-image style={{ backgroundImage: 'url("")' }} />
                            </a>
                        </div>
                    </div>
                    <div className="member-box radius">
                        {/*input text*/}
                        <div className="inputbox inputbox error">
                            <label htmlFor="userId">
                                Username
                            </label>
                            <input type="text" className="input error" id="userId" name="userId" style={{ textTransform: 'lowercase' }} placeholder="4-15 char, allow number" maxLength={15} aria-required="true" />
                            <input className="clear" />
                        </div>
                        {/*input password*/}
                        <div className="inputbox password">
                            <div className="eyes" />
                            <label htmlFor="password">
                                Password
                            </label>
                            <input id="password" name="password" type="password" minLength={6} maxLength={20} className="input error" placeholder="6-20 char, allow number" aria-required="true" />
                            <input className="clear" />
                        </div>
                        {/*input password*/}
                        <div className="inputbox password">
                            <div className="eyes" />
                            <label htmlFor="confirmPassword">
                                Confirm Password
                            </label>
                            <input id="confirmPassword" name="confirmPassword" type="password" minLength={6} maxLength={20} className="input valid" placeholder="confirm password" />
                            <input className="clear" />
                        </div>
                        <input type="hidden" id="currencyType" name="currencyType" defaultValue={7} />
                    </div>
                    <div className="member-box radius">
                        {/*input text*/}
                        <div className="inputbox">
                            <label>
                                Full Name
                            </label>
                            <input type="text" className="input error" id="realName" name="name" maxLength={30} placeholder="Full Name" aria-required="true" />
                            <input className="clear" />
                        </div>
                        {/*input phone*/}
                        <div className="inputbox phone">
                            <label>
                                Phone Number
                            </label>
                            <p id="callingCode">+91</p>
                            <input type="tel" className="input error" id="phoneNumber" name="phone" maxLength={15} placeholder="Phone Number" aria-required="true" />
                            <input className="clear" />
                        </div>
                        {/*input text*/}
                        <div className="inputbox">
                            <label>
                                Email
                            </label>
                            <input type="email" className="input error" id="email" name="email" placeholder="Email" aria-required="true" />
                            <input className="clear" />
                        </div>
                    </div>
                    <div className="member-box radius">
                        <div className="inputbox refer-code">
                            <label>Do you have a Refer Code?</label>
                            <div className="more-refer-info active">
                                <span>More info</span>
                            </div>
                            <div className="refer-info-text" style={{ display: 'block' }}>
                                <input id="friendReferCode" name="friendReferCode" type="text" defaultValue minLength={6} maxLength={20} placeholder="Enter if you have one" className="valid" />
                                <ul>
                                    <li>Referral code must be provided by any friend playing on Sky247.</li>
                                    <li>रेफ़रल कोड Sky247 पर खेलने वाले किसी भी मित्र द्वारा प्रदान किया जाना चाहिए</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="member-box radius">
                        {/*input text*/}
                        <div className="inputbox verification">
                            <label>
                                Verification Code
                            </label>
                            <input id="captchaInput" name="captcha" type="text" maxLength={4} placeholder="Enter 4 digit code" aria-required="true" className="error" />
                            <input className="clear" type="reset" />
                            <div className="verification-code">
                                <img name="captchaImg" src="/captcha?0.9431424107420192" style={{ width: '60px', height: '30px', position: 'relative', zIndex: 10 }} />
                            </div>
                            <div className="refresh" onclick="RegisterHandler.getCaptcha();" />
                        </div>
                    </div>
                    <div id="validationError" className="member-error">
                        <label id="userId-error" className="error" htmlFor="userId">Username is not valid.</label></div>
                    <div id="registerButton" className="button">
                        <a>
                            Confirm
                        </a>
                    </div>
                    <p>
                        I'm 18 years old, and agree to "terms and conditions".
                    </p>
                </form>
            </div>
        </React.Fragment>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
const Sportsnav = () => {
    return (
        <React.Fragment>
            <div _ngcontent-serverapp-c66="" className="nav-wrap ng-star-inserted">
                <div className="content-title">
                    <h2 _ngcontent-serverapp-c66="">
                        <span _ngcontent-serverapp-c66="">Sports</span></h2></div>
                <div _ngcontent-serverapp-c66="" mcddragmove className="nav-content-wrap">
                    <div _ngcontent-serverapp-c66="" className="nav-content-inner" style={{ transform: 'translate(0%, 0px)' }}>
                        <div _ngcontent-serverapp-c66="" className="content-box ng-star-inserted" style={{}}>
                            <div _ngcontent-serverapp-c66="" className="layout-brand ng-star-inserted">
                                <div _ngcontent-serverapp-c66="" className="card1">
                                    <ul _ngcontent-serverapp-c66="">
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <Link _ngcontent-serverapp-c66="" to="#">{/**/}
                                                <div _ngcontent-serverapp-c66="" className="icon-sport exchange ng-star-inserted" />
                                                <p _ngcontent-serverapp-c66="">Exchange</p>
                                            </Link>
                                        </li>
                                        <li _ngcontent-serverapp-c66=""
                                            className="ng-star-inserted">
                                            <Link _ngcontent-serverapp-c66="">{/**/}
                                                <div _ngcontent-serverapp-c66="" className="icon-sport sportbook ng-star-inserted" />
                                                <p _ngcontent-serverapp-c66="">Sportsbook</p>
                                            </Link>
                                        </li>
                                        <li _ngcontent-serverapp-c66=""
                                            className="ng-star-inserted">
                                            <Link _ngcontent-serverapp-c66="" >{/**/}
                                                <div _ngcontent-serverapp-c66="" className="icon-sport kabaddi ng-star-inserted" />
                                                <p _ngcontent-serverapp-c66="">Kabaddi</p>
                                            </Link>
                                        </li>
                                        <li _ngcontent-serverapp-c66=""
                                            className="ng-star-inserted">
                                            <Link _ngcontent-serverapp-c66="" onWaiting>{/**/}
                                                <div _ngcontent-serverapp-c66="" className="icon-sport awcmbc ng-star-inserted" />
                                                <p _ngcontent-serverapp-c66="">BC Sports</p>
                                            </Link>
                                        </li>{/**/}
                                        <li _ngcontent-serverapp-c66=""
                                            className="ng-star-inserted" />
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                        {/**/}</ul>
                                </div>
                            </div></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sportsnav

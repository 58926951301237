import React from 'react'

export const SlotNav = () => {
    return (
        <React.Fragment>
            <div className="top-nav-wrap">
    <div className="top-nav-inner">
        <div className="search-wrap">
        <i className="search-icon" />
            <input id="gameSearch" type="text" placeholder="Search Games" />
        </div>
        <ul id="game-sort-wrap" className="sort-wrap">
            <li> <a className="btn-sort">Sort：</a></li>
            <li><a className="btn-sort active" data-sort="displayOrder">Recommend</a></li>
            <li><a className="btn-sort" data-sort="createTime">Latest</a></li>
            <li>
                <a className="btn-sort" data-sort="favorite">Favorite</a>
            </li>
            <li><a className="btn-sort" data-sort="aZ">A-Z</a></li>
        </ul>
    </div>
</div>
        </React.Fragment>
    )
}

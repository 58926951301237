import React from 'react'

export const Turnover = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
            <div className="container-player-group">
            <div className="player-title-box">
            <h2>
            <ui-translate key="fe.text.report.turnover" lang="en">Turnover</ui-translate></h2>
            <div className="tab-primary-box">
            <ul id="turnoverTabContainer">
            <li>
            <a data-toggle="tab" className="active" id="unCompleteTurnoverTab">
            <ui-translate key="fe.text.turnover.active" lang="en">Active</ui-translate></a></li>
            <li><a data-toggle="tab" id="completeTurnoverTab">
            <ui-translate key="fe.text.turnover.finished" lang="en">Completed</ui-translate></a></li></ul>
            <div className="slider-tab" style= {{width: '160px'}}/>
</div>
</div>
<div className="main-tab-content">
<section className="tab-pane active">
<div id="unCompleteTurnoverContainer" className="all-wallet-wrap no-info-txt">
<div className="no-info-img pre-load">
<img className="img-load" src="/images/player/table/no-value.svg" /></div>
<p>No Turnover !</p></div></section>
<section className="tab-pane">
<div id="completeTurnoverContainer" className="all-wallet-wrap" /></section></div></div>
<div style= {{display: 'none'}}>
<div id="unCompleteTurnoverTemplate" className="bonus-wallet-wrap">
<div className="bonus-wallet">
<div className="title" ui-key="title">-</div>
<div className="wallet-label now" />
<div className="wallet-inner">
<div className="wallet-inner-left bg-load data-image" data-image data-preload style= {{backgroundImage: 'url("")'}}/>
<div className="wallet-inner-right" data-messagetype= {1} data-id>
<div className="card-detail">
<div className="card-date end_time">
<ui-translate key="fe.text.promotion.expiry_date" lang="en">Event ends in: {'{'}0 {'}'}

</ui-translate>：<span ui-key="end_time">-</span></div>
<div className="detail-btn"><a>
<ui-translate key="fe.text.figure_show_detail" lang="en">Detail</ui-translate></a></div></div>
<div className="discount">&nbsp;
<div className="currency">₹</div>
<div className="amount" ui-key="completeTurnover">0.00</div></div>
<div className="discount02">
<div className="turnover">
<ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate>：X<span ui-key="multiplier">-</span></div></div>
<div className="progress-group">
<div className="progress">
<div className="progress-bar" role="progressbar" aria-valuenow= {70}aria-valuemin= {0}aria-valuemax= {100} style= {
        {
        width: 0
    }
}

/></div>
<span className="vip-now">0.00</span>
<span className="vip-next" ui-key="requirement_turnover">0.00</span></div></div></div></div></div>
<div id="completeTurnoverTemplate" className="bonus-wallet-wrap">
<div className="bonus-wallet wallet-color-gray">
<div className="title" ui-key="title">-</div>
<div className="wallet-label wallet-label-hot turn-gray" /><div className="wallet-inner">
<div className="wallet-inner-left bg-load data-image" data-image data-preload style= {{backgroundImage: 'url("")'}}/>
<div className="wallet-inner-right" data-messagetype= {1} data-id>
<div className="card-detail">
<div className="card-date end_time"><ui-translate key="fe.text.promotion.expiry_date" lang="en">Event ends in: {'{'}0 {'}'}

</ui-translate>：<span ui-key="end_time">-</span></div>
<div className="detail-btn"><a><ui-translate key="fe.text.figure_show_detail" lang="en">Detail</ui-translate></a></div></div>
<div className="discount">&nbsp;
<div className="currency">₹</div>
<div className="amount" ui-key="completeTurnover">0.00</div></div>
<div className="discount02"><div className="turnover">
<ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate>：X<span ui-key="multiplier">-</span></div></div>
<div className="progress-group">
<div className="progress">
<div className="progress-bar" role="progressbar" aria-valuenow= {70} aria-valuemin= {0} aria-valuemax= {100}

style= {{width: 0}}/>
</div>
<span className="vip-now">0.00</span>
<span className="vip-next" ui-key="requirement_turnover">0.00</span></div></div></div></div></div></div></div>
        </React.Fragment>
    )
}

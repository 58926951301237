import React, { useState, useEffect, useRef } from 'react'
import { Menusecond } from './Menusecond';
import { MobAnnouncement } from './MobAnnouncement';
import { Link } from 'react-router-dom'
import { VerifyAccount } from '../MobileComponent/VerifyAccount'
import MobCarousel from './MobileImage/MobCarousel';
import { MobNav } from "./MobNav"
import { Mobinbox } from "./Mobinbox"
import background from '../images/sky247.png'
import { NavBanner } from "./NavBanner"
import { Promotions } from "./Promotions"
import { useDetectOutsideClick } from './useDetectOutsideClick'
import { Mobskybanner } from './MobileImage/Mobskybanner';
import { FooterTop } from './FooterTop';
import { PagefooterMenu } from './PagefooterMenu';
// import { Exchange } from './MobileExchange/Exchange';
import { Account } from './Account';
import { Login } from './Login'
import { Signup } from './Signup'
import axios from 'axios';
import { Deposit } from './Deposit';
import Cookies from 'universal-cookie';
import Sportsnav from './Sportsnav';
import Sportscasino from './Sportscasino';
import { Navdeposit } from './MobileExchange/Navdeposit';
import Sportslots from './Sportslots';
import { CricketFullmarket } from '../MobileComponent/MobileExchange/CricketFullmarket'
import { Tennisfullmarket } from '../MobileComponent/MobileExchange/Tennisfullmarket'

window.flag = 0;

var cc;
var tt;

const cookies = new Cookies();

export default function Mobcombine(props) {


  var eventId = null;

  var marketId = null;
  var eventT = 0;
  if (props.match.params.eventT === '4' || props.match.params.eventT === '1' || props.match.params.eventT === '2') {
    eventId = props.match.params.eventId;
    marketId = props.match.params.marketId;
    eventT = 0;
  }
  if (props.match.params.eventType === 'mobinbox') {
    eventT = 1;
  }
  else if (props.match.params.eventType === 'promotions') {
    eventT = 2;
  }
  else if (props.match.params.eventType === 'exchange') {
    eventT = 3;
  }
  else if (props.match.params.eventType === 'inplay') {
    eventT = 4;
  }
  else if (props.match.params.eventType === 'cricketmatches') {
    eventT = 5;
  }
  else if (props.match.params.eventType === 'soccermatches') {
    eventT = 6;
  }
  else if (props.match.params.eventType === 'tennismatches') {
    eventT = 7;
  }
  else if (props.match.params.eventType === 'cricketfullmarket') {
    eventT = 8;
  }
  else if (props.match.params.eventType === 'account') {
    eventT = 9;
  }

  else if (props.match.params.eventType === 'login') {
    eventT = 10;
  }
  else if (props.match.params.eventType === 'signup') {
    eventT = 11;
  }
  else if (props.match.params.eventType === 'soccerfullmarket') {
    eventT = 12;
  }
  else if (props.match.params.eventType === 'tennisfullmarket') {
    eventT = 13;
  }
  else if (props.match.params.eventType === 'deposit') {
    eventT = 14;
  }
  else if (props.match.params.eventType === 'verifyaccount') {
    eventT = 15;
  }
  else if (props.match.params.eventType === 'verifyaccount') {
    eventT = 15;
  }









  const dropdownRef = useRef(null);

  const [tabMenu, setTabMenu] = useState(eventT);
  const [dropdownopen, setdropdownopen] = useDetectOutsideClick(dropdownRef, false);
  const [Sportstab, setSportstab] = useState(0)
  const [Betshow, setBetshow] = useState(false)
  const [SHowLogin, setSHowLogin] = useState(false)
  const [accountclick, setaccountclick] = useState(false)
  const [massage, setmassage] = useState(false)
  const [tabsport, settabsport] = useState(0)
  const [showFullMarket, setFullMarket] = useState(false);

  const [countCricket, setCountCricket] = useState(0);
  const [countSoccer, setCountSoccer] = useState(0);
  const [countTennis, setCountTennis] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [isError, setIsError] = useState(false);
  const [userid, setId] = useState("");
  const [password, setPassword] = useState("");
  const [captchaSuccess, setCaptcha] = useState(false);
  const [refreshhh, setrefreshhh] = useState(false);
  const [balance, setBalance] = useState(0);
  const [exposure, setExposure] = useState(0);
  const [userIDname, setuserIDname] = useState(false)
  const [passPass, setpassPass] = useState(false)
  const [newOpen, setnewOpen] = useState(false);
  const [expoSu, setexpoSu] = useState(false)
  const [refreshMsg, setrefreshMsg] = useState('Loading...')
  const [balan, setbalan] = useState(false)
  const [accOpen, setaccOpen] = useState(false)
  const [Tvopen, setTvopen] = useState(true)
  const [openLeftside, setopenLeftside] = useState(false)
  const [openRightside, setopenRightside] = useState(false)
  const [FancyRulespopup, setFancyRulespopup] = useState(false)
  const [betSucess1, setbetSucess1] = useState(false)
  const [msgNumber, setMsgNumber] = useState(0);
  const [betInfo, setbetInfo] = useState({});
  const [stakeInfo, setstakeInfo] = useState({});
  const [isShown, setisShown] = useState(false);
  const [liveStream, setliveStream] = useState(null);
  const [PrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [TermnCo, setTermnCo] = useState(false);
  const [RnR, setRnR] = useState(false);
  const [kyc, setkyc] = useState(false);
  const [Resg, setResg] = useState(false);
  const [Abtus, setAbtus] = useState(false);
  const [SEP, setSEP] = useState(false);
  const [UApolcy, setUApolcy] = useState(false);



  const changeLiveStreaming = (val) => {
    setliveStream(val);
    setTvopen(true);

  }



  const changeMsgNumber = (val) => {
    setMsgNumber(val);
  }

  const changeBetInfo = (obj) => {
    var obj2 = obj;
    setbetInfo(obj2);
  }

  const changeStakeInfo = (obj) => {
    var obj2 = obj;
    setstakeInfo(obj2);
  }


  const handlePass = (value) => {
    setpassPass(value)
  }

  const handlebal = (value) => {
    setbalan(value)
  }

  const handleExpo = (value) => {
    setexpoSu(value)
  }


  useEffect(() => {
    setTabMenu(eventT);

  }, [eventT]);


  useEffect(() => {
    if (cookies.get('token') == null) {
      setLoggedIn(false);
      return;
    }
    else {
      var utoken = cookies.get('token');
      tt = utoken;
      var user = cookies.get('id');
      setId(user);
      axios.post('https://millionbet247.com/api/client/clientrefresh', {
        id: user,
        token: utoken
      })
        .then(result => {
          if (result.status === 210) {
            console.log('refresh successful');
            setLoggedIn(true);
            setBalance(result.data[0].val - Math.abs(result.data[1].val));

            if (result.data[1].val) {
              setExposure(Math.abs(result.data[1].val));
            }
            else {
              setExposure(0);

            }

          }

          if (result.status === 220) {
            console.log('refresh unsucessful');
            setLoggedIn(false);

          }
        }

        ).catch(e => {
          setIsError(true);
        });
    }
  }, []);




  const changeState = (obj) => {
    var obj1 = true;
    obj1 = obj;
    setFullMarket(obj1);
    changeTab(0);
  };

  const changeExposure = (obj) => {
    setExposure(obj);
  }

  const changeBalance = (obj) => {
    setBalance(obj);
  }


  const changeTab = (val) => {
    setTabMenu(val);
  }
  const checkShowLogin = (bool) => {
    setSHowLogin(bool);
  }



  const handleLogout = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('token', { path: '/' });
    setTabMenu(3);
    setTimeout(() => { window.location.reload(); }, 200);

  }


  const handleRefreshhh = (t = 1000, msg = 'Loading...', flag = 0) => {

    if (flag === 1) {
      setrefreshhh(true);
      setrefreshMsg(msg);
    }
    else if (flag === 2) {
      setrefreshhh(false);

    }
    else {
      setrefreshhh(true);
      setrefreshMsg(msg)
      setTimeout(() => { setrefreshhh(false); }, t);
    }
  }


  const ChangeTv = () => {
    setTvopen(true)
    TvControl();
  }
  const ChangeSetTv = () => {
    setTvopen(false)
  }

  const hnadlefancyrules = () => {
    setFancyRulespopup(true)
  }
  const closefancyrules = () => {
    setFancyRulespopup(false)
  }
  const TvControl = () => {
    setisShown(true);
    setTimeout(() => {
      setisShown(false)
    }, 2700);
  }

  const MassageClick = () => {
    setmassage(true)
  }
  const MassageClickClose = () => {
    setmassage(false)
  }


  const BetleftOpen = () => {
    setBetshow(true)
  }
  const BetleftClose = () => {
    setBetshow(false)
  }
  const Clickaccount = () => {
    setaccountclick(!accountclick)
  }

  const CheckshowLogin = (bool) => {
    setSHowLogin(bool)
  }
  const dsClick = () => {
    if (window.flag === 0) {
      setdropdownopen(!dropdownopen);
    }
  }

  useEffect(() => {
    setTabMenu(eventT);

  }, [eventT]);
  const [settings, setsettings] = useState(false)
  const SettingsCkick = () => {
    setsettings(true)
  }
  const SettingsCkickClose = () => {
    setsettings(false)
  }








  return (
    <React.Fragment>
      <header _ngcontent-serverapp-c42="" id="header" className="normal ng-star-inserted">
        <div _ngcontent-serverapp-c42="" className="header-left-btn-group">
          <div _ngcontent-serverapp-c42="" mcdprevious className="back-btn" />
          <div _ngcontent-serverapp-c42="" className="menu-btn" onClick={() => { dsClick() }}>
            <ul _ngcontent-serverapp-c42="">
              <li _ngcontent-serverapp-c42="" />
              <li _ngcontent-serverapp-c42="" />
              <li _ngcontent-serverapp-c42="" />
            </ul>
          </div>
        </div>
        <div _ngcontent-serverapp-c42="" className="header-title" />
        <div _ngcontent-serverapp-c42="" className="logo" tabIndex={0} style={{ backgroundImage: `url(${background})`, backgroundSize: 'contain' }} />
        <div _ngcontent-serverapp-c42="" className="header-right-btn-group">
          <a _ngcontent-serverapp-c42="" className="whatapp-btn ng-star-inserted" href="https://b.link/zjh28d" target="_blank"></a>
          <div _ngcontent-serverapp-c42="" className="editor-btn" />
          <mcd-language _ngcontent-serverapp-c42="" _nghost-serverapp-c41 className="ng-tns-c41-3 ng-star-inserted"></mcd-language>
        </div>
      </header>


      <div id="pageContentBody" class="content">
        {tabMenu === 1 && <Mobinbox />}
        {tabMenu === 2 && <Promotions />}
        {tabMenu === 14 && <Navdeposit />}
        {tabMenu === 15 && <VerifyAccount />}
        {tabMenu === 10 && <Login CheckshowLogin={CheckshowLogin} />}
        {tabMenu === 11 && <Signup />}
        {tabMenu !== 1 && tabMenu !== 2 && tabMenu !== 14 && tabMenu !== 15 &&
          tabMenu !== 10 && <div class="content">
            {tabMenu !== 8 && tabMenu !== 12 && tabMenu !== 13 &&
              <div className="banner">
                <section id="announcement_banner" className="banner-v1 slider slick-initialized slick-slider slick-dotted">
                  <MobCarousel />
                </section>
              </div>}
            <MobAnnouncement MassageClick={MassageClick} />
            {tabMenu !== 8 && tabMenu !== 12 && tabMenu !== 13 && <MobNav tabsport={tabsport} settabsport={settabsport} tabMenu={tabMenu} />}
            {tabMenu !== 3 && tabMenu !== 8 && <>
              {tabsport === 0 && <Sportsnav />}
              {tabsport === 1 && <Sportscasino />}
              {tabsport === 2 && <Sportslots />}
            </>}


            {tabMenu !== 3 && tabMenu !== 4 && tabMenu !== 6 && tabMenu !== 7 && tabMenu !== 8 && tabMenu !== 12 && tabMenu !== 13 &&
              <>

                <Mobskybanner />
              </>
            }
          </div>
        }
        {tabMenu !== 1 && tabMenu !== 2 && tabMenu !== 8 && tabMenu !== 13 && tabMenu !== 12 && tabMenu !== 13 && tabMenu !== 10 && tabMenu !== 14 && tabMenu !== 15 && <FooterTop />}
        <Account Clickaccount={Clickaccount} accountclick={accountclick} CheckshowLogin={CheckshowLogin} />
        <PagefooterMenu tabMenu={tabMenu} Clickaccount={Clickaccount} CheckshowLogin={CheckshowLogin} SHowLogin={SHowLogin} />

        <div id="pageLeftMenu" className={` ${dropdownopen ? "menu active" : "menu"}`} ref={dropdownRef}>
          <div className="menu-first">
            <ul>
              <li data-category="home">
                <a data-pagetype={0}>
                  <ui-translate key="fe.text.nav.mainPage" lang="en">Home</ui-translate>
                </a>
              </li>
              {/* <li data-category="bets">
                <a data-pagetype={0} onClick={() => { BetleftOpen() }}>
                  <ui-translate key="fe.text.nav.mainPage" lang="en">Bets</ui-translate>
                </a>
              </li> */}
              <li data-category="sport" className={`${(Sportstab == 1) ? "active" : ""}`}>
                <a id="menuSport" onClick={() => { setSportstab(1) }} >
                  <ui-translate key="fe.text.nav.sport" lang="en">Sports</ui-translate>
                </a>
              </li>
              <li data-category="games" className={`${(Sportstab == 2) ? "active" : ""}`}>
                <a id="menuSlot" onClick={() => { setSportstab(2) }} >
                  <ui-translate key="fe.text.nav.slot" lang="en">Slots</ui-translate>
                </a>
              </li>
              <li data-category="casino" className={`${(Sportstab == 3) ? "active" : ""}`}>
                <a id="menuCasino" onClick={() => { setSportstab(3) }}>
                  <ui-translate key="fe.text.nav.casino" lang="en">Casino</ui-translate>
                </a>
              </li>
              <li data-category="tablegame" className={`${(Sportstab == 4) ? "active" : ""}`}>
                <a id="menuTable" onClick={() => { setSportstab(4) }} >
                  <ui-translate key="fe.text.nav.table" lang="en">Table</ui-translate>
                </a>
              </li>
              <li data-category="lottery" className={`${(Sportstab == 5) ? "active" : ""}`}>
                <a id="menuLottery" onClick={() => { setSportstab(5) }}>
                  <ui-translate key="fe.text.nav.lottery" lang="en">Lottery</ui-translate>
                </a>
              </li>
            </ul>
            <ul>
              <li data-category="promotion">
                <a data-pagetype={9}>
                  <ui-translate key="fe.text.promotions" lang="en">Promotions</ui-translate>
                </a>
              </li>
            </ul>
          </div>
          <Menusecond Sportstab={Sportstab} setSportstab={setSportstab} /></div>


        <div id="pop-bg" class="pop-bg" style={dropdownopen ? { display: 'block' } : { display: 'none' }}>

        </div>
      </div>
    </React.Fragment>
  )
}

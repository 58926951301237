import React from 'react'

export const Bankstatement = () => {
    return (
        <React.Fragment>
            <div className="tabContent tabContent-page">
    <div className="inner-wrap" style={{transform: 'translate(-100%, 0px)'}}>
        <div className="inner-box" id="verifyAccountDocumentBox" data-content-current style={{height: '0px'}}>
            <form id="verify_account" noValidate="novalidate">
                <div className="menu-box">
                    <div className="option-group">
                        <label htmlFor="documentType"><ui-translate key="fe.text.verifyAccount.document" lang="en">Document</ui-translate></label>
                        <div className="option-wrap">
                            <select name="documentType" id="documentType" aria-required="true">
                <option value={-1}>Choose document type</option>
                <option value={1}>Identity Card</option><option value={4}>Passport</option><option value={8}>Driver's License</option><option value={32}>PAN Card</option>
              </select>
                        </div>
                        <div className="member-error" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="documentNo"><ui-translate key="fe.text.verifyAccount.primaryId" lang="en">Document No</ui-translate>*</label>
                        <div className="input-wrap">
                            <input type="text" name="documentNo" id="documentNo" placeholder="Type ID No here" aria-required="true" />
                        </div>
                        <div className="member-error" />
                    </div>
                    <div className="input-group">
                        <label htmlFor="expiryDate"><ui-translate key="fe.text.verifyAccount.expiryDate" lang="en">Expiry Date</ui-translate></label>
                        <div className="input-wrap">
                            <input type="date" name="expiryDate" id="expiryDate" />
                        </div>
                        <div className="member-error" />
                    </div>
                    <div className="input-group">
                        <label><ui-translate key="fe.text.verifyAccount.primaryIdFrontPhoto" lang="en">Upload Document Photo (Front side)</ui-translate>*</label>
                        <div className="upload-wrap">
                            <div className="upload-area">
                                <i className="delete-btn red" />
                                <div className="area-pic">
                                </div>
                                <div className="area-inner">
                                    <input className="upload-img" type="file" accept="image/*" id="documentFrontImage" name="documentFrontImage" />
                                    <div className="u-icon" />
                                    <div className="u-text">
                                        <ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate>
                                    </div>
                                    <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="input-group">
                        <label><ui-translate key="fe.text.verifyAccount.primaryIdBackPhoto" lang="en">Upload Document Photo (Back side)</ui-translate></label>
                        <div className="upload-wrap">
                            <div className="upload-area">
                                <i className="delete-btn red" />
                                <div className="area-pic">
                                </div>
                                <div className="area-inner">
                                    <input className="upload-img" type="file" accept="image/*" id="documentBackImage" name="documentBackImage" />
                                    <div className="u-icon" />
                                    <div className="u-text">
                                        <ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate>
                                    </div>
                                    <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-wrap show">
                            <div className="a-title toggle-btn">
                                <div className="a-icon" />
                                <div className="a-text">
                                    <ui-translate key="fe.text.verifyAccount.tips" lang="en">Tips</ui-translate>
                                </div>
                                <div className="a-arrow" />
                            </div>
                            <div className="a-content">
                                <ol className="a-list">
                                    <li className="a-item">
                                        <ui-translate key="fe.text.verifyAccount.tips1" lang="en">Upload jpg, jpeg or png file.</ui-translate>
                                    </li>
                                    <li className="a-item">
                                        <ui-translate key="fe.text.verifyAccount.tips2" params="[&quot;5M&quot;]" lang="en">Not over 5M.</ui-translate>
                                    </li>
                                    <li className="a-item">
                                        <ui-translate key="fe.text.verifyAccount.tips7" params="[&quot;3&quot;]" lang="en">Upload file limit count for 3.</ui-translate>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="accordion-wrap show error" id="documentError">
                            <div className="a-title">
                                <div className="a-icon" />
                                <div className="a-text">
                                    <ui-translate key="fe.text.verifyAccount.reviewFailed" lang="en">Review Failed</ui-translate>
                                </div>
                                <div className="a-arrow" />
                            </div>
                            <div className="a-content">
                                <div className="a-list">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="button" id="kyc-verification-btn">
                <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
            </div>
        </div>
        <div className="inner-box" id="verifyAccountBankBox" data-content-current="current" style={{height: 'auto'}}>
            <div className="menu-box">
                <div className="dropDown-menu" id="addBankCard">
                    <div className="dropDown-menu-btn addBankCard">
                        <div className="icon"><img src="/images/h5/icon-set/icon-add-card.svg" /></div>
                        <div className="text">
                            <ui-translate key="fe.text.addBankStatement" lang="en">Add Bank Statement</ui-translate>
                        </div>
                    </div>
                    <div className="dropDown-menu-content">
                        <div className="menu-box">
                            <form name="content_verify_localBankForm_0" noValidate="novalidate">
                                <div className="option-group" name="userBankIdDiv">
                                    <label htmlFor="userBankId"><ui-translate key="fe.text.wallet.deposit.from_bank" lang="en">Bank Name</ui-translate></label>
                                    <div className="option-wrap">
                                        <select id="userBankId" name="userBankId" aria-required="true">
                      <option data-bank value>-- Select --</option>
                      <option data-bank={279} value={279}>AKHAND ANAND CO-OP BANK</option>
                      <option data-bank={682} value={682}>AOU Bank</option>
                      <option data-bank={286} value={286}>AU Small Finance Bank</option>
                      <option data-bank={281} value={281}>Aditya Birla payment bank</option>
                      <option data-bank={248} value={248}>Airtel Payment Bank</option>
                      <option data-bank={221} value={221}>Allahabad Bank</option>
                      <option data-bank={226} value={226}>Andhra Bank</option>
                      <option data-bank={372} value={372}>Andhra Pradesh Grameena Vikas Bank</option>
                      <option data-bank={681} value={681}>Andhra Pradesh Grameena Vikas Bank</option>
                      <option data-bank={287} value={287}>Andhra Pragathi Grameena Bank</option>
                      <option data-bank={379} value={379}>Apex Bank</option>
                      <option data-bank={276} value={276}>Apna Sahakari Bank</option>
                      <option data-bank={354} value={354}>Aryavrat Gramin Bank</option>
                      <option data-bank={374} value={374}>Assam Gramin Vikas Bank</option>
                      <option data-bank={215} value={215}>Axis Bank</option>
                      <option data-bank={727} value={727}>Axis Bank Corporate</option>
                      <option data-bank={247} value={247}>Bandhan Bank</option>
                      <option data-bank={278} value={278}>Bandhan Bank Ltd</option>
                      <option data-bank={728} value={728}>Bank of Bahrain And Kuwait</option>
                      <option data-bank={213} value={213}>Bank of Baroda</option>
                      <option data-bank={729} value={729}>Bank of Baroda Corporate</option>
                      <option data-bank={730} value={730}>Bank of Baroda Retail Accounts</option>
                      <option data-bank={235} value={235}>Bank of India</option>
                      <option data-bank={227} value={227}>Bank of Maharashtra</option>
                      <option data-bank={683} value={683}>Bank of Mercantile</option>
                      <option data-bank={684} value={684}>Baroda Uttar Pradesh Gramin</option>
                      <option data-bank={416} value={416}>Baroda rajsathan kshetriya gramin bank</option>
                      <option data-bank={462} value={462}>Bassein catholic Bank</option>
                      <option data-bank={380} value={380}>Bharat Co-Operative Bank (Mumbai) Ltd</option>
                      <option data-bank={685} value={685}>Bhavana bank</option>
                      <option data-bank={234} value={234}>Canara Bank</option>
                      <option data-bank={249} value={249}>Central Bank</option>
                      <option data-bank={611} value={611}>Chaitanya Godavari Grameena Bank</option>
                      <option data-bank={245} value={245}>Citi Bank</option>
                      <option data-bank={257} value={257}>Citizen credit co-operative bank ltd</option>
                      <option data-bank={229} value={229}>City Union Bank</option>
                      <option data-bank={230} value={230}>Corporation Bank</option>
                      <option data-bank={273} value={273}>Cosmos bank</option>
                      <option data-bank={237} value={237}>DBS Bank</option>
                      <option data-bank={272} value={272}>DCB BANK</option>
                      <option data-bank={731} value={731}>DCB Bank Corporate</option>
                      <option data-bank={297} value={297}>Deepak Sahakari Bank Ltd.</option>
                      <option data-bank={233} value={233}>Dena Bank</option>
                      <option data-bank={732} value={732}>Deutsche Bank</option>
                      <option data-bank={686} value={686}>Dhanlaxmi Bank</option>
                      <option data-bank={539} value={539}>ESAF Small Finance Bank</option>
                      <option data-bank={289} value={289}>Equitas bank</option>
                      <option data-bank={387} value={387}>FINO BANK</option>
                      <option data-bank={241} value={241}>Federal Bank</option>
                      <option data-bank={368} value={368}>Fincare small finance bank</option>
                      <option data-bank={214} value={214}>HDFC Bank</option>
                      <option data-bank={264} value={264}>HSBC Bank</option>
                      <option data-bank={211} value={211}>ICICI Bank</option>
                      <option data-bank={687} value={687}>ICICI Corporate Bank</option>
                      <option data-bank={222} value={222}>IDBI Bank</option>
                      <option data-bank={216} value={216}>IDFC Bank</option>
                      <option data-bank={295} value={295}>IDFC First Bank</option>
                      <option data-bank={404} value={404}>INDIA POST PAYMENT BANK</option>
                      <option data-bank={254} value={254}>ING Vysya Bank Limited</option>
                      <option data-bank={225} value={225}>Indian Bank</option>
                      <option data-bank={240} value={240}>Indian Overseas Bank</option>
                      <option data-bank={239} value={239}>Indusind Bank</option>
                      <option data-bank={256} value={256}>Induslnd Bank</option>
                      <option data-bank={733} value={733}>Industrial Development Bank of India</option>
                      <option data-bank={734} value={734}>IngVysya Bank</option>
                      <option data-bank={250} value={250}>JAMMU AND KASHMIR BANK LTD</option>
                      <option data-bank={270} value={270}>Jana Small Finance Bank</option>
                      <option data-bank={688} value={688}>Janata sahakari bank</option>
                      <option data-bank={238} value={238}>Karnataka Bank</option>
                      <option data-bank={689} value={689}>Karnataka Gramin Bank</option>
                      <option data-bank={280} value={280}>Karnataka Vikas Grameena Bank</option>
                      <option data-bank={277} value={277}>Karu Vysya Bank</option>
                      <option data-bank={246} value={246}>Karur Vysya Bank</option>
                      <option data-bank={294} value={294}>Kerala Gramin Bank</option>
                      <option data-bank={220} value={220}>Kotak Mahindra Bank</option>
                      <option data-bank={260} value={260}>Lakshmi Vilas Bank</option>
                      <option data-bank={231} value={231}>Laxmi Vilas Bank</option>
                      <option data-bank={271} value={271}>MANVI PATTANA SOUHARDA SAHAKARI BANK</option>
                      <option data-bank={690} value={690}>Madhya Pradesh Gramin Bank</option>
                      <option data-bank={292} value={292}>Madhya bihar gramin bank</option>
                      <option data-bank={542} value={542}>Maharstra Gramin Bank</option>
                      <option data-bank={356} value={356}>Malad Sahakari Bank Limited</option>
                      <option data-bank={298} value={298}>NKGSB COOPERATIVE BANK LIMITED</option>
                      <option data-bank={464} value={464}>NSDL PAYMENT BANK</option>
                      <option data-bank={232} value={232}>Oriental Bank of Commerce</option>
                      <option data-bank={263} value={263}>PARSIK SAHAKARI BANK</option>
                      <option data-bank={251} value={251}>PAYTM SAVING BANK</option>
                      <option data-bank={266} value={266}>PMC Bank</option>
                      <option data-bank={236} value={236}>Paytm</option>
                      <option data-bank={252} value={252}>Pragati Krishna Bank</option>
                      <option data-bank={742} value={742}>Prathama UP Gramin Bank</option>
                      <option data-bank={691} value={691}>Punjab Gramin Bank</option>
                      <option data-bank={212} value={212}>Punjab National Bank</option>
                      <option data-bank={735} value={735}>Punjab National Bank Corporate</option>
                      <option data-bank={268} value={268}>Punjab and sind bank</option>
                      <option data-bank={692} value={692}>Purvanchal Bank</option>
                      <option data-bank={311} value={311}>RUPEEO Online Bank</option>
                      <option data-bank={267} value={267}>Ratnakar Bank Limited</option>
                      <option data-bank={296} value={296}>SUCO BANK</option>
                      <option data-bank={694} value={694}>SUVARNAYUG SAHAKARI BANK LTD</option>
                      <option data-bank={261} value={261}>SVC Cooperative Bank Ltd</option>
                      <option data-bank={543} value={543}>Saptagiri Grameena Bank</option>
                      <option data-bank={693} value={693}>Saraswat bank</option>
                      <option data-bank={262} value={262}>Sarva Haryana Gramin Bank</option>
                      <option data-bank={381} value={381}>Sind Co-operative Urnan Bank Limited (Sind Bank)</option>
                      <option data-bank={243} value={243}>South Indian Bank</option>
                      <option data-bank={242} value={242}>Standard Chartered Bank</option>
                      <option data-bank={269} value={269}>State BANK OF Travancore</option>
                      <option data-bank={736} value={736}>State Bank of Bikaner And Jaipur</option>
                      <option data-bank={255} value={255}>State Bank of Hyderabad</option>
                      <option data-bank={210} value={210}>State Bank of India</option>
                      <option data-bank={244} value={244}>State Bank of Mysore</option>
                      <option data-bank={283} value={283}>State Bank of Patiala</option>
                      <option data-bank={219} value={219}>Syndicate Bank</option>
                      <option data-bank={265} value={265}>THE CATHOLIC SYRIAN BANK Ltd.</option>
                      <option data-bank={695} value={695}>THE NAINITAL BANK LIMITED</option>
                      <option data-bank={293} value={293}>TJSB Sahakari Bank Ltd.</option>
                      <option data-bank={282} value={282}>Tamilnad Mercantile Bank.</option>
                      <option data-bank={253} value={253}>Telangana Grameena Bank</option>
                      <option data-bank={258} value={258}>The Akola Urban Co-operative Bank LTD</option>
                      <option data-bank={285} value={285}>The Kalupur Commercial Co-operative Bank Limited</option>
                      <option data-bank={371} value={371}>The Mehsana Urban Co-op Bank LTD</option>
                      <option data-bank={291} value={291}>The Nasik Merchants' Co-operative Bank</option>
                      <option data-bank={696} value={696}>The Varachha CO-OP. Bank ltd</option>
                      <option data-bank={223} value={223}>UCO Bank</option>
                      <option data-bank={274} value={274}>Ujjivan Small Finance Bank</option>
                      <option data-bank={217} value={217}>Union Bank of India</option>
                      <option data-bank={224} value={224}>United Bank of India</option>
                      <option data-bank={284} value={284}>United Commercial Bank</option>
                      <option data-bank={259} value={259}>Utkarsh Small Finance Bank</option>
                      <option data-bank={290} value={290}>Uttar Bihar Gramin Bank</option>
                      <option data-bank={740} value={740}>Uttarbanga Kshetriya Gramin Bank</option>
                      <option data-bank={228} value={228}>Vijaya Bank</option>
                      <option data-bank={218} value={218}>Yes Bank</option>
                      <option data-bank={463} value={463}>ZILA SAHKARI BANK</option>
                      <option data-bank={288} value={288}>bhavana bank</option>
                      <option data-bank={275} value={275}>saraswat bank</option>
                    </select>
                                    </div>
                                    <div className="member-error" />
                                    <input type="hidden" name="id" defaultValue={0} />
                                    <input type="hidden" name="isUpdate" defaultValue={1} />
                                </div>
                                <div className="input-group" name="userBankBranchDiv">
                                    <label htmlFor="userBankBranch"><ui-translate key="fe.text.bank.bankBranch" lang="en">Bank Branch</ui-translate></label>
                                    <div className="input-wrap">
                                        <input type="text" name="userBankBranch" id="userBankBranch" placeholder="bank branch" />
                                    </div>
                                    <div className="member-error" />
                                </div>
                                <div className="input-group" name="userBankNameDiv">
                                    <label htmlFor="userBankName"><ui-translate key="fe.text.wallet.deposit.bank_account_name" lang="en">Your Account Name</ui-translate></label>
                                    <div className="input-wrap">
                                        <input type="text" name="userBankName" id="userBankName" placeholder="bank account name" aria-required="true" />
                                    </div>
                                    <div className="member-error" />
                                </div>
                                <div className="input-group" name="userBankNoDiv">
                                    <label htmlFor="userBankNo"><ui-translate key="fe.text.wallet.deposit.bank_account_number" lang="en">Account Number</ui-translate></label>
                                    <div className="input-wrap">
                                        <input type="text" name="userBankNo" id="userBankNo" placeholder="bank account number" aria-required="true" />
                                    </div>
                                    <div className="member-error" />
                                </div>
                                <div className="input-group" name="userFinanceCodeDiv">
                                    <label htmlFor="userFinanceCode"><ui-translate key="fe.text.bank.financeCode" lang="en">SWIFT / IFSC</ui-translate></label>
                                    <div className="input-wrap">
                                        <input type="text" name="userFinanceCode" id="userFinanceCode" placeholder />
                                    </div>
                                    <div className="member-error" />
                                </div>
                                <div className="input-group" name="imageGroup">
                                    <label id="uploadFrontPhoto" htmlFor="documentFrontImage_0"><ui-translate key="fe.text.verifyAccount.bankStatementFrontPhoto" lang="en">Upload Bank Statement Photo (Front side)</ui-translate></label>
                                    <label id="uploadPhoto" style={{display: 'block'}}><ui-translate key="fe.text.verifyAccount.bankStatementPhoto" lang="en">Upload Bank Statement Photo</ui-translate></label>
                                    <div className="upload-wrap">
                                        <div className="upload-area"><i className="delete-btn red" />
                                            <div className="area-pic">
                                            </div>
                                            <div className="area-inner">
                                                <input className="upload-img" type="file" accept="image/*" placeholder id="documentFrontImage_0" name="documentFrontImage" />
                                                <div className="u-icon" />
                                                <div className="u-text">
                                                    <ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate>
                                                </div>
                                                <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group" name="imageGroup" id="backBankStatement">
                                    <label htmlFor="documentBackImage_0"><ui-translate key="fe.text.verifyAccount.bankStatementBackPhoto" lang="en">Upload Bank Statement Photo (Back side)</ui-translate></label>
                                    <div className="upload-wrap">
                                        <div className="upload-area"><i className="delete-btn red" />
                                            <div className="area-pic">
                                            </div>
                                            <div className="area-inner">
                                                <input className="upload-img" type="file" accept="image/*" placeholder id="documentBackImage_0" name="documentBackImage" />
                                                <div className="u-icon" />
                                                <div className="u-text">
                                                    <ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate>
                                                </div>
                                                <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="accordion-wrap show">
                    <div className="a-title toggle-btn">
                        <div className="a-icon" />
                        <div className="a-text">
                            <ui-translate key="fe.text.verifyAccount.tips" lang="en">Tips</ui-translate>
                        </div>
                        <div className="a-arrow" />
                    </div>
                    <div className="a-content">
                        <ol className="a-list">
                            <li className="a-item">
                                <ui-translate key="fe.text.verifyAccount.tips1" lang="en">Upload jpg, jpeg or png file.</ui-translate>
                            </li>
                            <li className="a-item">
                                <ui-translate key="fe.text.verifyAccount.tips2" params="[&quot;5M&quot;]" lang="en">Not over 5M.</ui-translate>
                            </li>
                            <li className="a-item">
                                <ui-translate key="fe.text.verifyAccount.tips7" params="[&quot;3&quot;]" lang="en">Upload file limit count for 3.</ui-translate>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="accordion-wrap show error" id="bankError">
                    <div className="a-title">
                        <div className="a-icon" />
                        <div className="a-text">
                            <ui-translate key="fe.text.verifyAccount.reviewFailed" lang="en">Review Failed</ui-translate>
                        </div>
                        <div className="a-arrow" />
                    </div>
                    <div className="a-content">
                        <div className="a-list">
                        </div>
                    </div>
                </div>
            </div>
            <div className="button" id="bank-verification-btn">
                <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

import React from 'react'

export const VerifyAccount = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
  <main className="container-player-wrapper">
    <div className="container-player-group player-verify">
      <div className="player-title-box">
        <h2><ui-translate key="fe.text.profile.verify_account" lang="en">Verify Account</ui-translate></h2>
        <div className="tab-primary-box">
          <ul>
            <li><a data-toggle="tab" data-pagetype={29}><ui-translate key="fe.text.verifyAccount.documents" lang="en">Documents</ui-translate></a></li>
            <li><a data-toggle="tab" data-pagetype={32}><ui-translate key="fe.text.documentType.bankStatement" lang="en">Bank Statement</ui-translate></a></li>
          </ul>
          <div className="slider-tab" style={{width: '160px', left: '0px'}} />
        </div>
      </div>
      <div className="main-tab-content">
        <section className="tab-pane active">
          <div className="id-photo-group">
            <form id="verify_account" noValidate="novalidate">
              <div className="input-box">
                <div className="input-box-50">
                  <label htmlFor="documentType"><ui-translate key="fe.text.verifyAccount.document" lang="en">Document</ui-translate></label>
                  <select name="documentType" id="documentType">
                    <option value={-1}>Choose document type</option>
                    <option value={1}>Identity Card</option><option value={4}>Passport</option><option value={8}>Driver's License</option><option value={32}>Pan Card</option>
                  </select>
                  <div className="error" name="error" style={{display: 'none'}} />
                </div>
                <div className="input-box-50">
                  <label htmlFor="documentNo"><ui-translate key="fe.text.verifyAccount.primaryId" lang="en">Document No</ui-translate>*</label>
                  <input type="text" name="documentNo" id="documentNo" placeholder="Type ID No here" aria-required="true" />
                  <div className="error" name="error" style={{display: 'none'}} />
                </div>
              </div>
              <div className="input-box">
                <div className="input-box-50">
                  <label htmlFor="expiryDate"><ui-translate key="fe.text.verifyAccount.expiryDate" lang="en">Expiry Date</ui-translate></label>
                  <input type="date" name="expiryDate" id="expiryDate" />
                  <div className="error" name="error" style={{display: 'none'}} />
                </div>
              </div>
              <div className="input-box">
                <div className="input-box-50">
                  <div className="deposit-receipt-upload">
                    <label><ui-translate key="fe.text.verifyAccount.primaryIdFrontPhoto" lang="en">Upload Document Photo (Front side)</ui-translate>*</label>
                    <div className="upload-box">
                      <i className="icon-close" />
                      <input type="file" className="upload-img" accept="image/*" id="documentFrontImage" name="documentFrontImage" />
                      <label className="btn-5th-xs" htmlFor="documentFrontImage"><ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate></label>
                      <img name="previewImage" src className="thumb-image" style={{display: 'none'}} />
                      <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                    </div>
                    <div className="tips-group">
                      <ol>
                        <li><ui-translate key="fe.text.verifyAccount.tips1" lang="en">Upload jpg, jpeg or png file.</ui-translate></li>
                        <li><ui-translate key="fe.text.verifyAccount.tips2" params="[&quot;5M&quot;]" lang="en">Not over 5M.</ui-translate></li>
                      </ol>
                    </div>
                    <div className="error" name="error" style={{display: 'none'}} />
                  </div>
                </div>
                <div className="input-box-50">
                  <div className="deposit-receipt-upload">
                    <label><ui-translate key="fe.text.verifyAccount.primaryIdBackPhoto" lang="en">Upload Document Photo (Back side)</ui-translate></label>
                    <div className="upload-box">
                      <i className="icon-close" />
                      <input type="file" className="upload-img" accept="image/*" id="documentBackImage" name="documentBackImage" />
                      <label className="btn-5th-xs" htmlFor="documentBackImage"><ui-translate key="fe.text.verifyAccount.choosePhoto" lang="en">choose a photo</ui-translate></label>
                      <img name="previewImage" src className="thumb-image" style={{display: 'none'}} />
                      <input type="hidden" name="imageIsEmpty" defaultValue={1} />
                    </div>
                    <div className="tips-group">
                      <ol>
                        <li><ui-translate key="fe.text.verifyAccount.tips1" lang="en">Upload jpg, jpeg or png file.</ui-translate></li>
                        <li><ui-translate key="fe.text.verifyAccount.tips2" params="[&quot;5M&quot;]" lang="en">Not over 5M.</ui-translate></li>
                      </ol>
                    </div>
                    <div className="error" name="error" style={{display: 'none'}} />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <button className="btn-default" id="kyc-verification-btn"><ui-translate key="fe.text.submit" lang="en">Submit</ui-translate></button>
        </section>
      </div>
    </div>
  </main>
</div>

        </React.Fragment>
    )
}

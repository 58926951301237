import React from 'react'

export const Emailpopup = (props) => {
    return (
        <React.Fragment>
            <div id="pop-bg" class="pop-bg" style={{display: 'block'}}></div>
            <div id="verification-pop-email" className="pop-wrap form-pop safe-verification active Ani" data-verifying="false">
  <div className="pop-title">
    <h3>
      <ui-translate key="fe.text.profile.verify" lang="en">Verify</ui-translate>
      <ui-translate key="fe.text.account.email" lang="en">Email</ui-translate>
    </h3>
    <a className="btn-close" onClick={()=>(props.handlerightsideclose(false))} />
  </div>
  <div className="pop-inner">
    <ul className="input-group">
      <li>{/* class=wrong ? */}
        <label htmlFor="email_address">
          <ui-translate key="fe.text.account.email" lang="en">Email</ui-translate>
        </label>
      
        <input type="text" name="email_address" id="email_address" readOnly />
      </li>
      <li className="verification-input">
        <label htmlFor="email_verify_code">
          <ui-translate key="fe.text.plz_input_verification_code" lang="en">Please input verification code</ui-translate>
        </label>
        <div className="vcode-input">
        
          <input type="text" name="verifyCode" id="email_verify_code" />
      
          <span className="verify_timer vcode-sec">0s</span>
          <span className="resend-set resendVerifyCode" data-contacttype={1}>Send Validation Code</span>
          <div className="btn-vcode btn-2nd sendVerifyCode" data-contacttype={1}>
            <div className="sms-svg-group">
              <div className="svg-phone" />
              <div className="svg-mail-group">
                <ul className="line">
                  <li style={{transform: 'translate3d(5px, 0px, 0px)'}} />
                  <li style={{transform: 'translate(5px, 0px)'}} />
                  <li style={{transform: 'translate(4.8973px, 0px)'}} />
                </ul>
                <div className="svg-mail" />
              </div>
            </div>
            <p>
              <ui-translate key="fe.text.profile.send_verify" lang="en">Send Validation Code</ui-translate>
            </p>
          </div>
        </div>
        <span className="error" style={{display: 'none'}} />
      </li>
    </ul>
    <div className="pop-btn-box">
      <a className="btn-default-xs submit" data-contacttype={1}>
        <ui-translate key="fe.text.confirm" lang="en">Confirm</ui-translate>&nbsp;
        <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
      </a>
    </div>
  </div>
</div>

        </React.Fragment>
    )
}

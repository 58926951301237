import React from 'react'
import { Slotbanner } from './Slotbanner'
import SlotCarousel from "./SlotCarousel"
import { SlotNav } from './SlotNav'
import { Subnav } from './Subnav'

export const Skyslot = () => {
    return (
        <React.Fragment>
        <div id="pageContentLoader" data-aniautoclose="true" data-aniautocloseseconds={1500} className="loaderBox" style={{display: 'block'}}>
    <div className="loader">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.8 40.1" className="sky-border">
      <path className="pen line-fei-1" d="M3.4 23.4l5.3-2.3c0 .8.4 1.4 1 1.9s1.5.7 2.6.7c1 0 1.9-.2 2.5-.5.6-.4 1-.8 1.1-1.5.1-1-.8-1.8-2.8-2.5-.6-.2-1.1-.4-1.5-.5-2.4-.9-4.1-1.8-5-2.7-.9-.9-1.2-2-1-3.4.3-2 1.4-3.6 3.3-4.8C10.8 6.6 13.1 6 15.8 6c2 0 3.6.4 5 1.1 1.3.7 2.2 1.8 2.7 3.1l-5 2.2c-.1-.8-.5-1.3-1.1-1.7-.6-.4-1.3-.6-2.3-.6-.9 0-1.7.2-2.4.5s-1 .8-1.1 1.4c-.2 1 1.2 2 3.9 2.8.3.1.6.2.8.2 2.3.7 3.9 1.6 4.7 2.6.9 1 1.2 2.2 1 3.7-.3 2-1.4 3.6-3.2 4.8-1.9 1.2-4.2 1.8-7.1 1.8-2.3 0-4.1-.4-5.6-1.2-1.5-.8-2.4-1.9-2.7-3.3z" />
      <path className="pen line-fei-2" d="M58 11l-.5.8-1.1 1.9-3.8 6.4-3-11.5H39.3l-9.5 9 2.1-13.8h-6.4l-3.7 24h6.4l1.4-9.6 6.3 9.6h7.9l-7-10.2 6.5-5.8 5 15.5-1.6 2.6-3.2 5.3h7.1l8.6-14.6 2.8-4.8" />
      <path className="pen pen2 line-fei-3" d="M62.1 16s.4-.5.6-.7c.1-.1.1-.2.2-.2l.4-.4c.1-.1.2-.2.3-.2.1-.1.3-.2.4-.3.1-.1.2-.1.3-.2.1-.1.3-.2.4-.3s.2-.1.3-.2c.1-.1.3-.2.4-.2.1-.1.2-.1.3-.1.2-.1.3-.1.5-.2.1 0 .2-.1.4-.1s.4-.1.6-.1c.1 0 .2 0 .3-.1.3 0 .7-.1 1.1-.1 1.7 0 3.3 1.2 3.3 2.8 0 1.9-.1 2.2-12 10.6l4.2 6.9h16V26h-8.3c6.2-4.1 8.4-7.6 8.4-11.1 0-5.2-5.5-9-11.2-9-.7 0-1.3 0-1.9.1-.2 0-.4 0-.6.1-.4 0-.8.1-1.1.1-.2 0-.4.1-.6.1-.3.1-.6.1-.9.2-.2.1-.4.1-.6.2-.3.1-.5.2-.8.3l-.6.3c-.2.1-.4.2-.7.4-.2.1-.4.2-.5.4-.2.1-.4.3-.6.5-.2.1-.3.3-.5.4-.2.2-.3.4-.5.5-.1.2-.3.3-.4.5l-.2.2c-.2.2-.4.5-.5.7" />
      <path className="pen pen2 line-fei-4" d="M98.6 32.9V21H88L98.1 3.9h-8.2L79.7 21l4.2 6.9h7.8v5z" />
      <path className="pen pen2 line-fei-5" d="M118.4 16.4l-4.2-6.8H99.5v6.8h10.6L98.4 36.3h8.2l11.8-19.9z" />
    </svg>
        <div className="seLogo" />
        <div className="skyloader-dotwrap">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
        </div>
    </div>
</div>
            <div id="pageContentBody">
            <div class="content no-sidenav week-col-" style={{paddingTop: '145px'}}>
          
           

            <SlotCarousel/>
            {/* slotgame nosidenav */}
            <SlotNav/>
            {/* Subnev */}
            <Subnav/>
            <Slotbanner/>

          
        
            </div>
            </div>
        </React.Fragment>
    )
}

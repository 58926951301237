import React from 'react'

export const Tennisfullmarket = () => {
    return (
        <React.Fragment>
            <div>
    <div id="liveMatchGameHead" className="game-head">
        <div id="matchTrackerWrap" className="match-tracker-wrap">
            <div className="widgets">
                <div /></div>
            <ul id="liveMatchTrackerBtn" className="match-btn">
                <li>
                <a id="liveMultiMarketPin" name="gameHeadPin" className="btn-pin" title="Add to Multi Markets" eventtype={4} eventid={30035305} marketid="1.173506114" style={{cursor: 'pointer'}} /></li>
                <li>
                <a className="btn-refresh" style={{cursor: 'pointer'}} /></li>
            </ul>
        </div>
        <div id="scoresEvent" className="match-odds">Match Odds<img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></div>
        
          <ul className="game-info" style={{display: 'block'}}>
            <li id="gameInfoDate" className="green">
            <img className="icon-irun" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /> In-Play</li>
        </ul>
        <dl id="minMaxBox" className="fancy-info">
        <dt id="minMaxDt" style={{display: 'none'}}>Min/Max</dt>
        <dt id="maxDt">Min</dt>
            <dd id="minMaxInfo">1</dd>
            <dt id="maxDt">Max</dt>
            <dd id="minMaxInfo">100</dd>
        </dl>
    </div>
    <div id="fullMarketBetsWrap" className="bets-wrap" eventtype={4} eventid={30035305} marketid="1.173506114">
        <h3 id="lineMarketName" className="line_market-name" style={{display: 'none'}}>
        <span>1st Innings 10 Over Line</span>
        </h3>
        <span id="selectionCounts" className="refer_only">2 Selections</span>
        <table id="fullMarketBoard" className="bets ">
            <tbody>
                <tr className="bet-all">
                    <td />
                    <td id="backPercent" className="refer-bet" colSpan={2}>101.7%</td>
                    <td><a id="backAll" className="back-all">
                    <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                    <span>Back all</span></a></td>
                    <td><a id="layAll" className="lay-all">
                    <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                    <span>Lay all</span>
                    </a>
                    </td>
                    <td id="layPercent" className="refer-book" colSpan={2}>97.9%</td>
                </tr>
                <tr id="fullSelection_4164048" className="null">
                    <th className="predict">
                        <dl id="horseInfo" className="horse-info" style={{display: 'none'}}>
                        <dt>
                        <a id="marketDepthBtn" href="#">
                        <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>
                        <ul id="horseBox" className="horse-box">
                        <li id="clothNumberAlpha" />
                        <li id="stallDraw" /></ul>
                        <div id="uniform" className="uniform">
                        <img src />
                        </div>
                        </dt>
                            <dd>
                                <ul id="horseName" className="horse-name">
                                    <li id="runnerName" />
                                    <li id="jockyName" />
                                </ul>
                                <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                    <dd id="trainerName" /><dt>Age/Weight:</dt>
                                    <dd id="ageWeight" /><dt>Form:</dt>
                                    <dd id="form" /><dt>Days since last run:</dt>
                                    <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                    <dd id="jockeyClam" /><dt>Wearning:</dt>
                                    <dd id="wearing" />
                                </dl>
                            </dd>
                        </dl>
                        <p>
                        <a><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>Hemery</p>
                        <span id="lossWithoutBet" className="win" style={{display: 'none'}} />
                        <span id="withBet"
                            className="win" style={{display: 'none'}} /><span id="lossWithBet" className="win" style={{display: 'none'}} /><span id="zeroProfit" className="win" style={{display: 'none'}} /><span id="zeroLiability" className="win" style={{display:
                            'none'}} /><span id="zeroProfitWithBet" className="win" style={{display: 'none'}} /><span id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} /></th>
                    <td id="suspend" colSpan={6} className="suspend" style={{display:
                        'none'}}><span>Suspend</span></td>
                    <td id="closed" colSpan={6} className="suspend" style={{display: 'none'}}><span>Closed</span></td>
                    <td id="nonRunner" colSpan={6} className="suspend" style={{display: 'none'}}><span>Non Runner</span></td>
                    <td id="back_3" className="back-3 null" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.88" inplay={0}>
                    <a>10<span>321</span></a></td>
                    <td id="back_2" className="back-2 null " side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.89" inplay={0}>
                    <a>14<span>262</span></a></td>
                    <td id="back_1" className="back-1 null null" side="Back" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.9" inplay={0}>
                    <a>15<span>215</span></a></td>
                    <td id="lay_1" className="lay-1 null null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.97" inplay={0}>
                    <a>21<span>349</span></a></td>
                    <td id="lay_2" className="lay-2 null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.98" inplay={0}>
                    <a>26<span>635</span></a></td>
                    <td id="lay_3" className="lay-3 null" side="Lay" selectionid={4164048} runner="Royal Challengers Bangalore" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}>
                    <a>34<span>1</span></a></td>
                </tr>
                <tr id="fullSelection_2954266" eventtype={4} eventid={30035305} marketid="1_173506114" selectionid={2954266} eventname="Royal Challengers Bangalore v Rajasthan Royals" status={1} sortpriority={2} className="null">
                    <th className="predict">
                        <dl id="horseInfo" className="horse-info" style={{display: 'none'}}>
                        <dt>
                        <a id="marketDepthBtn" href="#">
                        <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>
                        <ul id="horseBox" className="horse-box">
                        <li id="clothNumberAlpha" />
                        <li id="stallDraw" /></ul>
                        <div id="uniform" className="uniform">
                        <img src />
                        </div>
                        </dt>
                            <dd>
                                <ul id="horseName" className="horse-name">
                                    <li id="runnerName" />
                                    <li id="jockyName" />
                                </ul>
                                <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                    <dd id="trainerName" /><dt>Age/Weight:</dt>
                                    <dd id="ageWeight" /><dt>Form:</dt>
                                    <dd id="form" /><dt>Days since last run:</dt>
                                    <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                    <dd id="jockeyClam" /><dt>Wearning:</dt>
                                    <dd id="wearing" />
                                </dl>
                            </dd>
                        </dl>
                        <p>
                        
                        <a>
                        <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                        </a>
                        Ejupovic</p>
                        <span id="withBet" className="win" style={{display: 'none'}} />
                        <span id="lossWithBet" className="win" style={{display: 'none'}} />
                            <span id="zeroProfit" className="win" style={{display: 'none'}} />
                            <span id="zeroLiability" className="win" style={{display: 'none'}} />
                            <span id="zeroProfitWithBet" className="win" style={{display:'none'}} />
                            <span id="zeroLiabilityWithBet" className="win" style={{display: 'none'}} /></th>
                    <td id="suspend" colSpan={6} className="suspend" style={{display: 'none'}}>
                    <span>Suspend</span>
                    </td>
                    <td id="closed" colSpan={6} className="suspend" style={{display: 'none'}}>
                    <span>Closed</span>
                    </td>
                    <td id="nonRunner" colSpan={6} className="suspend" style={{display: 'none'}}>
                    <span>Non Runner</span></td>
                    <td id="back_3" className="back-3  null" >
                    <a>1.03<span>41</span></a></td>
                    <td id="back_2" className="back-2 null" >
                    <a>1.04<span>15</span></a></td>
                    <td id="back_1" className="back-1 null null" >
                    <a>1.05<span>6</span></a></td>
                    <td id="lay_1" className="lay-1 null null">
                    <a>1.07<span>3</span></a></td>
                    <td id="lay_2" className="lay-2 null" >
                    <a>1.08<span>3</span></a></td>
                    <td id="lay_3" className="lay-3 null" >
                    <a>1.09<span>19</span>
                    </a></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div id="bookMakerWrap" className="bets-wrap  bookmaker_bet" style={{}}>
    <table id="bookMakerMarketList" className="bets bets-bookmaker" style={{}}>
        <colgroup>
            <col span={1} width={280} />
            <col span={1} width={70} />
            <col span={1} width={70} />
            <col span={1} width={70} />
            <col span={1} width={70} />
            <col span={1} width={70} />
            <col span={1} width={70} />
        </colgroup>
        <tbody id="bookMakerMarket_31387211_149917" style={{display: 'table-row-group'}}>
            <tr className="colgroup-adjust">
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
            </tr>
            <tr id="bookMakerSpecialBet" className="special_bet">
                <td colSpan={7}>
                    <h3>
                        <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a> Bookmaker Market
                        <p>| Zero Commission</p>
                    </h3>
                    <dl className="fancy-info">
                        <dt><span>Min</span></dt>
                        <dd id="min">100.00</dd>
                        <dt><span>Max</span></dt>
                        <dd id="max">122,500.00</dd>
                        <dt id="rebateName" style={{display: 'none'}}><span>Rebate</span></dt>
                        <dd id="rebate" style={{display: 'none'}} />
                    </dl>
                </td>
            </tr>
            <tr className="bet-all">
                <td colSpan={3} /> 
                <td>Back</td>
                <td>Lay</td>
                <td className="refer-book" colSpan={2} />
            </tr>
            <tr id="bookMakerSuspend_31387211_149917_441666" className="fancy-suspend-tr" style={{}} marketid={149917}>
                <th colSpan />
                <td className="fancy-suspend-td" colSpan={6}>
                    <div id="suspendClass" className="fancy-suspend"><span id="info">Ball Running</span></div>
                </td>
            </tr>
            <tr id="bookMakerSelection_31387211_149917_441666" style={{display: 'table-row'}}>
                <th className="predict">
                    <p id="runnerName">Varvara Gracheva</p>
                    <span id="before" className="win" style={{display: 'none'}} />
                    <span id="after" className="to-lose" style={{display: 'none'}} />
                </th>
                <td className colSpan={3}>
                    <dl className="back-gradient">
                        <dd id="back_3" style={{cursor: 'pointer'}}>
                            <p>53</p>
                        </dd>
                        <dd id="back_2" style={{cursor: 'pointer'}}>
                            <p>54</p>
                        </dd>
                        <dd id="back_1" style={{cursor: 'pointer'}} className><a>55</a></dd>
                    </dl>
                </td>
                <td className colSpan={3}>
                    <dl className="lay-gradient">
                        <dd id="lay_1" style={{cursor: 'pointer'}} className><a>58</a></dd>
                        <dd id="lay_2" style={{cursor: 'pointer'}}>
                            <p>59</p>
                        </dd>
                        <dd id="lay_3" style={{cursor: 'pointer'}}>
                            <p>60</p>
                        </dd>
                    </dl>
                </td>
            </tr>
            <tr id="bookMakerSuspend_31387211_149917_441667" className="fancy-suspend-tr" style={{}} marketid={149917}>
                <th colSpan />
                <td className="fancy-suspend-td" colSpan={6}>
                    <div id="suspendClass" className="fancy-suspend"><span id="info">Suspend</span></div>
                </td>
            </tr>
            <tr id="bookMakerSelection_31387211_149917_441667" style={{display: 'table-row'}}>
                <th className="predict">
                    <p id="runnerName">Marie Bouzkova</p>
                    <span id="before" className="win" style={{display: 'none'}} />
                    <span id="after" className="to-lose" style={{display: 'none'}} />
                </th>
                <td className colSpan={3}>
                    <dl className="back-gradient">
                        <dd id="back_3" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="back_2" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="back_1" style={{cursor: 'pointer'}} className><a /></dd>
                    </dl>
                </td>
                <td className colSpan={3}>
                    <dl className="lay-gradient">
                        <dd id="lay_1" style={{cursor: 'pointer'}} className><a /></dd>
                        <dd id="lay_2" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="lay_3" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                    </dl>
                </td>
            </tr>
        </tbody>
    </table>
    <table id="bookMakerTempTable" style={{display: 'none'}}>
        <tbody id="bookMakerMarketTemplate">
            <tr className="colgroup-adjust">
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
            </tr>
            <tr id="bookMakerSpecialBet" className="special_bet">
                <td colSpan={7}>
                    <h3>
                        <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a> Bookmaker Market
                        <p>| Zero Commission</p>
                    </h3>
                    <dl className="fancy-info">
                        <dt><span>Min</span></dt>
                        <dd id="min">100.00</dd>
                        <dt><span>Max</span></dt>
                        <dd id="max">10000.00</dd>
                        <dt id="rebateName"><span>Rebate</span></dt>
                        <dd id="rebate" />
                    </dl>
                </td>
            </tr>
            <tr className="bet-all">
                <td colSpan={3} /> {/*
                <td class="refer-bet"></td> */}
                <td>Back</td>
                <td>Lay</td>
                <td className="refer-book" colSpan={2} />
            </tr>
        </tbody>
        <tbody>
            <tr id="bookMakerSelectionTemplate" style={{display: 'none'}}>
                <th className="predict">
                    <p id="runnerName" />
                    <span id="before" className="win" style={{display: 'none'}} />
                    <span id="after" className="to-lose" style={{display: 'none'}} />
                </th>
                <td className colSpan={3}>
                    <dl className="back-gradient">
                        <dd id="back_3" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="back_2" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="back_1" style={{cursor: 'pointer'}}><a /></dd>
                    </dl>
                </td>
                <td className colSpan={3}>
                    <dl className="lay-gradient">
                        <dd id="lay_1" style={{cursor: 'pointer'}}><a /></dd>
                        <dd id="lay_2" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                        <dd id="lay_3" style={{cursor: 'pointer'}}>
                            <p />
                        </dd>
                    </dl>
                </td>
            </tr>
            <tr id="suspendTemplate" className="fancy-suspend-tr" style={{display: 'none'}}>
                <th colSpan />
                <td className="fancy-suspend-td" colSpan={6}>
                    <div id="suspendClass" className="fancy-suspend"><span id="info">Suspend</span></div>
                </td>
            </tr>
            <tr id="delayTemplate" className="fancy-suspend-tr" style={{display: 'none'}}>
                <th />
                <td colSpan={2} />
                <td className="fancy-suspend-td" colSpan={2}>
                    <div className="fancy-suspend"><span id="info">Delay Betting</span></div>
                </td>
                <td colSpan={2} />
            </tr>
            <tr id="bookMakerBetTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                <td colSpan={7}>
                    <dl id="classWrap" className="quick_bet-wrap slip-back">
                        <dt id="bookMakerBetHeader" className>
              <span id="bookMakerBetAcceptCheck" className="bet-check">
                <input id="bookMakerBetAcceptAnyOdds" type="checkbox" />
                <label htmlFor="bookMakerBetAcceptAnyOdds">Accept Any Odds</label>
              </span>
            </dt>
                        <dd className="col-btn"><a id="cancel" className="btn" style={{cursor: 'pointer'}}>Cancel
              </a></dd>
                        <dd id="oddsHeader" className="col-odd">
                            <ul className="quick-bet-confirm">
                                <li id="odds" />
                            </ul>
                        </dd>
                        <dd className="col-stake">
                            <input id="inputStake" type="text" defaultValue />
                        </dd>
                        <dd className="col-send">
                            <a id="placeBet" className="btn-send disable" style={{cursor: 'pointer'}}>Place Bets
              </a>
                        </dd>
                    </dl>
                </td>
            </tr>
            <tr id="bookMakerBetBarTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                <td colSpan={7}>
                    <dl className="quick_bet-wrap quick_bet-progress">
                        <dd id="progressBar" className="progress-bar" style={{width: '0%'}} />
                        <dd className="progress-bar-txt">Placing your bets, Please wait <span id="secRemain">0 sec remaining…</span>
                        </dd>
                    </dl>
                </td>
            </tr>
            <tr id="bookMakerBetMessageTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                <td colSpan={7}>
                    <dl id="classWrap" className="quick_bet-wrap quick_bet-message success">
                        <dd id="info">
                            <strong id="header">Bet Matched</strong> USD$ at odds Profit:
                            <a id="close" className="btn-close" style={{cursor: 'pointer'}}>Close</a>
                        </dd>
                    </dl>
                </td>
            </tr>
        </tbody>
    </table>
    <div id="sportsBookWrap" className="bets-wrap sportsbook_bet" style={{}}>
        {/* Sportsbook bet head start */}
        <div id="sportsBookHead" className="sportsbook-head">
            <h4 className="in-play">
                <span id="sportsBookName">Premium Tennis</span>
                <a id="sportsBookRules" className="btn-sportsbook_rules">Rules</a>
            </h4>
            <a id="showFancyBetBtn" className="other-tab" style={{display: 'none'}}>Fancy Bet</a>
            <div id="sportsBookRulesWrap_2" className="rules-wrap">
                <h3 id="sportsbookRulesTitle" />
                <div id="sportradarCricketRules" className="rules-content">
                    <dl className="download-list">
                        <dt className="icon-pdf"><a href="javascript: void(window.open('https://drive.google.com/open?id=1rkKwnG8Sv09iANXNYriOXOm0e0wjoQqf', '_blank', config = 'location=no'));">Pre Match Market Details</a></dt>
                        <dd><a href="javascript: void(window.open('https://drive.google.com/open?id=1rkKwnG8Sv09iANXNYriOXOm0e0wjoQqf', '_blank', config = 'location=no'));">download</a></dd>
                    </dl>
                    <dl className="download-list">
                        <dt className="icon-pdf"><a href="javascript: void(window.open('https://drive.google.com/open?id=13rBX3LjmSfNNKPbCyCxkqnvHZPdmIgLf', '_blank', config = 'location=no'));">In Play Market Details</a></dt>
                        <dd><a href="javascript: void(window.open('https://drive.google.com/open?id=13rBX3LjmSfNNKPbCyCxkqnvHZPdmIgLf', '_blank', config = 'location=no'));">download</a></dd>
                    </dl>
                </div>
                <div id="sportradarTennisRules" className="rules-content">
                    <p>General Rules:</p>
                    <ol>
                        <li>In case of default, retirement or walkover of any player, all undecided bets are considered void.</li>
                        <li>In case of any delay (rain, darkness…) all markets remain unsettled and the trading will be continued as soon as the match continues.</li>
                        <li>If penalty point(s) are awarded by the umpire, all bets on that game will stand.</li>
                        <li>In case of a match is finished before certain points/games were finished, all affected point/game related markets are considered void.</li>
                    </ol>
                    <p>Settlement and Cancellation Rules:</p>
                    <ol>
                        <li>If markets remain open with an incorrect score which has a significant impact on the prices, we reserve the right to void betting.</li>
                        <li>If the players/teams are displayed incorrectly, we reserve the right to void betting.</li>
                        <li>If a player retires all undecided markets are considered void.</li>
                        <li>If a match tie-break is played as a deciding set in Bo3 format, it will be considered as the 3rd set</li>
                        <li>Every tie-break or Match tie-break counts as 1 game</li>
                    </ol>
                </div>
                <div id="sportradarSoccerRules" className="rules-content">
                    <dl className="download-list">
                        <dt className="icon-pdf"><a href="javascript: void(window.open('https://drive.google.com/open?id=1ixfrIZd5ApPrs6Qe9lDny0ONb6SroRNi', '_blank', config = 'location=no'));">Market Details</a></dt>
                        <dd><a href="javascript: void(window.open('https://drive.google.com/open?id=1ixfrIZd5ApPrs6Qe9lDny0ONb6SroRNi', '_blank', config = 'location=no'));">download</a></dd>
                    </dl>
                </div>
                <div id="sportradarKabaddiRules" className="rules-content">
                </div>
                <ul className="btn-wrap break">
                    <li><a onclick="$j('#sportsBookRulesWrap_2').fadeOut()" className="btn" href="#">OK</a></li>
                </ul>
            </div>
            <dl id="minMaxWrap_1" className="fancy-info" style={{display: 'none'}}>
                <dt><span>Min/Max</span></dt>
                <dd id="minMaxInfo_1">0</dd>
                <dt id="rebateName_1">Rebate</dt>
                <dd id="rebateRatio_1">0.00%</dd>
            </dl>
            <dl id="minMaxWrap_2" className="fancy-info" style={{}}>
                <dt><span>Min</span></dt>
                <dd id="minMaxInfo_2">100.00</dd>
                <dt id="rebateName_2" style={{display: 'none'}}>Rebate</dt>
                <dd id="rebateRatio_2" style={{display: 'none'}}>0.00%</dd>
            </dl>
        </div>
        <div id="sportsBookTab_2_4" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a  id="tab_All" style={{color: "rgba(163,64,5,0.85)"}}>All</a></li>
                <li><a id="tab_Popular" style={{color: "rgba(163,64,5,0.85)"}}>Popular</a></li>
                <li><a id="tab_Match" style={{color: "rgba(163,64,5,0.85)"}}>Match</a></li>
                <li><a id="tab_Innings" style={{color: "rgba(163,64,5,0.85)"}}>Innings</a></li>
                <li><a id="tab_Over" style={{color: "rgba(163,64,5,0.85)"}}>Over</a></li>
                <li className="tab_more_drop">
                    <a id="moreDropBtn" style={{color: "rgba(163,64,5,0.85)"}}>More</a>
                    <ul id="moreDropList" className="more_drop-list">
                        <li><a id="tab_Batsman" style={{color: "rgba(163,64,5,0.85)"}}>Batsman</a></li>
                        <li>
                        <a id="tab_Partnership">Partnership</a></li>
                        <li>
                        <a id="tab_Group">Group</a></li>
                        <li><a id="tab_Range">Range</a></li>
                        <li><a id="tab_HeadtoHead">Head-to-Head</a></li>
                        <li><a id="tab_Player">Player</a></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div id="sportsBookTab_2_2" className="special_bets-tab-wrap" style={{}}>
            <ul className="special_bets-tab">
                <li>
                <a id="tab_All" style={{color: "rgba(163,64,5,0.85)"}}>All</a></li>
                <li><a id="tab_Main"  style={{color: "rgba(163,64,5,0.85)"}}>Main</a></li>
                <li><a id="tab_Total"  style={{color: "rgba(163,64,5,0.85)"}}>Total</a></li>
                <li><a id="tab_Others"  style={{color: "rgba(163,64,5,0.85)"}}>Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Popular">Popular</a></li>
                <li><a id="tab_Half">Half</a></li>
                <li><a id="tab_Goals">Goals</a></li>
                <li><a id="tab_FastMarkets">Fast Markets</a></li>
                <li><a id="tab_Specials">Specials</a></li>
                <li><a id="tab_Corners">Corners</a></li>
                <li><a id="tab_Cards">Cards</a></li>
                <li><a id="tab_Players">Players</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_137" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Popular">Popular</a></li>
                <li><a id="tab_Half">Half</a></li>
                <li><a id="tab_Goals">Goals</a></li>
                <li><a id="tab_FastMarkets">Fast Markets</a></li>
                <li><a id="tab_Specials">Specials</a></li>
                <li><a id="tab_Corners">Corners</a></li>
                <li><a id="tab_Cards">Cards</a></li>
                <li><a id="tab_Players">Players</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_2_138" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab">
                <li><a id="tab_All">All</a></li>
                <li><a id="tab_Main">Main</a></li>
                <li><a id="tab_Half">Half</a></li>
                <li><a id="tab_Others">Others</a></li>
            </ul>
        </div>
        <div id="sportsBookTab_1" className="special_bets-tab-wrap" style={{display: 'none'}}>
            <ul className="special_bets-tab" />
        </div>
        {/* Sportsbook bet head end */}
        <table id="sportsBookMarket_2_2054179_137058177" className="bets" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Winner</a>
                        </h3>
                    </td>
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137058177_1836157937" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137058177_1836157937" style={{display: 'table-row'}} marketpk="2_2054179_137058177" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">Gracheva, Varvara</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">1.55</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137058177_1836157938" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137058177_1836157938" style={{display: 'table-row'}} marketpk="2_2054179_137058177" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">Bouzkova, Marie</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">2.35</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058174" className="bets" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set - winner</a>
                        </h3>
                    </td>
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137058174_1836157929" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137058174_1836157929" style={{display: 'table-row'}} marketpk="2_2054179_137058174" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">Gracheva, Varvara</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">2.6</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137058174_1836157930" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137058174_1836157930" style={{display: 'table-row'}} marketpk="2_2054179_137058174" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">Bouzkova, Marie</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">1.45</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137537816" className="bets" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total sets</a>
                        </h3>
                    </td>
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137537816_1836453019" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137537816_1836453019" style={{display: 'table-row'}} marketpk="2_2054179_137537816" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">over 2.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">1.45</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137537816_1836453020" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137537816_1836453020" style={{display: 'table-row'}} marketpk="2_2054179_137537816" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">under 2.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">2.6</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137911657" className="bets" style={{display: 'none'}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total games</a>
                        </h3>
                    </td>
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137911657_1836644969" className="fancy-suspend-tr" style={{}} eventid={2054179} isactive={1} status={3}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Closed</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137911657_1836644969" style={{display: 'table-row'}} marketpk="2_2054179_137911657" isactive={1} status={3}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">over 25.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">1.6</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137911657_1836644968" className="fancy-suspend-tr" style={{}} eventid={2054179} isactive={1} status={3}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Closed</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137911657_1836644968" style={{display: 'table-row'}} marketpk="2_2054179_137911657" isactive={1} status={3}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">under 25.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">2.25</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137922040" className="bets" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total games</a>
                        </h3>
                    </td>
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137922040_1836651181" className="fancy-suspend-tr" style={{display: 'none'}}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white">
                        <span>Suspend</span>
                        </div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137922040_1836651181" style={{display: 'table-row'}}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">over 26.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">1.6</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                <tr id="sportsBookSuspend_2_2054179_137922040_1836651180" className="fancy-suspend-tr" style={{display: 'none'}} eventid={2054179} isactive={1} status={1}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                <tr id="sportsBookSelection_2_2054179_137922040_1836651180" style={{display: 'table-row'}} marketpk="2_2054179_137922040" isactive={1} status={1}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName">under 26.5</p>
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info">2.25</a>
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137922130" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137924217" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set game 10 - winner</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137922137" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set game 9 - winner</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137924211" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set game 10 - correct score</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058180" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Set handicap</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137901560" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Game handicap</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137901565" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Game handicap</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137909752" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set - game handicap</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137924593" className="bets close" style={{display: 'none'}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set game 9 - 4th point</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137909753" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set - total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137909758" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set - correct score</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058175" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Odd/even games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137537828" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Will there be a tiebreak</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137909749" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set - will there be a tiebreak</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137901564" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Gracheva, Varvara total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058157" className="bets close" style={{display: 'none'}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Bouzkova, Marie total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058171" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Bouzkova, Marie to win a set</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137924762" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137924761" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">2nd set game 10 - 1st point</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookMarket_2_2054179_137058156" className="bets close" style={{}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Bouzkova, Marie total games</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <p id="sportsBookNoBet" className="no_bet" style={{display: 'none'}}>has no Sports Book market </p>
        <table id="sportsBookMarketTemplate" className="bets" style={{display: 'none'}}>
            <colgroup>
                <col span={1} width={280} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
                <col span={1} width={70} />
            </colgroup>
            <tbody>
                <tr className="colgroup-adjust">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
                <tr className="special_bet">
                    <td colSpan={7}>
                        <h3 id="marketHeader">
                            <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>
                            <a id="marketName" className="to-expand">Total sixes</a>
                        </h3>
                    </td>
                </tr>
            </tbody>
        </table>
        <table id="sportsBookTemplateTable" className="bets" style={{display: 'none'}}>
            <tbody>
                <tr id="sportsBookSelectionTemplate" style={{display: 'none'}}>
                    <th className colSpan={3}>
                        <dl className="fancy-th-layout">
                            <dt>
                <p id="selectionName" />
                <span id="before" className="win" style={{display: 'none'}} />
                <span id="after" className="to-lose" style={{display: 'none'}} />
              </dt>
                        </dl>
                    </th>
                    <td id="back_1" className="back-1 no-liq" colSpan={2}>
                        <a id="info" />
                    </td>
                    <td className="refer-book" colSpan={2} />
                </tr>
                {/* Sportsbook bet quick*/}
                <tr id="sportsBookBetTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                    <td colSpan={7}>
                        {/* Quick Bet Wrap */}
                        <dl className="quick_bet-wrap slip-book">
                            <dt id="sportsBookBetHeader" className>
                <span id="sportsBookAcceptCheck" className="bet-check">
                  <input id="sportsBookAcceptAnyOdds" type="checkbox" />
                  <label htmlFor="sportsBookAcceptAnyOdds">Accept Any Odds</label>
                </span>
                <span id="sportsBookMaxStake" className="max-bet"><a id="getSportsBookMaxStakeBtn" href="#">Max</a></span>
              </dt>
                            <dd className="col-btn"><a id="cancel" className="btn">Cancel</a></dd>
                            <dd id="oddsHeader" className="col-odd">
                                <ul className="quick-bet-confirm">
                                    <li id="odds" />
                                    <li className="quick-bet-confirm-title">odds</li>
                                </ul>
                            </dd>
                            <dd className="col-stake">
                                <input id="inputStake" type="text" />
                            </dd>
                            <dd className="col-send">
                                <a id="placeBet" className="btn-send">Place Bets</a>
                            </dd>
                        </dl>
                        {/* Quick Bet Wrap End */}
                    </td>
                </tr>
                {/* Sportsbook bet quick bet mode*/}
                <tr id="sportsBookBetBarTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                    <td colSpan={7}>
                        {/* Quick Bet Wrap Progress */}
                        <dl className="quick_bet-wrap quick_bet-progress">
                            <dd id="progressBar" className="progress-bar" style={{width: '0%'}} />
                            <dd className="progress-bar-txt">Placing your bets, Please wait <span id="secRemain">0 sec remaining…</span></dd>
                        </dl>
                        {/* Quick Bet Wrap End */}
                    </td>
                </tr>
                {/* Sportsbook bet pause status*/}
                <tr id="sportsBookSuspendTemplate" className="fancy-suspend-tr" style={{display: 'none'}}>
                    <th colSpan={3} />
                    <td className="fancy-suspend-td" colSpan={2}>
                        <div id="suspendClass" className="fancy-suspend-white"><span>Suspend</span></div>
                    </td>
                    <td colSpan={2} />
                </tr>
                {/* Sportsbook bet quick bet mode*/}
                <tr id="sportsBookBetMessageTemplate" className="fancy-quick-tr" style={{display: 'none'}}>
                    <td colSpan={7}>
                        {/* Quick Bet Wrap Message */}
                        <dl id="classWrap" className="quick_bet-wrap quick_bet-message">
                            <dd id="info">
                                <strong id="header">Bet Matched</strong> USD$1,000 at odds 1.27 Profit: $5,800.00
                                <a id="close" className="btn-close">Close</a>
                            </dd>
                        </dl>
                        {/* Quick Bet Wrap End */}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</div>
        </React.Fragment>
    )
}

import React from 'react'
import { Link } from "react-router-dom"

export const Cricketmatches = () => {
    return (
        <React.Fragment>

            <ul className="slip-head">
                <li className="col-game" style={{ width: 'calc(64% - 21)' }} />
                <li className="col-visit">1</li>
                <li className="col-draw">x</li>
                <li className="col-home">2</li>
                <li className="col-info" />
            </ul>
            <div>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <span className="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>
                        <span _ngcontent-nef-c44="" id="bookMakerIcon" class="game-bookmaker in-play" style={{cursor: 'pointer', display: 'inline-flex'}}>BookMaker</span>
                        <span _ngcontent-nef-c44="" id="feedingSiteIcon" class="game-sportsbook" style={{display: 'flex'}}>Sportsbook</span>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <Link to="/fullmarket">Mumbai Indians v Lucknow Super Giants</Link>
                        <span className="game-list-time">3:30 PM</span>
                        <span class="game-live" id="streamingIcon" style={{display: 'inline-flex'}}>Live</span>
</dt>
                    <dd id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.88</a>
                        <a id="lay1_0" className="btn-lay">1.89</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a>
                    </dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" >2.12</a>
                        <a id="btnLay" className="btn-lay" >2.14</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin"  style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <span className="game-fancy in-play" id="fancyBetIcon" style={{ cursor: 'pointer', display: 'inline-flex' }}>Fancy</span>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285006/1.197460069">Delhi Capitals v Royal Challengers Bangalore</a>
                        <span className="game-list-time">7:30 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">2.08</a>
                        <a id="lay1_0" className="btn-lay">2.1</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a>
                    </dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" side="Back" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">1.9</a>
                        <a id="btnLay" className="btn-lay" side="Lay" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">1.91</a></dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285007/1.197512942">Punjab Kings v Sunrisers Hyderabad</a>
                        <span className="game-list-time">Tommorow 3:30 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.83</a>
                        <a id="lay1_0" className="btn-lay">1.91</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a>
                    </dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" side="Back" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">2.08</a>
                        <a id="btnLay" className="btn-lay" side="Lay" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">2.22</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285009/1.197512392">Gujarat Titans v Chennai Super Kings</a>
                        <span className="game-list-time">Tommorow 7:30 PM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.97</a>
                        <a id="lay1_0" className="btn-lay">2.04</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a>
                    </dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" side="Back" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">1.98</a>
                        <a id="btnLay" className="btn-lay" side="Lay" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">2.04</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}><dt>
                    <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                    <a href="/exchange/member/fullmarket/4/31285010/1.197548203">Rajasthan Royals v Kolkata Knight Riders</a>
                    <span className="game-list-time">2022-04-18 19:30:00</span>
                </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.87</a>
                        <a id="lay1_0" className="btn-lay">1.95</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a>
                    </dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" >2.06</a>
                        <a id="btnLay" className="btn-lay" >2.16</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285011/1.197615941">Lucknow Super Giants v Royal Challengers Bangalore</a>
                        <span className="game-list-time">2022-04-19 19:30:00</span>
                        </dt>
                    <dd id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.56</a>
                        <a id="lay1_0" className="btn-lay">2.8</a></dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a></dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" >1.56</a>
                        <a id="btnLay" className="btn-lay">2.8</a></dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285013/1.197662919">Delhi Capitals v Punjab Kings</a>
                        <span className="game-list-time">2022-04-20 19:30:00</span></dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.47</a>
                        <a id="lay1_0" className="btn-lay">3.15</a></dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a></dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" >1.47</a>
                        <a id="btnLay" className="btn-lay" >3.15</a></dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285014/1.197716235">Mumbai Indians v Chennai Super Kings</a>
                        <span className="game-list-time">2022-04-21 19:30:00</span></dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.46</a>
                        <a id="lay1_0" className="btn-lay">3.2</a></dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a></dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" side="Back" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">1.46</a>
                        <a id="btnLay" className="btn-lay" side="Lay" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">3.2</a></dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/4/31285018/1.197766655">Delhi Capitals v Rajasthan Royals</a>
                        <span className="game-list-time">2022-04-22 19:30:00</span></dt>
                    <dd id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back">1.4</a>
                        <a id="lay1_0" className="btn-lay">3.5</a></dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back">--</a>
                        <a id="btnLay" className="btn-lay">--</a></dd>
                    <dd className="col-home">
                        <a id="btnBack" className="btn-back" side="Back" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">1.4</a>
                        <a id="btnLay" className="btn-lay" side="Lay" selectionid={215817} runner="Atalanta" marketname="Match Odds" inplay={0} markettype="MATCH_ODDS">3.5</a>
                    </dd>
                    <dd className="col-info">
                        <a className="add-pin" title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
            </div>


            <div className="footer">
                <div id="supportWrap" className="support-wrap">
                    <div className="support-service">
                        <a id="support_whatsapp" className="support-whatsapp null">
                            <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" title="WhatsApp" />
                            </a>
                            </div>
                    <div className="support-info" />
                    </div>



            </div>


        </React.Fragment>
    )
}

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

export const MobNav = (props) => {
    const [navbar, setnavbar] = useState(false)
    const [scroll, setscroll] = useState()
    const changevalueonscroll = () => {
        if (window.scrollY >= 80) {
            setnavbar(true)

        }
        else {
            setnavbar(false)
        }
    };
    window.addEventListener('scroll', changevalueonscroll)


    return (
        <React.Fragment>
            <div class={scroll ? "ng-star-inserted nav-auto nav nav-category active" : "ng-star-inserted nav-auto nav nav-category"} style={{ width: 'auto' }} id="content_gameNav">
                <div className="slick-list draggable" style={{ padding: '0px 50px' }}>
                    <div className="slick-track" style={{ opacity: 1, width: 'auto', transform: 'translate3d(-198px, 0px, 0px)', display: 'flex' }}>
                        <div onClick={() => { props.settabsport(0) }} className={`btn slick-slide ${props.tabsport ? "slick-current slick-center" : ""}${(props.tabMenu === 3) ? "slick-current slick-center" : ""}`} data-navcategory="sport" data-gametype={4} data-slick-index={0} aria-hidden="true" tabIndex={-1} style={{ width: '99px' }}>
                            <Link to="/" style={{ top: 'auto', height: 'inherit' }}>   <p>
                                <ui-translate key="fe.text.nav.sport" lang="en">Sports</ui-translate>
                            </p>
                            </Link>
                        </div>

                        <div onClick={() => { props.settabsport(1) }} className={`btn slick-slide ${props.tabsport ? "slick-current slick-center" : ""}`} data-navcategory="casino" data-gametype={2} data-slick-index={1} aria-hidden="true" tabIndex={-1} style={{ width: '99px' }}>
                            <p>
                                <ui-translate key="fe.text.nav.casino" lang="en">Casino</ui-translate>
                            </p>
                        </div>
                        <div onClick={() => { props.settabsport(2) }} className={`btn slick-slide ${props.tabsport ? "slick-current slick-center" : ""}`} data-navcategory="games" data-gametype={1} data-slick-index={2} aria-hidden="true" tabIndex={-1} style={{ width: '99px' }}>
                            <p>
                                <ui-translate key="fe.text.nav.slot" lang="en">Slots</ui-translate>
                            </p>
                        </div>
                        <div className="btn slick-slide" data-navcategory="table" data-gametype={256} data-slick-index={3} aria-hidden="true" tabIndex={-1} style={{ width: '99px' }}>
                            <p>
                                <ui-translate key="fe.text.nav.table" lang="en">Table</ui-translate>
                            </p>
                        </div>
                        <div className="btn slick-slide " data-navcategory="lottery" data-gametype={64} data-slick-index={4} aria-hidden="true" tabIndex={0} style={{ width: '99px' }}>
                            <p>
                                <ui-translate key="fe.text.nav.lottery" lang="en">Lottery</ui-translate>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

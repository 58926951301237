import React from 'react'
import { Casinobanner } from './Casinobanner'
import CasinoCarousel from "./CasinoCarousel"
export const Casino = () => {
    return (
        <React.Fragment>
         <div id="pageContentBody">
    <div className="content" style={{paddingTop: '145px'}}>
    <CasinoCarousel/>
    <Casinobanner/>
    </div>
    </div>
        
        </React.Fragment>
    )
}

import React from 'react'

export const Resetpassword = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
    <div className="container-player-group reset-password-wrap">
        <form name="changePasswordForm" id="changePasswordForm" noValidate="novalidate">
            <div className="player-title-box">
                <h2>
                    <ui-translate key="fe.text.reset_pass" lang="en">Reset password</ui-translate>
                </h2>
            </div>
            <div className="tips-group password-tips">
                <p>
                    <ui-translate key="fe.text.password_requirements" lang="en">Password requirements</ui-translate>
                    :
                </p>
                <ol>
                    <li className="invalid" id="password-tips-letter">
                        <ui-translate key="fe.text.password_requirements.letter" lang="en">must be english characters（a-zA-Z)</ui-translate>
                    </li>
                    <li className="invalid" id="password-tips-length">
                        <ui-translate key="fe.text.password_requirements.length" lang="en">must be at least 6 characters</ui-translate>
                    </li>
                    <li className="invalid" id="password-tips-number">
                        <ui-translate key="fe.text.password_requirements.number" lang="en">at least 1 number（0-9)</ui-translate>
                    </li>
                </ol>
            </div>
            <div className="input-box">
                <div className="input-box-50">
                    <label htmlFor="password">
            <ui-translate key="fe.text.reset_pass.current_pass" lang="en">Current password</ui-translate>
          </label>
                    <input id="password" name="password" type="password" minLength={6} maxLength={20} required style={{display: 'block !important'}} aria-required="true" />
                </div>
            </div>
            <div className="input-box">
                <div className="input-box-50">{/* class: success */}
                    <label htmlFor="newPassword">
            <ui-translate key="fe.text.reset_pass.new_pass" lang="en">New password</ui-translate>
          </label>
                    <input id="newPassword" name="newPassword" type="password" minLength={6} maxLength={20} required style={{display: 'block !important'}} aria-required="true" />
                </div>
                <div className="input-box-50">{/* class: mistake */}
                    <label htmlFor="confirmPassword">
            <ui-translate key="fe.text.reset_pass.new_pass_confirm" lang="en">Confirm New password</ui-translate>
          </label>
                    <input id="confirmPassword" name="confirmPassword" type="password" minLength={6} maxLength={20} required style={{display: 'block !important'}} aria-required="true" />
                </div>
            </div>
            <button className="btn-default submit">
        <ui-translate key="fe.text.confirm" lang="en">Confirm</ui-translate>
      </button>
        </form>
    </div>
</div>
        </React.Fragment>
    )
}

import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import casino1 from './CasinoImages/casino-01.jpeg'
import casino2 from './CasinoImages/casino-02.jpeg'



const photos = [
   {
      name:'photo 1',
      url:[casino1]
   },
   {
   name:'photo 2',
   url:[casino2]
},


 ]

 const settings ={
    dots: true,
    slide:true,
    infinite:true,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll:1,
    className:'slidesN'
 }


export default function Carousel() {
    return (
        <React.Fragment>
            <Slider  {...settings} style={{height:'34vw'}}>
                  {photos.map((photo,id)=>{
                     return(
                     <div key ={id}>
                     <div class="main-casino-banner">
                        <img width="100%" src={photo.url} alt="cric" style={{height:'34vw'}}/>
                        </div>
                     </div>
                        )
                  })}
                  </Slider>
        </React.Fragment>
    )
}

import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Ipl1 from '../MobileImage/banner-1.jpeg'
import Banner2 from "./banner-2.jpeg"
import Banner3 from "./banner-3.jpeg"
import Banner4 from "./banner-4.jpeg"
import Banner5 from "./banner-5.jpeg"


const photos = [
   {
      name: 'photo 1',
      url: [Ipl1]
   },

   {
      name: 'photo 2',
      url: [Banner2]
   },
   {
    name: 'photo 3',
    url: [Banner3]
 },
 {
    name: 'photo 4',
    url: [Banner4]
 },
 {
    name: 'photo 5',
    url: [Banner5]
 }

]



const settings = {
   dots: true,
   slide: false,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 3500,
   speed: 800,
   slidesToShow: 1,
   arrows: false,
   slidesToScroll: 1,
   className: 'slidesN'
}



export default function MobCarousel() {
   return (
      <React.Fragment>
         <Slider  {...settings} style={{ height: '100%' }}>
            {photos.map((photo, id) => {
               return (
                  <div key={id}>
                
                     <img width="100%" src={photo.url} alt="cric" style={{width: '100%',height:"auto"}} />
                  
                  </div>
               )
            })}
         </Slider>
      </React.Fragment>
   )
}

import React from 'react'
import bannerImage1 from "./imageskybanner-1.jpeg";
import bannerImage2 from "./imageskybanner2.jpeg"
import bannerImage3 from "./imageskybanner3.jpeg"
export const Mobskybanner = () => {
    return (
        <React.Fragment>
            <div className="recommend">
    <div className="recommend-title">
        <h2>Sky247 Club</h2>
    </div>
    <div className="recommend-bg">
        <div id="main_sport_slider_container" className="recommend-main" style={{minHeight: '145px'}}>
            <div id="main_wrap_sport_template" className="recommend-card">
                <a>
          <img src={bannerImage1}/>
        </a>
            </div>
            <div id="main_wrap_sport_template" className="recommend-card">
                <a>
          <img  src={bannerImage2} />
        </a>
            </div>
            <div id="main_wrap_sport_template" className="recommend-card">
                <a>
          <img src={bannerImage3} />
        </a>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

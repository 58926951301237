import React,{useState} from 'react'


export const Casinobanner = () => {
    const [Aesexy, setAesexy] = useState(false)
    const [mozos, setmozos] = useState(false)
    const [evo, setevo] = useState(false)
    const [microgaming, setmicrogaming] = useState(false)
    const [playtec, setplaytec] = useState(false)

    const Casinobanner=(val1,val2)=>{
        if(val1 ===1){
            setAesexy(val2)
        }
        if(val1 ===2){
            setmozos(val2)
        }
        if(val1 ===3){
            setevo(val2)
        }
        if(val1 ===4){
            setmicrogaming(val2)
        }
        if(val1 ===5){
            setplaytec(val2)
        }
        
    }
    return (
        <React.Fragment>
            <div id="casinoContainer" className="container-1200 casino">
    <div id="casino_lg_container" className=" ">
        <div id="casino-AWCMSEXY" onMouseEnter={()=>{Casinobanner(1,true)}} onMouseLeave={()=>{Casinobanner(1,false)}} className={`${ Aesexy ? "casino-lg active" : "casino-lg"}`} title="SEXY">
            <ul className="mask" ui-key="playItems">
                <li title="Baccarat">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Baccarat</a>
                </li>
                <li title="Dice">
                    <a className="play-item itemSuibao" style={{cursor: 'default'}} data-css="itemSuibao" ui-key="title">Dice</a>
                </li>
                <li title="DragonTiger">
                    <a className="play-item itemDragonTiger" style={{cursor: 'default'}} data-css="itemDragonTiger" ui-key="title">DragonTiger</a>
                </li>
                <li title="Roulette">
                    <a className="play-item itemRoulette" style={{cursor: 'default'}} data-css="itemRoulette" ui-key="title">Roulette</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" id="sexyimage"  data-title="SEXY"  title="SEXY" />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">SEXY</h3>
                <h4 ui-key="desc1">Glamourous dealers in hot bikinis.</h4>
                <p ui-key="desc2">Charming Seductive Voices,Multiple Bets / quick bet function,Baccarat Road Map notifications</p>
                <a name="vendor" className="btn-default" data-play data-vendor="AWCMSEXY" data-type="Casino" data-href="/player/playGame?t=casino&v=AWCMSEXY">Play Game
        </a>
            </div>
        </div>
        <div id="casino-AWCMSEVENMOJOS" onMouseEnter={()=>{Casinobanner(2,true)}} onMouseLeave={()=>{Casinobanner(2,false)}} className={`${ mozos ? "casino-lg active" : "casino-lg"}`}  title="7MOJ">
            <ul className="mask" ui-key="playItems">
                <li title="Blackjack">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Blackjack</a>
                </li>
                <li title="Roulette">
                    <a className="play-item itemRoulette" style={{cursor: 'default'}} data-css="itemRoulette" ui-key="title">Roulette</a>
                </li>
                <li title="Baccarat">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Baccarat</a>
                </li>
                <li title="DragonTiger">
                    <a className="play-item itemDragonTiger" style={{cursor: 'default'}} data-css="itemDragonTiger" ui-key="title">DragonTiger</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" id="mazos" title="7MOJ" />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">7MOJ</h3>
                <h4 ui-key="desc1">7MOJOS</h4>
                <p ui-key="desc2">7MOJOS</p>
                <a name="vendor" className="btn-default" data-play data-vendor="AWCMSEVENMOJOS" data-type="Casino" data-href="/player/playGame?t=casino&v=AWCMSEVENMOJOS">Play Game
        </a>
            </div>
        </div>
    </div>
    <div style={{display: 'none'}}>
        <div id="casino_lg_template" className="casino-lg">
            <ul className="mask" ui-key="playItems">
                <li>
                    <a className="play-item" style={{cursor: 'default'}} data-css ui-key="title">-</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" data-image data-title style={{backgroundImage: 'url("")'}} />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">-</h3>
                <h4 ui-key="desc1">-</h4>
                <p ui-key="desc2">-</p>
                <a name="vendor" className="btn-default" data-play data-vendor data-type data-href>Play Game
        </a>
            </div>
        </div>
    </div>
    <div id="casino_list_container" className="list-wrap-casino">
        <div id="casino-EVO" onMouseEnter={()=>{Casinobanner(3,true)}} onMouseLeave={()=>{Casinobanner(3,false)}} className={`${ evo ? "casino-xs active" : "casino-xs"}`}  title="EVO">
            <ul className="mask" ui-key="playItems">
                <li title="Baccarat">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Baccarat</a>
                </li>
                <li title="Dice">
                    <a className="play-item itemSuibao" style={{cursor: 'default'}} data-css="itemSuibao" ui-key="title">Dice</a>
                </li>
                <li title="Roulette">
                    <a className="play-item itemRoulette" style={{cursor: 'default'}} data-css="itemRoulette" ui-key="title">Roulette</a>
                </li>
                <li title="Blackjack">
                    <a className="play-item itemBlackJack" style={{cursor: 'default'}} data-css="itemBlackJack" ui-key="title">Blackjack</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" id="evolution"  title="EVO" />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">EVO</h3>
                <h4 ui-key="desc1">European style live casino platform</h4>
                <p ui-key="desc2">Super sic bo, Deal or no deal live, European casino games</p>
                <a name="vendor" className="btn-default" data-play data-vendor="EVO" data-type="Casino" data-href="/player/playGame?t=casino&v=EVO">Play Game
        </a>
            </div>
        </div>
        <div id="casino-MG"  onMouseEnter={()=>{Casinobanner(4,true)}} onMouseLeave={()=>{Casinobanner(4,false)}} className={`${ microgaming ? "casino-xs active" : "casino-xs"}`} title="MG">
            <ul className="mask" ui-key="playItems">
                <li title="Baccarat">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Baccarat</a>
                </li>
                <li title="Dice">
                    <a className="play-item itemSuibao" style={{cursor: 'default'}} data-css="itemSuibao" ui-key="title">Dice</a>
                </li>
                <li title="Roulette">
                    <a className="play-item itemRoulette" style={{cursor: 'default'}} data-css="itemRoulette" ui-key="title">Roulette</a>
                </li>
                <li title="DragonTiger">
                    <a className="play-item itemDragonTiger" style={{cursor: 'default'}} data-css="itemDragonTiger" ui-key="title">DragonTiger</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" id="mgimg" title="MG" />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">MG</h3>
                <h4 ui-key="desc1">HD streaming and multiwindow games</h4>
                <p ui-key="desc2">PLAYBOY girls room</p>
                <a name="vendor" className="btn-default" data-play data-vendor="MG" data-type="Casino" data-href="/player/playGame?t=casino&v=MG">Play Game
        </a>
            </div>
        </div>
        <div id="casino-SilkStonePT"   onMouseEnter={()=>{Casinobanner(5,true)}} onMouseLeave={()=>{Casinobanner(5,false)}} className={`${ playtec ? "casino-xs active" : "casino-xs"}`} title="PT">
            <ul className="mask" ui-key="playItems">
                <li title="Baccarat">
                    <a className="play-item itemBaccarat" style={{cursor: 'default'}} data-css="itemBaccarat" ui-key="title">Baccarat</a>
                </li>
                <li title="Dice">
                    <a className="play-item itemSuibao" style={{cursor: 'default'}} data-css="itemSuibao" ui-key="title">Dice</a>
                </li>
                <li title="DragonTiger">
                    <a className="play-item itemDragonTiger" style={{cursor: 'default'}} data-css="itemDragonTiger" ui-key="title">DragonTiger</a>
                </li>
                <li title="Blackjack">
                    <a className="play-item itemBlackJack" style={{cursor: 'default'}} data-css="itemBlackJack" ui-key="title">Blackjack</a>
                </li>
                <li title="Roulette">
                    <a className="play-item itemRoulette" style={{cursor: 'default'}} data-css="itemRoulette" ui-key="title">Roulette</a>
                </li>
                <li title="Casino Holdem">
                    <a className="play-item itemHoldem" style={{cursor: 'default'}} data-css="itemHoldem" ui-key="title">Casino Holdem</a>
                </li>
                <li title="Hi-Lo">
                    <a className="play-item itemLiveHiLow" style={{cursor: 'default'}} data-css="itemLiveHiLow" ui-key="title">Hi-Lo</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" id="playtec" title="PT" />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">PT</h3>
                <h4 ui-key="desc1">Cutting edge products and industry-leading platform.</h4>
                <p ui-key="desc2">Spin a Win, multiple table types</p>
                <a name="vendor" className="btn-default" data-play data-vendor="SilkStonePT" data-type="Casino" data-href="/player/playGame?t=casino&v=SilkStonePT">Play Game
        </a>
            </div>
        </div>
        <div id="casinoBannerXs" className="casino-banner-xs data-image right" id="casinoBannerXs">
            <a data-pagetype={9} />
        </div>
    </div>
    {/* end class="list-wrap-casino" */}
    <div style={{display: 'none'}}>
        <div id="casino_template" className="casino-xs">
            <ul className="mask" ui-key="playItems">
                <li>
                    <a className="play-item" style={{cursor: 'default'}} data-css ui-key="title">-</a>
                </li>
            </ul>
            <div className="img-wrap">
                <div className="img data-image data-title" data-image data-title style={{backgroundImage: 'url("")'}} />
            </div>
            <div className="word-wrap">
                <h3 ui-key="title">-</h3>
                <h4 ui-key="desc1">-</h4>
                <p ui-key="desc2" />
                <a name="vendor" className="btn-default" data-play data-vendor data-type data-href>Play Game
        </a>
            </div>
        </div>
    </div>
    <div id="casinoBannerContainer" style={{display: 'none'}}>
        <div id="casinoBannerLg" className="casino-banner-lg data-image" data-image="/images/banner/en_casino-banner-lg.jpg" style={{backgroundImage: 'url("/images/banner/en_casino-banner-lg.jpg")'}}>
            <a data-pagetype={9} />
        </div>
        <div id="casinoBannerXs" className="casino-banner-xs data-image right" data-image="/images/banner/en_casino-banner-xs.jpg" style={{backgroundImage: 'url("/images/banner/en_casino-banner-xs.jpg")'}}>
            <a data-pagetype={9} />
        </div>
        <div id="casinoBannerMd" className="casino-banner-md data-image" data-image="/images/banner/en_casino-banner-md.jpg" style={{backgroundImage: 'url("/images/banner/en_casino-banner-md.jpg")'}}>
            <a data-pagetype={9} />
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

import React from 'react'
import providerEvo from "../images/Social/provider-evo.png";
import awcmsexy from "../images/Social/provider-awcmsexy.png"
import awcmpt from "../images/Social/provider-awcmpt.png"
import awcmsevenmojos from "../images/Social/provider-awcmsevenmojos.png"
import awcmpp from "../images/Social/provider-awcmpp.png"
import awcmvenus from "../images/Social/provider-awcmvenus.png"
import providerwm from "../images/Social/provider-wm.png"
import providerab from "../images/Social/provider-ab.png"
import providermg from "../images/Social/provider-mg.png"
const Sportscasino = () => {
    return (
        <React.Fragment>
            <div _ngcontent-serverapp-c66="" className="nav-wrap ng-star-inserted">
                <div _ngcontent-serverapp-c66="" id="nav-title" className="content-title">
                    <h2 _ngcontent-serverapp-c66="">
                        <span _ngcontent-serverapp-c66="">Casino</span>
                    </h2>
                </div>
                <div _ngcontent-serverapp-c66="" mcddragmove className="nav-content-wrap">
                    <div _ngcontent-serverapp-c66="" className="nav-content-inner" >

                        <div _ngcontent-serverapp-c66="" className="content-box ng-star-inserted" style={{}}>
                            <div _ngcontent-serverapp-c66="" className="layout-brand ng-star-inserted">
                                <div _ngcontent-serverapp-c66="" className="card1">
                                    <ul _ngcontent-serverapp-c66="">
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="EVO">
                                                <img _ngcontent-serverapp-c66="" alt="provider-evo" src={providerEvo} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">EVO</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="SEXY">
                                                <img _ngcontent-serverapp-c66="" alt="provider-awcmsexy" src={awcmsexy} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">SEXY</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="PT">
                                                <img _ngcontent-serverapp-c66="" alt="provider-awcmpt" src={awcmpt} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">PT</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="7MOJ">
                                                <img _ngcontent-serverapp-c66="" alt="provider-awcmsevenmojos" src={awcmsevenmojos} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">7MOJ</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="PP">
                                                <img _ngcontent-serverapp-c66="" alt="provider-awcmpp" src={awcmpp} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">PP</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="VENUS">
                                                <img _ngcontent-serverapp-c66="" alt="provider-awcmvenus" src={awcmvenus} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">VENUS</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="WM">
                                                <img _ngcontent-serverapp-c66="" alt="provider-wm" src={providerwm} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">WM</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="AB">
                                                <img _ngcontent-serverapp-c66="" alt="provider-ab" src={providerab} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">AB</p>
                                            </a>
                                        </li>
                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted">
                                            <a _ngcontent-serverapp-c66="" tabIndex={-1} vender="MG">
                                                <img _ngcontent-serverapp-c66="" alt="provider-mg" src={providermg} className="ng-star-inserted" />

                                                <p _ngcontent-serverapp-c66="">MG</p>
                                            </a>
                                        </li>

                                        <li
                                            _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                        <li _ngcontent-serverapp-c66="" className="ng-star-inserted" />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sportscasino

import React from 'react'
import Deposit from "../Deposit/depoimage/no-deposit.svg"

const Bettingrecords = () => {

    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
    <main className="container-player-wrapper">
        <div className="container-player-group">
            <div className="player-title-box">
                <h2>
                    <ui-translate key="fe.text.bettingRecord" lang="en">Betting Records</ui-translate>
                </h2>
            </div>
            <div className="main-table-info">
                <div className="tab-secondary-box">
                    <ul className="tabs">
                        <li>
                            <input type="radio" name="betRecord" id="settledTab" defaultChecked />
                            <label htmlFor="settledTab"><ui-translate key="fe.text.txn.status.type.1" lang="en">Settled</ui-translate></label>
                        </li>
                        <li data-pagetype="3.5">
                            <input type="radio" name="betRecord" id="unSettledTab" />
                            <label htmlFor="unSettledTab"><ui-translate key="fe.text.txn.status.type.0" lang="en">Unsettled</ui-translate></label>
                        </li>
                    </ul>
                </div>
                <div className="filter-box">
                    <div id="filterVendor" className="filter-group" style={{width: 'fit-content'}}>
                        <label>
              <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
            </label>
                        <ul id="vendorFilterContainer" className="choose-box">
                            <li>
                                <input type="checkbox" name="planform" id="vendor-0" defaultValue={-1} defaultChecked />
                                <label htmlFor="vendor-0"><ui-translate key="fe.text.all" lang="en">All</ui-translate></label>
                            </li>
                            <li id="vendor-1">
                                <input type="checkbox" name="planform" data-vendorid={1} id="vendor-1-input" defaultValue={1} />
                                <label ui-key="displayName" htmlFor="vendor-1-input">MG</label>
                            </li>
                            <li id="vendor-75">
                                <input type="checkbox" name="planform" data-vendorid={75} id="vendor-75-input" defaultValue={75} />
                                <label ui-key="displayName" htmlFor="vendor-75-input">KM</label>
                            </li>
                            <li id="vendor-83">
                                <input type="checkbox" name="planform" data-vendorid={83} id="vendor-83-input" defaultValue={83} />
                                <label ui-key="displayName" htmlFor="vendor-83-input">JILI</label>
                            </li>
                            <li id="vendor-18">
                                <input type="checkbox" name="planform" data-vendorid={18} id="vendor-18-input" defaultValue={18} />
                                <label ui-key="displayName" htmlFor="vendor-18-input">Saba</label>
                            </li>
                            <li id="vendor-19">
                                <input type="checkbox" name="planform" data-vendorid={19} id="vendor-19-input" defaultValue={19} />
                                <label ui-key="displayName" htmlFor="vendor-19-input">JDB</label>
                            </li>
                            <li id="vendor-23">
                                <input type="checkbox" name="planform" data-vendorid={23} id="vendor-23-input" defaultValue={23} />
                                <label ui-key="displayName" htmlFor="vendor-23-input">PG</label>
                            </li>
                            <li id="vendor-66">
                                <input type="checkbox" name="planform" data-vendorid={66} id="vendor-66-input" defaultValue={66} />
                                <label ui-key="displayName" htmlFor="vendor-66-input">Exchange</label>
                            </li>
                            <li id="vendor-98">
                                <input type="checkbox" name="planform" data-vendorid={98} id="vendor-98-input" defaultValue={98} />
                                <label ui-key="displayName" htmlFor="vendor-98-input">7MOJ</label>
                            </li>
                            <li id="vendor-67">
                                <input type="checkbox" name="planform" data-vendorid={67} id="vendor-67-input" defaultValue={67} />
                                <label ui-key="displayName" htmlFor="vendor-67-input">SEXY</label>
                            </li>
                            <li id="vendor-72">
                                <input type="checkbox" name="planform" data-vendorid={72} id="vendor-72-input" defaultValue={72} />
                                <label ui-key="displayName" htmlFor="vendor-72-input">BPOKER</label>
                            </li>
                            <li id="vendor-31">
                                <input type="checkbox" name="planform" data-vendorid={31} id="vendor-31-input" defaultValue={31} />
                                <label ui-key="displayName" htmlFor="vendor-31-input">PT</label>
                            </li>
                            <li id="vendor-37">
                                <input type="checkbox" name="planform" data-vendorid={37} id="vendor-37-input" defaultValue={37} />
                                <label ui-key="displayName" htmlFor="vendor-37-input">EVO</label>
                            </li>
                            <li id="vendor-88">
                                <input type="checkbox" name="planform" data-vendorid={88} id="vendor-88-input" defaultValue={88} />
                                <label ui-key="displayName" htmlFor="vendor-88-input">LUDO</label>
                            </li>
                        </ul>
                        <div className="more-filter-proup">
                            <h4>
                                <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
                            </h4>
                            <ul id="vendorFilterMoreContainer" className="choose-box">
                                <li>
                                    <input type="checkbox" name="more" defaultChecked />
                                    <label>all</label>
                                </li>
                            </ul>
                            <div className="filter-btn-box">
                                <button className="btn-5th-xs" onclick="$('#vendorFilterMoreContainer').parent().removeClass('active')">
                  <ui-translate key="fe.text.confirm" lang="en">Confirm</ui-translate>
                </button>
                            </div>
                        </div>
                    </div>
                    <div id="filterGameType" className="filter-group" style={{width: 'fit-content'}}>
                        <label>
              <ui-translate key="fe.text.gameType" lang="en">Game Type</ui-translate>
            </label>
                        <ul id="gameTypeFilterContainer" className="choose-box">
                            <li>
                                <input type="checkbox" name="gameType" id="gameType-0" defaultValue={-1} defaultChecked />
                                <label htmlFor="gameType-0"><ui-translate key="fe.text.all" lang="en">All</ui-translate></label>
                            </li>
                            <li id="gameType-1">
                                <input type="checkbox" name="gameType" data-gametypeid={1} id="gameType-1-input" defaultValue={1} />
                                <label ui-key="fullName" htmlFor="gameType-1-input">Slots</label>
                            </li>
                            <li id="gameType-2">
                                <input type="checkbox" name="gameType" data-gametypeid={2} id="gameType-2-input" defaultValue={2} />
                                <label ui-key="fullName" htmlFor="gameType-2-input">Live Casino</label>
                            </li>
                            <li id="gameType-4">
                                <input type="checkbox" name="gameType" data-gametypeid={4} id="gameType-4-input" defaultValue={4} />
                                <label ui-key="fullName" htmlFor="gameType-4-input">Sports</label>
                            </li>
                            <li id="gameType-8">
                                <input type="checkbox" name="gameType" data-gametypeid={8} id="gameType-8-input" defaultValue={8} />
                                <label ui-key="fullName" htmlFor="gameType-8-input">Fishing</label>
                            </li>
                            <li id="gameType-16">
                                <input type="checkbox" name="gameType" data-gametypeid={16} id="gameType-16-input" defaultValue={16} />
                                <label ui-key="fullName" htmlFor="gameType-16-input">Card</label>
                            </li>
                            <li id="gameType-32">
                                <input type="checkbox" name="gameType" data-gametypeid={32} id="gameType-32-input" defaultValue={32} />
                                <label ui-key="fullName" htmlFor="gameType-32-input">E-sports</label>
                            </li>
                            <li id="gameType-64">
                                <input type="checkbox" name="gameType" data-gametypeid={64} id="gameType-64-input" defaultValue={64} />
                                <label ui-key="fullName" htmlFor="gameType-64-input">Lottery</label>
                            </li>
                            <li id="gameType-128">
                                <input type="checkbox" name="gameType" data-gametypeid={128} id="gameType-128-input" defaultValue={128} />
                                <label ui-key="fullName" htmlFor="gameType-128-input">P2P</label>
                            </li>
                            <li id="gameType-256">
                                <input type="checkbox" name="gameType" data-gametypeid={256} id="gameType-256-input" defaultValue={256} />
                                <label ui-key="fullName" htmlFor="gameType-256-input">Table</label>
                            </li>
                            <li id="gameType-1024">
                                <input type="checkbox" name="gameType" data-gametypeid={1024} id="gameType-1024-input" defaultValue={1024} />
                                <label ui-key="fullName" htmlFor="gameType-1024-input">Arcade</label>
                            </li>
                            <li id="gameType-512">
                                <input type="checkbox" name="gameType" data-gametypeid={512} id="gameType-512-input" defaultValue={512} />
                                <label ui-key="fullName" htmlFor="gameType-512-input">Other</label>
                            </li>
                        </ul>
                        <div className="more-filter-proup">
                            <h4>
                                <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
                            </h4>
                            <ul id="gameTypeFilterMoreContainer" className="choose-box">
                                <li>
                                    <input type="checkbox" name="more" defaultChecked />
                                    <label>all</label>
                                </li>
                            </ul>
                            <div className="filter-btn-box">
                                <button className="btn-5th-xs" onclick="$('#gameTypeFilterMoreContainer').parent().removeClass('active')">
                  <ui-translate key="fe.text.confirm" lang="en">Confirm</ui-translate>
                </button>
                            </div>
                        </div>
                    </div>
                    <div className="filter-group">
                        <label>
              <ui-translate key="fe.text.date" lang="en">Date</ui-translate>
            </label>
                        <ul className="choose-box">
                            <li>
                                <input type="radio" name="dateOption" id="dateOption0" defaultValue={0} defaultChecked />
                                <label htmlFor="dateOption0">
                  <ui-translate key="fe.text.today" lang="en">Today</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="dateOption" id="dateOption1" defaultValue={1} />
                                <label htmlFor="dateOption1">
                  <ui-translate key="fe.text.yesterday" lang="en">Yesterday</ui-translate>
                </label>
                            </li>
                            <li>
                                <input type="radio" name="dateOption" id="dateOption7" defaultValue={7} />
                                <label htmlFor="dateOption7">
                  <ui-translate key="fe.text.lastDays" params="[7]" lang="en">Last 7 days</ui-translate>
                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="filter-btn-box">
                        <button className="btn-5th-xs query-bet-report">
              <ui-translate key="fe.text.submit" lang="en">Submit</ui-translate>
            </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="table-change-wrap">
            <div className="table-change-inner">
                <div className="container-player-group">
                    <div className="table-container">
                        <ul className="table-content main-tab-content">
                            <li className="active">
                                <div id="bet-report-tips-container" className="tips-group" style={{}}>
                                    <div className="bet-info">
                                        <p>Total<span id="tipTotalRecords">0</span>records</p>
                                        <p>
                                            <ui-translate key="fe.text.report.turnover" lang="en">Turnover</ui-translate>
                                            ：<span id="tipTurnover">0</span></p>
                                        <p>
                                            <ui-translate key="fe.text.report.txnProfitLoss" lang="en">Profit/Loss</ui-translate>
                                            ：<span id="tipsProfitLoss" className="win">0</span></p>
                                    </div>
                                </div>
                                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                                    <div className="dataTables_length" id="DataTables_Table_0_length"><label><select onchange="setTimeout(function(){ if(!$('.go-to-sub-table').DataTable().fnPageChange) { return; } $('.go-to-sub-table').DataTable().fnPageChange(0);}, 10);"><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> records per page</label></div>
                                    <div
                                        id="DataTables_Table_0_processing" className="dataTables_processing" style={{display: 'none'}}>Processing...</div>
                                <table className="table-box go-to-sub-table display dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" style={{width: '891px'}}>
                                    <thead>
                                        <tr className="table-title" role="row">
                                            <th className="column-num txt-center sorting_disabled" rowSpan={1} colSpan={1} aria-label="&nbsp;" style={{width: '49px'}}>&nbsp;</th>
                                            <th className="column-l sorting_disabled" rowSpan={1} colSpan={1} aria-label="
									Platform
								" style={{width: '175px'}}>
                                                <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
                                            </th>
                                            <th className="column-l sorting_disabled" rowSpan={1} colSpan={1} aria-label="
									Game Type
								" style={{width: '175px'}}>
                                                <ui-translate key="fe.text.gameType" lang="en">Game Type</ui-translate>
                                            </th>
                                            <th className="column-s txt-num sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="
									Turnover
								: activate to sort column ascending" style={{width: '95px'}}>
                                                <ui-translate key="fe.text.report.turnover" lang="en">Turnover</ui-translate>
                                            </th>
                                            <th className="column-s txt-num sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="
									Profit/Loss
								: activate to sort column ascending" style={{width: '95px'}}>
                                                <ui-translate key="fe.text.report.txnProfitLoss" lang="en">Profit/Loss</ui-translate>
                                            </th>
                                            <th className="column-time sorting_asc" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="
									Settle Date
									
								: activate to sort column descending" style={{width: '129px'}} aria-sort="ascending">
                                                <ui-translate key="fe.text.report.txnSettleDate" lang="en">Settle Date</ui-translate>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                            <td valign="top" colSpan={6} className="dataTables_empty">
                                                <div className="no-info-txt">
                                                    <div className="no-info-img"><img className="img-load" src={Deposit} /></div>
                                                    <p>
                                                        <ui-translate key="fe.text.noData" lang="en">No Data</ui-translate>
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate" style={{display: 'none'}}><a className="paginate_button previous disabled" aria-controls="DataTables_Table_0" data-dt-idx={0} tabIndex={-1} id="DataTables_Table_0_previous">Previous</a><span /><a className="paginate_button next disabled" aria-controls="DataTables_Table_0"
                                        data-dt-idx={1} tabIndex={-1} id="DataTables_Table_0_next">Next</a></div>
                    </div>
                    </li>
                    <li>
                        <table className="table-box-test no-value-table">
                            <thead>
                                <tr className="table-title">
                                    <th className="column-num txt-center">&nbsp;</th>
                                    <th className="column-m">
                                        <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
                                    </th>
                                    <th className="column-m">
                                        <ui-translate key="fe.text.gameType" lang="en">Game Type</ui-translate>
                                    </th>
                                    <th className="column-m">
                                        <ui-translate key="fe.text.report.txnGame" lang="en">Game</ui-translate>
                                    </th>
                                    <th className="column-s txt-num">
                                        <ui-translate key="fe.text.report.txnBet" lang="en">Bet</ui-translate>
                                    </th>
                                    <th className="column-time">
                                        <ui-translate key="ui.text.report.txnDate" lang="en">Txn Date</ui-translate>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="no-info-txt">
                            <div className="no-info-img pre-load"><img className="img-load" data-preload="/images/player/table/no-bet.svg" /></div>
                            <p>fe.text.noData</p>
                        </div>
                    </li>
                    </ul>
                </div>
            </div>
            <div className="container-player-group">
                <div className="main-table-box">
                    <a className="btn-return">
                        <ui-translate key="fe.text.go_back" lang="en">back</ui-translate>
                    </a>
                    <table className="up-table-box display dataTable">
                        <thead>
                            <tr className="table-title">
                                <th className="column-num txt-center">&nbsp;</th>
                                <th className="column-m">
                                    <ui-translate key="fe.text.platform" lang="en">Platform</ui-translate>
                                </th>
                                <th className="column-m">
                                    <ui-translate key="fe.text.gameType" lang="en">Game Type</ui-translate>
                                </th>
                                <th className="column-m">
                                    <ui-translate key="fe.text.report.turnover" lang="en">Turnover</ui-translate>
                                </th>
                                <th className="column-s txt-num">
                                    <ui-translate key="fe.text.report.txnProfitLoss" lang="en">Profit/Loss</ui-translate>
                                </th>
                                <th className="column-time">
                                    <ui-translate key="fe.text.report.txnSettleDate" lang="en">Settle Date</ui-translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="betDetailReportTopRow">
                                <td className="txt-center">-</td>
                                <td>-</td>
                                <td>-</td>
                                <td className="txt-num">-</td>
                                <td className="txt-num">-</td>
                                <td className="column-time" style={{backgroundColor: 'unset'}}>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="sub-table-box">
                    <h5>
                        <ui-translate key="ui.text.detail_report" lang="en">Detail Report</ui-translate>
                    </h5>
                    <table className="table-box display sub-table">
                        <thead>
                            <tr className="table-title">
                                <th className="column-num txt-center" style={{maxWidth: '16px'}}>&nbsp;</th>
                                <th className="column-m" style={{maxWidth: '120px'}}>
                                    <ui-translate key="fe.text.report.txnGame" lang="en">Game</ui-translate>
                                </th>
                                <th className="column-s txt-num" style={{maxWidth: '100px'}}>
                                    <ui-translate key="fe.text.report.turnover" lang="en">Turnover</ui-translate>
                                </th>
                                <th className="column-s txt-num" style={{maxWidth: '100px'}}>
                                    <ui-translate key="fe.text.report.txnProfitLoss" lang="en">Profit/Loss</ui-translate>
                                </th>
                                <th className="column-s" style={{maxWidth: '75px'}}>
                                    <ui-translate key="fe.text.status" lang="en">Status</ui-translate>
                                </th>
                                <th className="column-time" style={{maxWidth: '140px'}}>
                                    <ui-translate key="fe.text.report.txnSettleDate" lang="en">Settle Date</ui-translate>
                                </th>
                                <th className="column-time" style={{maxWidth: '140px'}}>
                                    <ui-translate key="ui.text.report.txnDate" lang="en">Txn Date</ui-translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody />
                    </table>
                </div>
            </div>
        </div>
</div>
</main>
<div style={{display: 'none'}}>
    <ul>
        <li id="vendorFilterTemplate">
            <input type="checkbox" name="planform" data-vendorid />
            <label ui-key="displayName" />
        </li>
        <li id="gameTypeFilterTemplate">
            <input type="checkbox" name="gameType" data-gametypeid />
            <label ui-key="fullName" />
        </li>
    </ul>
</div>
</div>
        </React.Fragment>
    )
}

export default Bettingrecords

import React from 'react'
import Slider from 'react-slick'
import Casino1 from '../images/Banner/casino1.jpeg';
import Casino2 from '../images/Banner/casino2.jpeg'


const casino = [
    {
       name:'photo 1',
       url:[Casino1]
    },
    {
    name:'photo 2',
    url:[Casino2]
 },

  
  ]
 
  const settings ={
     dots: true,
     slide:true,
     infinite:true,
     autoplay: false,
     autoplaySpeed: 3500,
     speed: 800,
     slidesToShow: 1,
     arrows: true,
     slidesToScroll:1,
     className:'slidesN'
  }
 
export const SlidingCasino = () => {
    
    return (
        <React.Fragment>
        <Slider  {...settings} >
              {casino.map((photo,id)=>{
                 return(
                 <div key ={id}>
                    <img width="100%" src={photo.url} alt="cric" style={{height:'45vh'}}/>
                 </div>
                    )
              })}
              </Slider>
    </React.Fragment>
    )
}

import React,{useState} from 'react'
import { Deposit } from '../Deposit'
import { Withdrawl } from './Withdrawl'

export const Navdeposit = () => {
    const [tabChange, settabChange] = useState(0)
    return (
        <React.Fragment>
         <div id="pageContentBody" class="content player-content">   <div class="tabBtn-section tabBtn-wrap">
                <div class="footer-related-bar tabBtn tabBtn-bar" id="depositTab">
                    <div class="line" style={  tabChange?{width: 'calc(50%)',transform:' translate(100%, 0px)'  }: {width:' calc(50%)',transform: 'translate(0%, 0px)'}}>

                    </div>
                    <div class="btn" data-tab-current="current">
                        <div class="text" onClick={()=>{settabChange(0)}}>
                            <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                            <div class="badge"></div>
                        </div>
                    </div>
                    <div class="btn" data-tab-current="">
                        <div class="text"  onClick={()=>{settabChange(1)}}>
                            <ui-translate key="fe.text.withdrawal" lang="en">Withdrawal</ui-translate>
                            <div class="badge">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
           {tabChange ===0 && <Deposit />}
           {tabChange ===1 &&    <Withdrawl />}
           </div>
        </React.Fragment>
    )
}

import React from 'react'

export const Inbox = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
                <div className="container-player-group">
                    <div className="player-title-box">
                        <h2>
                            <ui-translate key="fe.text.inbox" lang="en">Inbox</ui-translate>
                        </h2>
                    </div>
                    <div className="tab-secondary-box">
                        <ul className="tabs">
                            <li className="active">
                                <input type="radio" name="mail" id="notifyInbox" defaultChecked />
                                <label htmlFor="notifyInbox">
                                    <ui-translate key="fe.text.notification" lang="en">Notification</ui-translate>&nbsp;<span id="inboxMsgCount">3</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="table-change-wrap">
                        <div className="table-container">
                            <ul className="table-content main-tab-content">
                                <li className="active">
                                    <div id="DataTables_Table_4_wrapper" className="dataTables_wrapper no-footer">
                                        <div className="dataTables_length" id="DataTables_Table_4_length"><label>Display <select name="DataTables_Table_4_length" aria-controls="DataTables_Table_4" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> records</label></div>
                                        <table
                                            className="table-box display mail-table dataTable no-footer" id="DataTables_Table_4" role="grid" aria-describedby="DataTables_Table_4_info">
                                            <thead>
                                                <tr className="table-title" role="row">
                                                    <th className="column-m sorting_disabled" rowSpan={1} colSpan={1} aria-label="
				Subject
			" style={{ width: '240px' }}>
                                                        <ui-translate key="fe.text.inbox.subject" lang="en">Subject</ui-translate>
                                                    </th>
                                                    <th className="column-l sorting_disabled" rowSpan={1} colSpan={1} aria-label="
				Content
			" style={{ width: '379px' }}>
                                                        <ui-translate key="fe.text.inbox.content" lang="en">Content</ui-translate>
                                                    </th>
                                                    <th className="column-time sorting_desc" tabIndex={0} aria-controls="DataTables_Table_4" rowSpan={1} colSpan={1} aria-sort="descending" aria-label="
				Received Date
				GMT+8: activate to sort column ascending" style={{ width: '182px' }}>
                                                        <ui-translate key="fe.text.inbox.received_date" lang="en">Received Date</ui-translate>
                                                        <span>GMT+8</span></th>
                                                </tr>
                                            </thead>
                                            <tbody id="inbox_container">
                                                <tr id={68637817} className="odd" data-id={68637817} data-messagetype={2} title="Celebrate a colourful Holi!" role="row">
                                                    <td><input type="checkbox" data-id={68637817} id="inbox-68637817" />
                                                        <label htmlFor="inbox-68637817" ui-key="title">Celebrate a colourful Holi!</label></td>
                                                    <td>
                                                        <div ui-key="contentMessage" style={{ display: 'none' }}>
                                                            <p>Sky247 wishes you a Happy Holi!<br /> Enjoy this joyful season with the best Casino games at Sky247! Andar Bahar, Teen Patti&nbsp;&nbsp;&amp; much more!&nbsp;Click <a href="https://bit.ly/sky247holi22">HERE</a>                                                    to play!Happy bets with Sky247!&nbsp;</p>
                                                            <p>Sky247 की ओर से आपको होली की शुभकामनाएँ!<br /> Sky247 पर सर्वश्रेष्ठ कैसीनो खेलों के साथ इस आनंदमय त्यौहार का आनंद लें! अंदर बहार, तीन पत्ती और भी बहुत कुछ!&nbsp;खेलने के लिए&nbsp;<a href="https://bit.ly/sky247holi22">यहां</a>&nbsp;क्लिक
                                                                करें!&nbsp;Sky247 के साथ हैप्पी बेट्स!</p>
                                                        </div>
                                                        <div ui-key="showMessage" className="mail-content">Sky247 wishes you a Happy Holi! Enjoy this joyful season with the best Casino games at Sky247! Andar Bahar, Teen Patti&nbsp;&nbsp;&amp; much more!&nbsp;Click HERE to play!Happy bets with Sky247!&nbsp; Sky247
                                                            की ओर से आपको होली की शुभकामनाएँ! Sky247 पर सर्वश्रेष्ठ कैसीनो खेलों के साथ इस आनंदमय त्यौहार का आनंद लें! अंदर बहार, तीन पत्ती और भी बहुत कुछ!&nbsp;खेलने के लिए&nbsp;यहां&nbsp;क्लिक करें!&nbsp;Sky247 के
                                                            साथ हैप्पी बेट्स!
                                                        </div>
                                                    </td>
                                                    <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>2022/03/17 20:57:30</td>
                                                </tr>
                                                <tr id={51237207} className="new even" data-id={51237207} data-messagetype={2} title="T10 runs give you cash!" role="row">
                                                    <td><input type="checkbox" data-id={51237207} disabled id="inbox-51237207" />
                                                        <label htmlFor="inbox-51237207" ui-key="title">T10 runs give you cash!</label></td>
                                                    <td>
                                                        <div ui-key="contentMessage" style={{ display: 'none' }}>
                                                            <p>Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive <strong>₹5 for every run scored in Abu Dhabi T10</strong>!<br /> Check Terms and Conditions
                                                                on promotions page and get your chance!<br /> Happy Bets with Sky247!</p>
                                                        </div>
                                                        <div ui-key="showMessage" className="mail-content">Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive ₹5 for every run scored in Abu Dhabi T10! Check Terms and Conditions on promotions page and
                                                            get your chance! Happy Bets with Sky247!
                                                        </div>
                                                    </td>
                                                    <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>2021/11/19 00:45:47</td>
                                                </tr>
                                                <tr id={51168892} className="odd" data-id={51168892} data-messagetype={2} title="T10 runs give you cash!" role="row">
                                                    <td><input type="checkbox" data-id={51168892} id="inbox-51168892" />
                                                        <label htmlFor="inbox-51168892" ui-key="title">T10 runs give you cash!</label></td>
                                                    <td>
                                                        <div ui-key="contentMessage" style={{ display: 'none' }}>
                                                            <p>Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive <strong>₹5 for every run scored in Abu Dhabi T10</strong>!<br /> Check <a href="http://www.sky247.com/page/guest/promotions.jsp?utm_source=inbox&utm_medium=inbox_sky&utm_campaign=inbox_sky_t10">Terms and Conditions</a>                                                    on promotions page and get your chance!<br /> Happy Bets with Sky247!</p>
                                                        </div>
                                                        <div ui-key="showMessage" className="mail-content">Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive ₹5 for every run scored in Abu Dhabi T10! Check Terms and Conditions on promotions page and
                                                            get your chance! Happy Bets with Sky247!
                                                        </div>
                                                    </td>
                                                    <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>2021/11/19 00:43:18</td>
                                                </tr>
                                                <tr id={51064766} className="new even" data-id={51064766} data-messagetype={2} title="Premium cricket maintenance" role="row">
                                                    <td><input type="checkbox" data-id={51064766} disabled id="inbox-51064766" />
                                                        <label htmlFor="inbox-51064766" ui-key="title">Premium cricket maintenance</label></td>
                                                    <td>
                                                        <div ui-key="contentMessage" style={{ display: 'none' }}>
                                                            <p style={{
                                                                boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow:
                                                                    '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)', margin: '0px', color: 'rgba(9, 30, 66, 0.87)', fontFamily:
                                                                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", Cantarell, sans-serif', fontSize: '14px', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'left', textIndent: '0px', textTransform:
                                                                    'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', backgroundColor: 'rgb(255, 255, 255)', textDecorationThickness: 'initial', textDecorationStyle: 'initial', textDecorationColor:
                                                                    'initial'
                                                            }}>Priya grahak<br style={{
                                                                boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor:
                                                                    'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)'
                                                            }} />
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} /> Kripya dhyan de ki SKY247 premium cricket sevaye aaj 18.11 ko server maintenance ke tahat raat 09 baje se 11 baje tak seva me uplabadh nahi
                                                                hogi. Agar maintenance ke tahat bhi aap seva ka upyog nahi kar pa rahe he to apne browser se cache and cookies ko clear karien.</p>
                                                            <p style={{
                                                                boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                    '0 0 rgba(0,0,0,0)', margin: '0px', color: 'rgba(9, 30, 66, 0.87)', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", Cantarell, sans-serif', fontSize: '14px',
                                                                fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'left', textIndent: '0px', textTransform: 'none', whiteSpace:
                                                                    'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', backgroundColor: 'rgb(255, 255, 255)', textDecorationThickness: 'initial', textDecorationStyle: 'initial', textDecorationColor: 'initial'
                                                            }}><br style={{
                                                                boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow:
                                                                    '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)'
                                                            }} /> Aapki samaj ke liye dhanyavaad aur apka din subh ho.<br style={{
                                                                boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )',
                                                                twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)'
                                                            }} />
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} /> --
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} />
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} /> Valued Players,<br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth:
                                                                        '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)'
                                                                }} />
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} /> Please note that Sky247 premium cricket services is today - 18/11 - under server maintenance from 21:00 to 23:00 IST. During this period premium
                                                                cricket is not accessible. Please clear cache and cookies after the maintenance if you still can't access the referred product.<br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset:
                                                                        'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow: '0 0 rgba(0,0,0,0)'
                                                                }} />
                                                                <br style={{
                                                                    boxSizing: 'border-box', twShadow: '0 0 rgba(0,0,0,0)', twRingInset: 'var(--tw-empty, )', twRingOffsetWidth: '0px', twRingOffsetColor: '#fff', twRingColor: 'rgba(59, 130, 246, 0.5)', twRingOffsetShadow: '0 0 rgba(0,0,0,0)', twRingShadow:
                                                                        '0 0 rgba(0,0,0,0)'
                                                                }} /> Thank you for your understanding and have a great day ahead.</p>
                                                        </div>
                                                        <div ui-key="showMessage" className="mail-content">Priya grahak Kripya dhyan de ki SKY247 premium cricket sevaye aaj 18.11 ko server maintenance ke tahat raat 09 baje se 11 baje tak seva me uplabadh nahi hogi. Agar maintenance ke tahat bhi aap seva ka upyog
                                                            nahi kar pa rahe he to apne browser se cache and cookies ko clear karien. Aapki samaj ke liye dhanyavaad aur apka din subh ho. -- Valued Players, Please note that Sky247 premium cricket services is today
                                                            - 18/11 - under server maintenance from 21:00 to 23:00 IST. During this period premium cricket is not accessible. Please clear cache and cookies after the maintenance if you still can't access the referred
                                                            product. Thank you for your understanding and have a great day ahead.
                                                        </div>
                                                    </td>
                                                    <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>2021/11/18 22:01:38</td>
                                                </tr>
                                                <tr id={44591249} className="new odd" data-id={44591249} data-messagetype={2} title="Still ₹1,3 million in prizes at Sky247 IPL Lottery!" role="row">
                                                    <td><input type="checkbox" data-id={44591249} disabled id="inbox-44591249" />
                                                        <label htmlFor="inbox-44591249" ui-key="title">Still ₹1,3 million in prizes at Sky247 IPL Lottery!</label></td>
                                                    <td>
                                                        <div ui-key="contentMessage" style={{ display: 'none' }}>
                                                            <p>Get your ticket for each of the next 2 weekly draws and be one of the lucky winners!</p>
                                                            <p><a href="https://www.sky247.net/campaigns/sky247-ipl-lottery.html?utm_source=inbox&utm_medium=inbox_sky&utm_campaign=inbox_sky_ipllotterywinners0710" target="_blank">IPL Lottery Winners</a></p>
                                                        </div>
                                                        <div ui-key="showMessage" className="mail-content">Get your ticket for each of the next 2 weekly draws and be one of the lucky winners! IPL Lottery Winners
                                                        </div>
                                                    </td>
                                                    <td className="column-time sorting_1" ui-key="createTime" style={{ backgroundColor: 'unset' }}>2021/10/07 06:22:07</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="dataTables_info" id="DataTables_Table_4_info" role="status" aria-live="polite">Showing 1 to 5 of 5 entries</div>
                                        <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_4_paginate" style={{ opacity: 1 }}><a className="paginate_button previous disabled" aria-controls="DataTables_Table_4" data-dt-idx={0} tabIndex={-1} id="DataTables_Table_4_previous">Previous</a><span><a className="paginate_button current" aria-controls="DataTables_Table_4" data-dt-idx={1} tabIndex={0}>1</a></span>
                                            <a
                                                className="paginate_button next disabled" aria-controls="DataTables_Table_4" data-dt-idx={2} tabIndex={-1} id="DataTables_Table_4_next">Next</a>
                                        </div>
                                    </div>
                                    <div className="table-button-box">
                                        <div className="need-edit-mail active">
                                            <button className="btn-player-secendary btn-5th-xs">
                                                <ui-translate key="fe.text.inbox.edit" lang="en">Edit</ui-translate>
                                            </button>
                                        </div>
                                        <div className="edit-mail" style={{ display: 'none' }}>
                                            <button className="btn-player-secendary btn-2nd-xs choose-delete">
                                                <ui-translate key="fe.text.inbox.delete" lang="en">Delete</ui-translate>
                                            </button>
                                            <button className="btn-player-secendary btn-gray-xs edit-cancel">
                                                <ui-translate key="fe.text.inbox.exit" lang="en">Exit</ui-translate>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <div style={{ display: 'none' }}>
                                    <table>
                                        {/* TODO: only un-read inbox message need class 'new' */}
                                        <tbody>
                                            <tr id="inbox_template" className="new" data-id data-messagetype>
                                                <td>
                                                    <input type="checkbox" data-id disabled id="temp" />
                                                    <label htmlFor="temp" ui-key="title">-</label>
                                                </td>
                                                <td>
                                                    <div ui-key="contentMessage" style={{ display: 'none' }}>-</div>
                                                    <div ui-key="showMessage" className="mail-content">-</div>
                                                </td>
                                                <td className="column-time" ui-key="createTime" style={{ backgroundColor: 'unset' }}>yyyy/MM/dd hh:mm:ss</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

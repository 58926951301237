import React from 'react'
import valueno from "../no-value (1).svg"
import LocalBank from "./icon-bank (1).png"
import Phonepe from "./phonepe.png"
import GPay from "./gpay.png"
import PAYTM from "./paytm.png"
import companyBank from "./image (8).png"

import CRYPTO from "./cryptoswitch.png"
export const Wallet = () => {
    return (
        <React.Fragment>
                    <div id="mainContentBody" className="replaceable-block">
                            <style dangerouslySetInnerHTML={{ __html: "\n  .submit-money-group input.error {\n    margin-left: 0px;\n  }\n\n  #content_bankType li.active {\n\t  display: block;\n  }\n\n  #content_bankType li {\n\t  display: none;\n  }\n" }} />
                            <main className="container-player-wrapper">
                                <form id="depositForm" noValidate="novalidate">
                                    <div className="container-player-group">
                                        <div className="player-title-box"><h2>
                                            <ui-translate key="fe.text.profile.moneyManagement" lang="en">Funds</ui-translate></h2>
                                            <div className="tab-primary-box"><ul><li>
                                                <a data-toggle="tab" data-pagetype={1}>
                                                    <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></a></li><li>
                                                    <a data-toggle="tab" data-pagetype={2}>
                                                        <ui-translate key="fe.text.withdrawal" lang="en">Withdrawal</ui-translate></a></li></ul>
                                                <div className="slider-tab" style={{ width: '160px', left: '0px' }} />
                                            </div></div>
                                        <div id="content_deposit" className="main-tab-content">
                                         {/*deposit*/}

                                            <section className="tab-pane deposit active">
                                                <section className="deposit-discount-group"><div className="title-box"><h3>
                                                    <ui-translate key="fe.text.promotions" lang="en">Promotions</ui-translate></h3>
                                                    <div className="deposit-discount-select">Promotion&nbsp;
                                                        <span>2</span></div></div><div style={{ display: 'none' }}>
                                                        <select id="bonusId">
                                                            <option value={6163} data-id={6163} data-banktypeid="1,2,4,8,16,32,64,128,32768,33554432,33,34" data-min={1} data-max={1000001} data-bonusamount="X">No_Bonus </option>
                                                            <option value={7421} data-id={7421} data-banktypeid="1,32768,33554432,33,34" data-min={247} data-max={1000000} data-bonusamount="21 <span>%</span>">Fancy Bonus 2022 </option></select>
                                                    </div>
                                                    <div className="all-coupon-select-box" style={{ display: 'none' }}>
                                                        <ul id="bonusSelectBlockContainer" className="col-5">
                                                            <li id={6163} title="No_Bonus">
                                                                <input type="radio" id="id-6163" data-pk={6163} name="bonusBlockSelect" />
                                                                <div className="couponimg">
                                                                    <div className="bg-load pre-load data-image" data-image="/upload/promotion/image_3061.jpg" data-preload style={{ backgroundImage: 'url("/upload/promotion/image_3061.jpg")' }}>
                                                                        <label className="status-check" htmlFor="id-6163" />
                                                                        <div className="status-more promotion-detail-btn" data-messagetype={3} data-id={6163} /></div></div></li>
                                                            <li id={7421} title="Fancy Bonus 2022">
                                                                <input type="radio" id="id-7421" data-pk={7421} name="bonusBlockSelect" />
                                                                <div className="couponimg">
                                                                    <div className="bg-load pre-load data-image" data-image="/upload/promotion/image_6441.jpg" data-preload style={{ backgroundImage: 'url("/upload/promotion/image_6441.jpg")' }}>
                                                                        <label className="status-check" htmlFor="id-7421" />
                                                                        <div className="status-more promotion-detail-btn" data-messagetype={3} data-id={7421} />
                                                                    </div></div></li></ul></div>
                                                    <div id="selectedBonusTemplateContainer" className="coupon-group">
                                                        <div className="coupon-banner bg-load pre-load data-image" data-image="/upload/promotion/image_3061.jpg" data-preload="/images/promotion/promotion-banner1.jpg" style={{ backgroundImage: 'url("/upload/promotion/image_3061.jpg")' }} />
                                                        <div className="coupon-cont">
                                                            <label><ui-translate key="fe.text.deposit.promo" lang="en">Promotion</ui-translate></label>
                                                            <div className="coupon-tit"><strong>No_Bonus</strong></div>
                                                            <p>Happy Bets with Sky247 !</p><time>2019/01/01 00:00:00 to 2099/12/31 23:59:59 end</time>
                                                            <button className="promotion-detail-btn" data-messagetype={3} data-id={6163} onclick="return false;">
                                                                <ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></button></div></div>
                                                </section><section>
                                                    <div className="title-box">
                                                        <h3>
                                                            <ui-translate key="fe.text.deposit.paymentMethod" lang="en">Payment Method</ui-translate></h3></div>
                                                    <ul id="content_bankType" className="choose-box col-5">
                                                        <li data-maintain="false" className="active">
                                                            <input type="radio" name="bankType" id="bankType_Local Bank" data-banktype={1} data-maintain="false" />
                                                            <label htmlFor="bankType_Local Bank">
                                                                <div className="pre-load">
                                                                    <img className="img-load" data-preload="/images/thirdparty/icon-bank.png" src={LocalBank} /></div><p>Local Bank</p></label>
                                                            <div className="tag-recommond" /></li><li data-maintain="false" className="active">
                                                            <input type="radio" name="bankType" id="bankType_PhonePe" data-banktype={32768} data-maintain="false" />
                                                            <label htmlFor="bankType_PhonePe">
                                                                <div className="pre-load">
                                                                    <img className="img-load" data-preload="/images/thirdparty/icon-bank.png" src={Phonepe} /></div>
                                                                <p>PhonePe</p></label>
                                                            <div className="tag-recommond" /></li>
                                                        <li data-maintain="false" className="active">
                                                            <input type="radio" name="bankType" id="bankType_GPay" data-banktype={33} data-maintain="false" />
                                                            <label htmlFor="bankType_GPay">
                                                                <div className="pre-load">
                                                                    <img className="img-load" data-preload="/images/thirdparty/icon-bank.png" src={GPay} /></div>
                                                                <p>GPay</p></label>
                                                            <div className="tag-recommond" /></li>
                                                        <li data-maintain="false" className="active">
                                                        <input type="radio" name="bankType" id="bankType_PAYTM" data-banktype={34} data-maintain="false" />
                                                        <label htmlFor="bankType_PAYTM">
                                                        <div className="pre-load">
                                                        <img className="img-load" data-preload="/images/thirdparty/icon-bank.png" src={PAYTM} />
                                                        </div><p>PAYTM</p></label>
                                                        <div className="tag-recommond" /></li>
                                                        <li data-maintain="false" className="active">
                                                        <input type="radio" name="bankType" id="bankType_CRYPTO" data-banktype={33554432} data-maintain="false" />
                                                        <label htmlFor="bankType_CRYPTO">
                                                        <div className="pre-load">
                                                        <img className="img-load" data-preload="/images/thirdparty/icon-bank.png" src={CRYPTO} /></div><p>CRYPTO</p></label>
                                                        <div className="tag-recommond" /></li></ul></section>
                                                        <section className="upiBlock">
                                                        <div className="title-box"><h3>
                                                        <ui-translate key="fe.text.deposit.payment_type" lang="en">Payment Type</ui-translate></h3></div>
                                                        <div id="content_paymentType" className="tab-secondary-box">
                                                        <ul className="tabs"><li className="active">
                                                        <input type="radio" name="paymentType" id="transferType_1" data-paymenttype={0} data-paymentbtntype={0} defaultValue={0} data-transfertype={1} style={{display: 'none'}}/>
                                                        <label htmlFor="transferType_1"><p>IMPS</p></label></li>
                                                        <li><input type="radio" name="paymentType" id="transferType_2" data-paymenttype={0} data-paymentbtntype={0} defaultValue={0} data-transfertype={2} style={{display: 'none'}}/>
                                                        <label htmlFor="transferType_2"><p>RTGS</p></label></li>
                                                        <li><input type="radio" name="paymentType" id="transferType_3" data-paymenttype={0} data-paymentbtntype={0} defaultValue={0} data-transfertype={3} style={{display: 'none'}}/>
                                                        <label htmlFor="transferType_3"><p>NEFT</p></label></li>
                                                        <li><input type="radio" name="paymentType" id="paymentType_52" data-paymenttype={52} data-paymentbtntype={2} defaultValue={52} style={{display: 'none'}}/>
                                                        <label htmlFor="paymentType_52"><p>Payment Gateway (Instant)</p></label></li>
                                                        <li>
                                                        <input type="radio" name="paymentType" id="paymentType_105" data-paymenttype={105} data-paymentbtntype={0} defaultValue={105} style={{display: 'none'}}/>
                                                        <label htmlFor="paymentType_105">
                                                        <p>UPI Manual</p></label>
                                                        </li></ul></div>
                                                        <div className="tab-container">
                                                        <ul className="tab-content">
                                                        <li className="active">
                                                        <section id="content_depositChannel">
                                                        <div className="title-box">
                                                        <h4><ui-translate key="fe.text.deposit.channel_desc" lang="en">Deposit Channel</ui-translate></h4></div>
                                                        <div className="deposit-pay-box">
                                                        <ul className="choose-box col-5">
                                                        <li data-maintain="false">
                                                        <input type="radio" name="depositSetting" id="depositSetting_1965" data-accountid={1965}data-min={247} data-max={247000} data-maintain="false" data-imageno="null" data-banktype={1} data-transfertype={1}/>
                                                                <label htmlFor="depositSetting_1965"><p>BankTransfer </p></label></li><li data-maintain="false">
                                                                <input type="radio" name="depositSetting" id="depositSetting_1966" data-accountid={1966}data-min={247000} data-max={1000000} data-maintain="false" data-imageno="null" data-banktype={1} data-transfertype={1}/>
                                                                <label htmlFor="depositSetting_1966"><p>BigDeposits </p></label></li></ul></div>
                                                                </section>
                                                                <section style={{display: 'none'}}>
                                                                <div className="title-box"><h4>
                                                                <ui-translate key="fe.text.deposit.select_bank" lang="en">Select Bank</ui-translate></h4></div>
                                                                <div id="content_bankList" className="deposit-pay-box" /></section>
                                                                <section id="localBankCard" style={{display: 'none'}}>
                                                                <div className="title-box"><h4>
                                                                <ui-translate key="ui.text.deposit.account_bank_info" lang="en">Deposit Info</ui-translate></h4>
                                                                <div id="bankInfoToggle" className="btn-player-secendary btn-5th-xs deposit-info-open" style={{display: 'none'}}>
                                                                <ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate>
                                                                </div></div>
                                                                <div id="depositBankInfoContainer" className="pay-info-group-box" style={{}}>
                                                                <div className="pay-info-group">
                                                                <div className="bank-card card-blue">
                                                                <div className="bank-name">
                                                                <div className="bank-info-copy">
                                                                <p id="localBankDetail_bankName">-</p>
                                                                <div className="tips-txt"><ui-translate key="ui.text.copy" lang="en">Copy</ui-translate></div></div>
                                                                <div className="bank-info-copy"><span id="localBankDetail_branch">-</span>
                                                                <div className="tips-txt"><ui-translate key="ui.text.copy" lang="en">Copy</ui-translate></div></div></div>
                                                                <div className="bank-account bank-info-copy"><p id="localBankDetail_accountNo">-</p>
                                                                <div className="tips-txt"><ui-translate key="ui.text.copy" lang="en">Copy</ui-translate></div></div>
                                                                <div className="bank-ifsc bank-info-copy"><span id="localBankDetail_financeCode">-</span>
                                                                <div className="tips-txt"><ui-translate key="ui.text.copy" lang="en">Copy</ui-translate></div></div>
                                                                <div className="bank-member bank-info-copy"><p id="localBankDetail_accountName">-</p>
                                                                <div className="tips-txt"><ui-translate key="ui.text.copy" lang="en">Copy</ui-translate></div></div></div>
                                                                <span id="orScanQrCode" style={{display: 'none'}}>Or</span>
                                                                <div id="localBank_qrCodeContainer" className="qr-code-box" style={{display: 'none'}}>
                                                                <img id="localBank_qrCode" className="deposit-qrcode" />
                                                                <div className="qr-code-txt"><p>scan QR code</p></div></div></div>
                                                                <div id="depositReceiptBox" className="container-player-group deposit-receipt-box" style={{display: 'none'}}>
                                                                <div className="player-title-box"><h5>
                                                                <ui-translate key="fe.text.deposit.upload_receipt" lang="en">Upload receipt</ui-translate></h5></div>
                                                                <div className="deposit-receipt-cont"><div className="deposit-receipt-form">
                                                                <div className="input-box"><label>
                                                                <ui-translate key="fe.text.wallet.deposit.bank_account_name" lang="en">Your Account Name</ui-translate>
                                                                </label><input type="text" id="fromBankAccName" name="fromBankAccName" aria-required="true" />
                                                                <span className="error" style={{display: 'none'}}>-</span>
                                                                </div>
                                                                <div className="input-box-50">
                                                                <label><ui-translate key="fe.text.deposit.reference_no" lang="en">Reference No. / Trans ID</ui-translate></label>
                                                                <input type="text" id="referenceNo" name="referenceNo" aria-required="true" />
                                                                <div className="error" style={{display: 'none'}}>-</div>
                                                                </div>
                                                                <div id="bankAccNumberOrPhoneContainer" className="input-box-50" style={{display: 'none'}}>
                                                                <label>
                                                                <ui-translate key="fe.text.deposit.bankAccNumberOrPhone" lang="en">Bank Acc No / Cash Out No.</ui-translate></label>
                                                                <input type="text" name="fromBankAccNo" id="fromBankAccNo" /></div></div>
                                                                <div className="deposit-receipt-upload"><label>
                                                                <ui-translate key="fe.text.deposit.upload_receipt" lang="en">Upload receipt</ui-translate></label>
                                                                <div className="upload-box"><i id="clearUploadImages" className="icon-close" /><input type="file" id="uploadImages" name="uploadImages" accept="image/*" required aria-required="true" /><label className="btn-5th-xs" id="img_load" htmlFor="uploadImages"><ui-translate key="fe.text.deposit.upload_receipt" lang="en">Upload receipt</ui-translate></label>
                                                                <img id="previewImage" className="thumb-image" /></div></div></div>
                                                                </div></div></section>
                                                                <section id="paymentInfoContainer" style={{display: 'block'}}>
                                                                <div className="title-box">
                                                                <h4><ui-translate key="ui.text.deposit.account_bank_info" lang="en">Deposit Info</ui-translate></h4>
                                                                </div><div className="deposit-info-box">
                                                                <ul id="paymentInfoDetail">
                                                                <li><h4>
                                                                <ui-translate key="fe.text.bank.bankAccountName" lang="en">Bank Account Name</ui-translate></h4>
                                                                <p id="paymentAccName">Jaikund Fashion Designer</p>
                                                                <div className="acc-copy" /><div className="icon-copy">COPY</div></li>
                                                                <li><h4><ui-translate key="fe.text.wallet.deposit.bank_account_number" lang="en">Account Number</ui-translate></h4>
                                                                <p id="paymentAccNo">098663300002592</p><div className="acc-copy" />
                                                                <div className="icon-copy">COPY</div></li></ul>
                                                                <li id="paymentFinanceCode"><h4>
                                                                <ui-translate key="fe.text.bank.financeCode" lang="en">SWIFT / IFSC</ui-translate></h4>
                                                                <p id="paymentFinanceCode">YESB0000162</p>
                                                                <div className="acc-copy" /><div className="icon-copy">COPY</div></li></div></section>
                                                                <section id="paymentPlayerInfo" style={{display: 'block'}}>
                                                                <div className="title-box">
                                                                <h4>UTR / Reference No. / Trans ID <span>Please check the image below to insert the correct Ref ID/UTR</span></h4></div>
                                                                <div className="deposit-note-box">
                                                                <div className="left-upiInfo">
                                                                <div className="input-box">
                                                                <input type="text" name="referenceNo" id="referenceNoValid" aria-required="true" />
                                                                <div className="error" style={{display: 'none'}}>-</div></div>
                                                                <div className="descriptionBlock" style={{display: 'block'}}>
                                                                <p id="transferNote">Please fill the reference number with a 12 digits number. To guide you there is an example below.</p></div></div>
                                                                <div className="right-upiInfo">
                                                                <div className="imgBlock" style={{display: 'block'}}>
                                                                <img id="transferImage" src={companyBank} /></div>
                                                                </div></div></section>
                                                                <section className="crypto-box" id="content_cryptocurrencyAmountBox" style={{display: 'none'}}/>
                                                                </li></ul>
                                                                <section className="save-money-box" id="content_fiatCurrencyAmountBox">
                                                                <div className="title-box"><h4>
                                                                <ui-translate key="fe.text.amount" lang="en">Amount</ui-translate></h4>
                                                                <span id="tipsMinMax">₹ 247 - ₹ 247,
                                                                        000</span></div>
                                                                        <div id="content_inputAmount" className="add-money-box active">
                                                                        <ul className="choose-box col-5">
                                                                        <li className="style-addAmount">
                                                                            <p data-amount={247}>
                                                                                <span>+</span>247 </p>
                                                                        </li><li className="style-addAmount">
                                                                                <p data-amount={500}>
                                                                                    <span>+</span>500 </p></li>
                                                                            <li className="style-addAmount">
                                                                                <p data-amount={1000}>
                                                                                    <span>+</span>1,000 </p></li>
                                                                            <li className="style-addAmount">
                                                                                <p data-amount={5000}>
                                                                                    <span>+</span>5,000 </p></li>
                                                                            <li className="style-addAmount"><p data-amount={10000}>
                                                                                <span>+</span>10,000 </p></li></ul></div>
                                                            <div className="tips-group">
                                                                <p>Gentle reminder: Please ensure amount to deposit is the same as transfered amount. Sky247 will not be liable for missing funds due to incorrect information. </p></div>
                                                            <div className="submit-money-group">
                                                                <div className="input-box input-money">
                                                                    <span className="currency">₹</span>
                                                                    <input id="depositAmount" name="depositAmount" type="number" style={{ marginTop: '0px' }} onkeydown="return event.keyCode !== 69" aria-required="true" placeholder={0.00} />
                                                                    <input className="reset-btn" type="reset" style={{ top: '1.25rem' }} />
                                                                </div><button className="btn-default button submitPayment disable" disabled>
                                                                    <span className="submit">
                                                                        <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
                                                                    <span className="loading">
                                                                        <div className="lds-rolling">
                                                                            <div className="animation" /></div></span>
                                                                    <span className="check">
                                                                        <span className="icon" />
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </section>
                                            </section>
                                        </div>
                                    </div>
                                </form>
                            </main>
                            <div className="guild-list-wrapper">
                                <aside className="record-aside-box record-box">
                                    <div className="list-aside deposit-aside active">
                                        <h4>
                                            <ui-translate key="fe.text.deposit_records" lang="en">Deposit Records</ui-translate></h4>
                                        <div className="player-guild-cont" style={{ display: 'none' }}>
                                            <ul id="depositRecordContainer" className="player-list" /></div>
                                        <a className="link-more" data-pagetype={5}>
                                            <ui-translate key="fe.text.more" lang="en">More</ui-translate></a>
                                        <div className="empty-cont" style={{}}>
                                            <img src={valueno} />
                                            <p>
                                                <ui-translate key="fe.text.noData" lang="en">No Data</ui-translate>
                                            </p>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div style={{ display: 'none' }}>
                                <ul>
                                    <li id="bonusSelectBlockTemplate"><input type="radio" id="data-pk" data-pk name="bonusBlockSelect" /><div className="couponimg"><div className="bg-load pre-load data-image" data-image data-preload style={{ backgroundImage: 'url("")' }}>
                                        <label className="status-check" htmlFor="data-pk" />
                                        <div className="status-more promotion-detail-btn" data-messagetype={3} data-id />
                                    </div>
                                    </div>
                                    </li></ul></div>
                                    </div>
                
        </React.Fragment>
    )
}

import React from 'react'

export const Tennismatches = () => {
    return (
        <React.Fragment>
            <ul class="slip-head">
                <li class="col-game" style={{width: 'calc(64% - 20)'}}></li>
                <li class="col-visit">1</li>
                <li class="col-draw">x</li>
                <li class="col-home">2</li>
                <li class="col-info">

                </li>
            </ul>
            <div>
                <dl className="game-list-col  " style={{ cursor: 'pointer' }}>
                <dt>
                <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                <a href="/tennisfullmarket">A Bogdan v Berf Cengiz</a>
                <span className="game-list-time  ">5:50 PM</span>
                <span class="in_play">In-Play</span>
                        <span class="game-live" id="streamingIcon" style={{display: 'inline-flex'}}>Live</span></dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">1.13</a>
                        <a id="lay1_0" className="btn-lay ">1.15</a></dd>
                    <dd className="col-draw">
                    <a id="btnBack" className="btn-back ">--</a>
                        <a id="btnLay" className="btn-lay ">--</a></dd>
                    <dd className="col-home">
                    <a id="back2_0" className="btn-back ">7.4</a>
                        <a id="lay2_0" className="btn-lay ">8.8</a></dd>
                    <dd className="col-info">
                    <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a>
                    </dd>
                </dl>
                <dl className="game-list-col" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" /><a href="/exchange/member/fullmarket/2/31387689/1.197888331">Vekic v Mari Melnikova</a><span className="game-list-time  ">5:50 PM</span></dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">1.48</a><a id="lay1_0" className="btn-lay ">1.54</a></dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">--</a><a id="btnLay" className="btn-lay ">--</a></dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">2.84</a><a id="lay2_0" className="btn-lay ">3.1</a></dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}>
                    <dt>
                        <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" />
                        <a href="/exchange/member/fullmarket/2/31386316/1.197856223">Lenz v Cazaux</a>
                        <span className="game-list-time  ">Tommorow 3:00 AM</span>
                    </dt>
                    <dd
                        id="selectTemp" className="col-visit">
                        <a id="back1_0" className="btn-back ">2.52</a>
                        <a id="lay1_0" className="btn-lay ">3.15</a>
                    </dd>
                    <dd className="col-draw">
                        <a id="btnBack" className="btn-back ">--</a>
                        <a id="btnLay" className="btn-lay ">--</a></dd>
                    <dd className="col-home">
                        <a id="back2_0" className="btn-back ">1.47</a><a id="lay2_0" className="btn-lay ">1.66</a></dd>
                    <dd className="col-info">
                        <a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
                <dl className="game-list-col  disable" style={{ cursor: 'pointer' }}><dt>
                    <img id="playIcon" className="icon-no_play" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" alt="playIcon" /><a href="/exchange/member/fullmarket/2/31386094/1.197854273">Lama v Sandgren</a><span className="game-list-time  ">Tommorow 5:30 AM</span></dt>
                    <dd
                        id="selectTemp" className="col-visit"><a id="back1_0" className="btn-back ">2.04</a><a id="lay1_0" className="btn-lay ">3.05</a></dd>
                    <dd className="col-draw"><a id="btnBack" className="btn-back ">--</a><a id="btnLay" className="btn-lay ">--</a></dd>
                    <dd className="col-home"><a id="back2_0" className="btn-back ">1.49</a><a id="lay2_0" className="btn-lay ">1.97</a></dd>
                    <dd className="col-info"><a className="add-pin " title="Add to Multi Markets" style={{ cursor: 'pointer' }}>.</a></dd>
                </dl>
            </div>
        </React.Fragment>
    )
}

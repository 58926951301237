import React, { useState } from 'react'

export const Login = (props) => {
    const [User, setUser] = useState(false);
    const [Password, setPassword] = useState(false);

    const loginpage = () => {
        if (User == "demo" && Password == "1234") {
            alert("ok");
            props.CheckshowLogin(true)

        }
        else { props.CheckshowLogin(false) }

    }


    return (
        <React.Fragment>
            <header class="member">
                <div class="header-left-btn-group">
                    <div class="back-btn">
                        <a href="/"></a></div>
                    <div class="menu-btn">
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="header-title">Login</div>
            </header>
            <div className="content login-biometric-content member-content">
                <div className="logo-box data-image" style={{ backgroundImage: 'url("/images/h5/member-logo.png")' }} />
                <form id="loginForm" noValidate="novalidate">
                    <div className="member-box radius">
                        {/*input text*/}
                        <div className="inputbox">
                            <label>
                                Username
                            </label>
                            <input id="loginUsername" name="userId" type="text" className="input" placeholder="Username" aria-required="true" onChange={(e) => { setUser(e.target.value) }} />
                            <input id="resetUsername" className="clear" />
                        </div>
                        {/*input password*/}
                        <div className="inputbox password">
                            <div className="eyes" />
                            <label>
                                Password
                            </label>
                            <input id="loginPassword" name="password" type="password" minLength={6} maxLength={20} className="input" placeholder="Password" aria-required="true" onChange={(e) => { setPassword(e.target.value) }} />
                            <input id="resetPassword" className="clear hasbutton" />
                        </div>
                    </div>
                    <div className="login-info-box">
                        <div className="member-error-box">
                            <div id="validationError" className="member-error" style={{ display: 'none' }} />
                        </div>
                        <div className="forgetpassword-buttn">
                            <a href="/h5/guest/forgotPassword.jsp">
                                Forgot password?
                            </a>
                        </div>
                    </div>
                    <div id="loginButton" className="button">
                        <a onClick={() => { loginpage() }} >
                            Login
                        </a>
                    </div>
                    <p className="signup">
                        <span>Do not have an account?</span>
                        <a href="/h5/guest/register.jsp">
                            Sign Up
                        </a>
                    </p>
                </form>
                <div id="bioLogin" hidden>
                    <a className="biometric-group">
                        <div className="btn-biometric">
                            <div className="ripple" />
                        </div>
                        <span id="bioLoginText" hidden>Login to Sky247 with biometrics</span>
                        <span id="bioLoginIdentifyText" hidden>Identifying</span>
                    </a>
                </div>
            </div>

        </React.Fragment>
    )
}

import React, { useState, useEffect } from 'react'

export const Fullmarket = (props) => {
    const [fancyClick, setfancyClick] = useState(false)
    const [selectTab, setselectTab] = useState(0)

    const FuncyClickOpen = () => {
        setfancyClick(true)
    }
    const FuncyClickClose = () => {
        setfancyClick(false)
    }

    return (
        <React.Fragment>
            <div id="overWrap" className="over-wrap live-match" style={{ height: 'calc(100% - 0px)' }}>
                <div id="liveMatchGameHead" className="game-head">
                    <div id="matchTrackerWrap" className="match-tracker-wrap">

                        <ul id="liveMatchTrackerBtn" className="match-btn">
                            <li>
                                <a id="liveMultiMarketPin" name="gameHeadPin" className="btn-pin" title="Add to Multi Markets" eventtype={4} eventid={30035305} marketid="1.173506114" style={{ cursor: 'pointer' }} /></li>
                            <li>
                                <a className="btn-refresh" style={{ cursor: 'pointer' }} />
                            </li>
                        </ul>
                    </div>
                    <div id="scoresEvent" className="match-odds">Match Odds
                        <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                    </div>
                    <ul className="game-info" style={{ display: 'block' }}>
                        <li id="gameInfoDate" className>
                            <img className="icon-time" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />2022-04-17 15:30</li>
                    </ul>
                    <dl id="minMaxBox" className="fancy-info">
                        <dt id="minMaxDt" style={{ display: 'none' }}>Min/Max</dt>
                        <dt id="maxDt">Min</dt>
                        <dd id="minMaxInfo">1</dd>
                        <dt id="maxDt">Max</dt>
                        <dd id="minMaxInfo">5000</dd>
                    </dl>
                    <a id="lmtOpenStreaming" class="open-tv-wrap">Live</a>
                </div>
                <div className="kv-wrap" style={{ display: 'none' }}>
                    <img src="/images/kv/KV.jpg" alt="" />
                </div>
                <div id="marketTemplate" className="game-wrap" style={{ display: 'none' }}>
                    <h3>
                        <a id="multiMarketPin" className="add-pin" style={{ cursor: 'pointer' }} />
                        <a id="marketName" className="to-expand" style={{ cursor: 'pointer' }} /></h3>
                    <ul className="slip-head">
                        <li className="col-game" id="matched">
                            <span id="lowLiquidityTag" className="game-low_liq" style={{ display: 'none' }}>
                                Low Liquidity</span>
                            Matched:</li>
                        <li className="col-home">
                            <span>Back</span>
                            <span>Lay</span>
                        </li>
                    </ul>
                    <div id="marketGameList" className="game-list">
                        <div id="suspend" className="suspend" style={{ display: 'none' }}>
                            <span>Suspend</span>
                        </div>
                        <div id="closed" className="suspend" style={{ display: 'none' }}>
                            <span>Closed</span></div>
                        <dl id="selectionTemplate" className="game-list-col" style={{ display: 'none' }}>
                            <span id="vsName" vsinfo style={{ display: 'none' }} />
                            <dt id="runner">
                                <span id="withoutBet" className="win" style={{ display: 'none' }} />
                                <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                                <span id="withBet" className="win" style={{ display: 'none' }} />
                                <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                            </dt>
                            <dd
                                className="col-home">
                                <div id="selectionSuspend" className="suspend">
                                    <span>Suspend</span></div>
                                <a className="btn-back" >--</a>
                                <a className="btn-lay" >--</a>
                            </dd>
                        </dl>
                        <a id="moreLink" className="more" style={{ cursor: 'pointer' }}>View Full Market</a></div>
                </div>
                <div id="eventLeft" className="event-left" style={{ display: 'none' }} />
                <div id="eventRight" className="event-right" style={{ display: 'none' }} />
                <div id="marketClosedWrap" className="closed-wrap" style={{ display: 'none' }}>
                    <div className="closed-next">
                        <h2>This race has ended</h2>
                    </div>
                </div>
                <div id="fullMarketBetsWrap" className="bets-wrap" >
                    <h3 id="lineMarketName" className="line_market-name" style={{ display: 'none' }}>
                        <span>1st Innings 10 Over Line</span>
                    </h3>
                    <span id="selectionCounts" className="refer_only">2 Selections</span>
                    <table id="fullMarketBoard" className="bets " style={{}}>
                        <tbody>
                            <tr className="bet-all">
                                <td />
                                <td id="backPercent" className="refer-bet" colSpan={2}>101.7%</td>
                                <td>
                                    <a id="backAll" className="back-all">
                                        <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                        <span>Back all</span>
                                    </a>
                                </td>
                                <td>
                                    <a id="layAll" className="lay-all">
                                        <img src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                        <span>Lay all</span>
                                    </a>
                                </td>
                                <td id="layPercent" className="refer-book" colSpan={2}>97.9%</td>
                            </tr>
                            <tr id="fullSelectionTemplate" style={{ display: 'none' }}>
                                <th>
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}>
                                        <dt>
                                            <a id="marketDepthBtn" href="#">
                                                <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                            </a>
                                            <ul id="horseBox" className="horse-box">
                                                <li id="clothNumberAlpha" />
                                                <li id="stallDraw" />
                                            </ul>
                                            <div id="uniform" className="uniform">
                                                <img src />
                                            </div>
                                        </dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover">
                                                <dt>Trainer:</dt>
                                                <dd id="trainerName" />
                                                <dt>Age/Weight:</dt>
                                                <dd id="ageWeight" />
                                                <dt>Form:</dt>
                                                <dd id="form" />
                                                <dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" />
                                                <dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" />
                                                <dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p />
                                    <span id="withoutBet" className="win" style={{ display: 'none' }} />
                                    <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                                    <span id="withBet" className="win" style={{ display: 'none' }} />
                                    <span id="lossWithBet" className="win"
                                        style={{ display: 'none' }} />
                                    <span id="zeroProfit" className="win" style={{ display: 'none' }} />
                                    <span id="zeroLiability" className="win" style={{ display: 'none' }} />
                                    <span id="zeroProfitWithBet" className="win" style={{
                                        display: 'none'
                                    }} />
                                    <span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} />
                                </th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Suspend</span>
                                </td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Closed</span>
                                </td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Non Runner</span>
                                </td>
                                <td id="back_3" className="back-3" >
                                    <a><span />
                                    </a>
                                </td>
                                <td id="back_2" className="back-2" ><a><span /></a></td>
                                <td id="back_1" className="back-1" ><a><span /></a></td>
                                <td id="lay_1" className="lay-1" ><a><span /></a></td>
                                <td id="lay_2" className="lay-2" ><a><span /></a></td>
                                <td id="lay_3" className="lay-3" ><a><span /></a></td>
                            </tr>
                            <tr id="fullSelection_4164048" className="null">
                                <th className="predict">
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}>
                                        <dt>
                                            <a id="marketDepthBtn" href="#">
                                                <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                            </a>
                                            <ul id="horseBox" className="horse-box">
                                                <li id="clothNumberAlpha" />
                                                <li id="stallDraw" />
                                            </ul>
                                            <div id="uniform" className="uniform">
                                                <img src />
                                            </div>
                                        </dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                                <dd id="trainerName" />
                                                <dt>Age/Weight:</dt>
                                                <dd id="ageWeight" />
                                                <dt>Form:</dt>
                                                <dd id="form" />
                                                <dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" />
                                                <dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" />
                                                <dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p><a>
                                        <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                    </a>Kings xii Punjab</p>
                                    <span id="lossWithoutBet" className="win" style={{ display: 'none' }} />
                                    <span id="withBet" className="win" style={{ display: 'none' }} />
                                    <span id="lossWithBet" className="win" style={{ display: 'none' }} />
                                    <span id="zeroProfit" className="win" style={{ display: 'none' }} />
                                    <span id="zeroLiability" className="win" style={{ display: 'none' }} />
                                    <span id="zeroProfitWithBet" className="win" style={{ display: 'none' }} />
                                    <span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} />
                                </th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Closed</span>
                                </td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}>
                                    <span>Non Runner</span>
                                </td>
                                <td id="back_3" className="back-3 null" >
                                    <a>1.81<span>1,036</span></a></td>
                                <td id="back_2" className="back-2 null" >
                                    <a>1.82
                                        <span>
                                            593</span>
                                    </a></td>
                                <td id="back_1"  className="back-1 null null" >
                                    <a onClick={()=>{props.ClickbetOpen(true)}}>1.84
                                        <span>18,520</span>
                                    </a>
                                </td>
                                <td id="lay_1" className="lay-1 null null" >
                                    <a>1.85<span>1,056</span>
                                    </a></td>
                                <td id="lay_2" className="lay-2 null">
                                    <a>1.86<span>442</span></a></td>
                                <td id="lay_3" className="lay-3 null" >
                                    <a>1.87<span>2,571</span>
                                    </a></td>
                            </tr>
                            <tr id="fullSelection_2954266" className="null">
                                <th className="predict">
                                    <dl id="horseInfo" className="horse-info" style={{ display: 'none' }}>
                                        <dt>
                                            <a id="marketDepthBtn" href="#">
                                                <img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" />
                                            </a>
                                            <ul id="horseBox" className="horse-box">
                                                <li id="clothNumberAlpha" />
                                                <li id="stallDraw" />
                                            </ul>
                                            <div id="uniform" className="uniform">
                                                <img src />
                                            </div>
                                        </dt>
                                        <dd>
                                            <ul id="horseName" className="horse-name">
                                                <li id="runnerName" />
                                                <li id="jockyName" />
                                            </ul>
                                            <dl id="horseInfoHover" className="horse_info-hover"><dt>Trainer:</dt>
                                                <dd id="trainerName" /><dt>Age/Weight:</dt>
                                                <dd id="ageWeight" /><dt>Form:</dt>
                                                <dd id="form" /><dt>Days since last run:</dt>
                                                <dd id="daysSinceLastRun" /><dt>Jockey claim:</dt>
                                                <dd id="jockeyClam" /><dt>Wearning:</dt>
                                                <dd id="wearing" />
                                            </dl>
                                        </dd>
                                    </dl>
                                    <p><a><img className="icon-predict" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==" /></a>Sunrisers Hyderabad</p><span id="withBet" className="win" style={{ display: 'none' }} /><span id="lossWithBet"
                                        className="win" style={{ display: 'none' }} /><span id="zeroProfit" className="win" style={{ display: 'none' }} /><span id="zeroLiability" className="win" style={{ display: 'none' }} /><span id="zeroProfitWithBet" className="win"
                                            style={{ display: 'none' }} /><span id="zeroLiabilityWithBet" className="win" style={{ display: 'none' }} /></th>
                                <td id="suspend" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Suspend</span></td>
                                <td id="closed" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Closed</span></td>
                                <td id="nonRunner" colSpan={6} className="suspend" style={{ display: 'none' }}><span>Non Runner</span></td>
                                <td id="back_3" className="back-3  null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="1.96" inplay={0}><a>2.14<span>2,412</span></a></td>
                                <td id="back_2" className="back-2 null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.02" inplay={0}><a>2.16<span>380</span></a></td>
                                <td id="back_1" className="back-1 null null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.04" inplay={0}><a>2.18<span>896</span></a></td>
                                <td id="lay_1" className="lay-1 null null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.12" inplay={0}><a>2.2<span>15,490</span></a></td>
                                <td id="lay_2" className="lay-2 null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.14" inplay={0}><a>2.22<span>486</span></a></td>
                                <td id="lay_3" className="lay-3 null" selectionid={2954266} runner="Rajasthan Royals" marketname="Match Odds" markettype="MATCH_ODDS" fullmarketodds="2.16" inplay={0}><a>2.24<span>837</span></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="bookMakerWrap" className="bets-wrap  bookmaker_bet">
                    <table id="bookMakerMarketList" className="bets bets-bookmaker">
                        <colgroup>
                            <col span={1} width={280} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                        </colgroup>
                        <tbody id="bookMakerMarket_30035305_37909" style={{ display: 'table-row-group' }}>
                            <tr id="bookMakerSpecialBet" className="special_bet">
                                <td colSpan={7}>
                                    <h3><a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>Bookmaker Market
                                        <p>| Zero Commission</p>
                                    </h3>
                                    <dl className="fancy-info"><dt><span>Min</span></dt>
                                        <dd id="min">1</dd><dt><span>Max</span></dt>
                                        <dd id="max">10000</dd><dt id="rebateName" style={{ display: 'none' }}><span>Rebate</span></dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr className="bet-all">
                                <td colSpan={3} />
                                <td>Back</td>
                                <td>Lay</td>
                                <td className="refer-book" colSpan={2} />
                            </tr>
                            <tr id="bookMakerSuspend_30035305_37909_111798" className="fancy-suspend-tr" marketid={37909}>
                                <th colSpan />
                                <td className="fancy-suspend-td" colSpan={6}>
                                    <div id="suspendClass" className="fancy-suspend"><span id="info">Suspend</span></div>
                                </td>
                            </tr>
                            <tr id="bookMakerSelection_30035305_37909_111798" style={{ display: 'table-row' }}>
                                <th className="predict">
                                    <p id="runnerName">Punjab Kings</p>
                                </th>
                                <td className colSpan={3}>
                                    <dl className="back-gradient">
                                        <dd id="back_3" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="back_2" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="back_1" className="null" style={{ cursor: 'pointer' }}><a> </a></dd>
                                    </dl>
                                </td>
                                <td className colSpan={3}>
                                    <dl className="lay-gradient">
                                        <dd id="lay_1" className="null" style={{ cursor: 'pointer' }}><a> </a></dd>
                                        <dd id="lay_2" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="lay_3" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                    </dl>
                                </td>
                            </tr>
                            <tr id="bookMakerSuspend_30035305_37909_111799" className="fancy-suspend-tr" marketid={37909}>
                                <th colSpan />
                                <td className="fancy-suspend-td" colSpan={6}>
                                    <div id="suspendClass" className="fancy-suspend"><span id="info">Suspend</span></div>
                                </td>
                            </tr>
                            <tr id="bookMakerSelection_30035305_37909_111799" style={{ display: 'table-row' }}>
                                <th className="predict">
                                    <p id="runnerName">Sunrisers Hyderabad</p>
                                </th>
                                <td className colSpan={3}>
                                    <dl className="back-gradient">
                                        <dd id="back_3" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="back_2" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="back_1" className="null" style={{ cursor: 'pointer' }}><a> </a></dd>
                                    </dl>
                                </td>
                                <td className colSpan={3}>
                                    <dl className="lay-gradient">
                                        <dd id="lay_1" className="null" style={{ cursor: 'pointer' }}><a> </a></dd>
                                        <dd id="lay_2" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                        <dd id="lay_3" style={{ cursor: 'pointer' }}>
                                            <p> </p>
                                        </dd>
                                    </dl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="fancyBetPosition" style={{ display: 'none' }} />
                <div id="fancyBetTable_30035305" className="bets-wrap fancy_bet" name="multiMarketItem">
                    <div id="fancyBetHead" class="fancy-head">
                        <h4 class="in-play">

                            <span id="headerName">Fancy Bet</span>


                            <a class="btn-fancybet_rules" ></a>

                        </h4>
                        <a id="showSportsBookBtn" class="other-tab" >
                            <span class="tag-new">New</span>
                            Premium Cricket
                        </a>
                    </div>
                    <div id="fancyBetTabWrap" className="fancy_bet_tab-wrap" style={{}}>
                        <ul className="special_bets-tab">
                            <li  onClick={()=>{setselectTab(0)}} className={`${(selectTab==0) ? "select" : ""}`}>
                            <a fancybettabtype={1}>All</a></li>
                            <li onClick={()=>{setselectTab(1)}} className={`${(selectTab ==1) ? "select" : ""}`}>
                            <a fancybettabtype={2}>Fancy</a></li>
                            <li onClick={()=>{setselectTab(2)}} className={`${(selectTab ==2) ? "select" : ""}`}>
                            <a fancybettabtype={6}>Ball by Ball</a></li>
                            <li onClick={()=>{setselectTab(3)}} className={`${(selectTab ==3) ? "select" : ""}`}>
                            <a fancybettabtype={3}>Khadda</a></li>
                            <li onClick={()=>{setselectTab(4)}} className={`${(selectTab ==4) ? "select" : ""}`}>
                            <a fancybettabtype={4}>Lottery</a></li>
                            <li onClick={()=>{setselectTab(5)}} className={`${(selectTab ==5) ? "select" : ""}`}>
                            <a fancybettabtype={5}>Odd/Even</a></li>
                        </ul>
                    </div>
                    <p id="fancyBetNoBet" className="no_bet" style={{ display: 'none' }}>has no markets</p>
                    <table id="fancyBetMarketList" className="bets">
                        <colgroup>
                            <col span={1} width={280} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                            <col span={1} width={70} />
                        </colgroup>
                        <tbody>
                            <tr id="fancyBetSpecialBet" className="special_bet">
                                <td colSpan={7}>
                                    <h3>
                                    <a id="multiMarketPin" className="add-pin" title="Add to Multi Markets">Add Pin</a>Fancy Bet</h3>
                                </td>
                            </tr>
                            <tr className="bet-all">
                                <td />
                                <td className="refer-bet" colSpan={2} />
                                <td>No</td>
                                <td>Yes</td>
                                <td className="refer-book" colSpan={2} />
                            </tr>
                            <tr id="suspendTemplate" className="fancy-suspend-tr" style={{ display: 'none' }}>
                                <th />
                                <td colSpan={2} />
                                <td className="fancy-suspend-td" colSpan={2}>
                                    <div id="suspendClass" className="fancy-suspend">
                                    <span id="info">Ball Running</span></div>
                                </td>
                                <td colSpan={2} />
                            </tr>
                            <tr id="delayTemplate" className="fancy-suspend-tr" style={{ display: 'none' }}>
                                <th />
                                <td colSpan={2} />
                                <td className="fancy-suspend-td" colSpan={2}>
                                    <div className="fancy-suspend">
                                    <span id="info">Delay Betting</span></div>
                                </td>
                                <td colSpan={2} />
                            </tr>
                            <tr id="fancyBetMarketTemplate" style={{ display: 'none' }}>
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout">
                                    <dt>
                                    <p id="marketName" />
                                    <span id="before" className="win" style={{ display: 'none' }} />
                                    <span id="after" className="to-lose" style={{ display: 'none' }} /></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1">
                                    <a id="runsInfo" style={{ cursor: 'pointer' }}><span /></a></td>
                                <td id="back_1" className="back-1">
                                    <a id="runsInfo" style={{ cursor: 'pointer' }}><span /></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info">
                                    <dt>Min/Max</dt>
                                        <dd id="minMax" />
                                    </dl>
                                    <dl className="fancy-info">
                                    <dt id="rebateName">Rebate</dt>
                                        <dd id="rebate" />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="suspend_168674" className="fancy-suspend-tr" style={{ display: 'none' }}>
                                <th />
                                <td colSpan={2} />
                                <td className="fancy-suspend-td" colSpan={2}>
                                    <div id="suspendClass" className>
                                        <span id="info">Ball Running</span>
                                    </div>
                                </td>
                                <td colSpan={2} />
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">PK Will Win The Toss Bhav</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1"  className={`${fancyClick ? "lay-1 select" : "lay-1 null"}`}
>
                                <a id="runsInfo" onClick={()=>{FuncyClickOpen(true)}} style={{ cursor: 'pointer' }}>1<span>110</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>1<span>90</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info">
                                    <dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            {fancyClick === true && <tr id="fancyBetBoard_31285022_493987_2" class="fancy-quick-tr" style={{ display: 'table-row' }}>
                                <td colspan="7">
                                    <dl id="classWrap" class="quick_bet-wrap slip-lay">
                                        <dt id="fancyBetHeader" class="">

                                            <span id="fancyBetAcceptCheck" class="bet-check">
                                                <input id="fancyBetAcceptAnyOdds" type="checkbox" />
                                                <label style={{marginLeft:"3px"}} for="fancyBetAcceptAnyOdds">Accept Any Odds</label>
                                            </span>

                                        </dt>
                                        <dd class="col-btn">
                                            <a id="cancel" onClick={()=>{FuncyClickClose(false)}} class="btn" style={{ cursor: 'pointer' }}>Cancel</a></dd>
                                        <dd id="oddsHeader" class="col-odd">
                                            <ul class="quick-bet-confirm">
                                                <li id="runs">113</li>
                                                <li id="odds" class="quick-bet-confirm-title">100</li>
                                            </ul>
                                        </dd>
                                        <dd class="col-stake">
                                            <input id="inputStake" type="text" value="" />
                                        </dd>
                                        <dd class="col-send">
                                            <a id="placeBet" class="btn-send" style={{ cursor: 'pointer' }}>Place Bets</a>
                                        </dd><dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="extended" style={{ display: 'block' }} fancybetboard="fancyBetBoard_31285022_493987_2">
                                            <ul>
                                                <li>
                                                    <a class="btn" id="selectStake_1" stake="10" style={{ cursor: 'pointer' }}>10</a></li>
                                                <li><a class="btn" id="selectStake_2" stake="30" style={{ cursor: 'pointer' }}>30</a></li>
                                                <li><a class="btn" id="selectStake_3" stake="50" style={{ cursor: 'pointer' }}>50</a></li>
                                                <li><a class="btn" id="selectStake_4" stake="200" style={{ cursor: 'pointer' }}>200</a></li>
                                                <li><a class="btn" id="selectStake_5" stake="500" style={{ cursor: 'pointer' }}>500</a></li>
                                                <li><a class="btn" id="selectStake_6" stake="1000" style={{ cursor: 'pointer' }}>1000</a></li>
                                            </ul>
                                            <p id="totalStakeP" style={{ display: 'none' }}>Total Stake: <strong id="totalStake"></strong></p>
                                        </dd>
                                    </dl>
                                </td>
                            </tr>
                       }
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt>
                                        <p id="marketName">Total Match Bowled Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>2<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>3<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match LBW Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>1<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>2<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Caught Out Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>9<span>110</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>9<span>90</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">3 WKT OR MORE BY BOWLER IN MATCH ADV</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>3<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>4<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Wkt Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>12<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>13<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Boundaries Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>42<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>44<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Six Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>15<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>17<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Four Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>27<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>29<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Highest Run In Over Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>21<span>110</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>21<span>90</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Extra Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>16<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>18<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Wide Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>9<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>10<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Run Out Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>1<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>2<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Fifty Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>2<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>3<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Total Match Playing Over Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>39<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>40<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                            <tr id="fancyBetMarket_168674">
                                <th colSpan={3}>
                                    <dl className="fancy-th-layout"><dt><p id="marketName">Top Scoring Batsman Runs Adv</p></dt>
                                        <dd className="dd-tips">
                                            <ul className="fancy-tips">
                                                <li id="remarkFirstRow" style={{ display: 'none' }} />
                                                <li id="remarkSecondRow" style={{ display: 'none' }} />
                                            </ul><a id="fancyBetBookBtn" className="btn-book" style={{ display: 'none' }}>Book</a></dd>
                                    </dl>
                                </th>
                                <td id="lay_1" className="lay-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>69<span>100</span></a></td>
                                <td id="back_1" className="back-1 null"><a id="runsInfo" style={{ cursor: 'pointer' }}>71<span>100</span></a></td>
                                <td className="td-fancy_merge" colSpan={2}>
                                    <dl className="fancy-info"><dt>Min/Max</dt>
                                        <dd id="minMax"> 1 / 800</dd>
                                    </dl>
                                    <dl className="fancy-info"><dt id="rebateName" style={{ display: 'none' }}>Rebate</dt>
                                        <dd id="rebate" style={{ display: 'none' }} />
                                    </dl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="fullMarketEventLeft" className="event-left" style={{ display: 'block' }} />
                <div id="fullMarketEventRight" className="event-right" style={{ display: 'block' }} /></div>

        </React.Fragment>
    )
}

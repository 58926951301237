import React from 'react'
import { Link } from "react-router-dom"
import Bettingrecords from './Bettingrecords'
import { TransactionRecords } from './TransactionRecords'
import { Turnover } from './Turnover'
import { VerifyAccount } from './VerifyAccount'
import { Resetpassword } from "./Resetpassword"
import { Personalinfo } from './Personalinfo'
import { Inbox } from './Inbox'
import { Referral } from './Referral'

import { Wallet } from "./Wallet"
export const Deposit = (props) => {

    return (
        <React.Fragment>
            <div id="pageContentBody">
                <div className="content content-player" style={{ opacity: 1, transition: 'all 1s ease 0s', paddingTop: '165px' }}>
                    <div className="player-sidenav-box">
                        <nav className="player-sidenav">
                            <div className="member-info">
                                <div className="member-photo pre-load">
                                    <div className="photo bg-load data-image" id="bcimg" />
                                </div>
                                <p id="navUserName"></p>
                                <div className="vip-points"><i />
                                    <span /></div></div>
                            <div className="sidenav-area sidenav-money"><ul>
                                <li className="link-deposit active">
                                    <a data-pagetype={1} className="active">
                                        <div className="sidenav-icon pre-load">
                                            <div className="bg-load" data-preload="/images/player/sidenav/deposit.svg" /></div>
                                        <p>
                                            <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></p></a></li>
                                <li className="link-withdrawal">
                                    <a data-pagetype={2} className>
                                        <div className="sidenav-icon pre-load">
                                            <div className="bg-load" data-preload="/images/player/sidenav/withdrawal.svg" /></div>
                                        <p>
                                            <ui-translate key="fe.text.withdrawal" lang="en">Withdrawal</ui-translate></p></a></li></ul></div>
                            <div className="sidenav-area">
                                <strong>
                                    <ui-translate key="fe.text.profile.moneyManagement" lang="en">Funds</ui-translate></strong><ul>
                                    <li className={`${(props.tabMenu === 4) ? "link-bet-record active" : "link-bet-record"}`} >
                                        <Link to="/bettingrecord" className={`${(props.tabMenu === 4) ? "active" : ""}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p>
                                                <ui-translate key="fe.text.bettingRecord" lang="en">Betting Records</ui-translate></p>
                                        </Link>
                                    </li>

                                    <li className={`${(props.tabMenu === 4) ? "link-my-wallet active" : "link-my-wallet"}`}>
                                        <Link to="/wallet" className={`${(props.tabMenu === 3) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p>
                                                <ui-translate key="ui.text.my_wallet" lang="en">My Wallet</ui-translate>
                                            </p>
                                        </Link>
                                    </li>
                                    <li className={`${(props.tabMenu === 5) ? "link-turnover active" : "link-turnover"}`}>
                                        <Link to="/turnover" className={`${(props.tabMenu === 5) ? "active" : ""}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p><ui-translate key="fe.text.turnover" lang="en">Turnover</ui-translate>
                                            </p>
                                        </Link>
                                    </li>
                                    <li className={`${(props.tabMenu === 6) ? "link-transaction-record active" : "link-transaction-record"}`}>
                                        <Link to="/transactionrecord" className={`${(props.tabMenu === 6) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p><ui-translate key="fe.text.txn_records" lang="en">Transaction Records</ui-translate></p>
                                        </Link>
                                    </li></ul></div>
                            <div className="sidenav-area">
                                <strong><ui-translate key="fe.text.backOffice.breadcrumbs.profile" lang="en">Profile</ui-translate></strong><ul>
                                    <li className={`${(props.tabMenu === 7) ? "link-profile active" : "link-profile"}`}>
                                        <Link to="/personalinfo" data-pagetype={6} className={`${(props.tabMenu === 7) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p>
                                                <ui-translate key="fe.text.personal_info" lang="en">Personal Info</ui-translate>
                                            </p>
                                        </Link>
                                    </li>
                                    <li className={`${(props.tabMenu === 8) ? "link-verify active" : "link-verify"}`}>
                                        <Link to="/verifyaccount" id="verifyAccountFeature" data-pagetype={29} className={`${(props.tabMenu === 8) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p>
                                                <ui-translate key="fe.text.verifyAccount" lang="en">Verify Account</ui-translate>
                                            </p>
                                        </Link>
                                    </li>
                                    <li className={`${(props.tabMenu === 9) ? "link-password active" : "link-password"}`}>
                                        <Link data-pagetype={7} to="/resetpassword" className={`${(props.tabMenu === 9) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" /></div>
                                            <p>
                                                <ui-translate key="fe.text.reset_pass.dialog_header" lang="en">Reset password</ui-translate>
                                            </p>
                                        </Link>
                                    </li>
                                    <li   className={`${(props.tabMenu === 10) ? "link-mail active" : "link-mail"}`}>
                                        <Link data-pagetype={8} to="/inbox" className={`${(props.tabMenu === 10) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" />
                                            </div>
                                            <p>
                                                <ui-translate key="fe.text.inbox" lang="en">Inbox</ui-translate><span>1</span></p>
                                                </Link></li>
                                    <li className={`${(props.tabMenu === 11) ? "link-friend active" : "link-friend"}`} id="referralFeature">
                                        <Link data-pagetype={12}  to="/referral" className={`${(props.tabMenu === 11) ? "active" : "null"}`}>
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/player/sidenav/friend.svg" /></div>
                                            <p><ui-translate key="fe.text.raf.referral" lang="en">Referral</ui-translate></p>
                                        </Link></li></ul></div></nav>
                        <div className="player-community socialicons">
                            <div className="sidenav-area">
                                <strong>
                                    <ui-translate key="fe.text.community.social" lang="en">community social</ui-translate></strong>
                                <ul id="player-sidenav-social-container" /></div>
                        </div>
                        <div className="player-community csicons">
                            <div className="sidenav-area">
                                <strong>
                                    <ui-translate key="fe.text.community.cs" lang="en">community cs</ui-translate></strong>
                                <ul id="player-sidenav-cs-container">
                                    <li className="link-whatsapp">
                                        <a target="_blank" href="https://wa.me/919007786429">
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/csicons/whatsapp.svg" /></div>
                                            <p>WhatsApp</p></a></li>
                                    <li className="link-email">
                                        <a target="_blank" href="mailto:support@sky247.com">
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/csicons/email.svg" /></div>
                                            <p>Support</p></a></li><li className="link-telegram">
                                        <a target="_blank" href="https://t.me/sky247supportbot">
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/csicons/telegram.svg" />
                                            </div><p>Telegram Support Channel</p></a></li>
                                    <li className="link-email">
                                        <a target="_blank" href="mailto:mailto:skypartners@skyinfotechltd.com">
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/csicons/email.svg" />
                                            </div><p>Marketing</p></a></li><li className="link-telegram">
                                        <a target="_blank" href="https://t.me/joinchat/yZdSnpV0SgNlZGFk">
                                            <div className="sidenav-icon pre-load">
                                                <div className="bg-load" data-preload="/images/csicons/telegram.svg" /></div>
                                            <p>Telegram Group</p></a></li></ul></div></div></div>
                    <div className="main-content-wrap">
                        <div className="tips-info verify-tips" style={{ display: 'none' }}>
                            <div className="tips-title-box"><h6><p /></h6>
                                <div className="close-tips" /></div><ol></ol></div>
                        <div className="top-overview-wrap"><ul><li>
                            <div className="left-overview-area">
                                <label><ui-translate key="fe.text.main_account_balance" lang="en">Main Wallet</ui-translate></label>
                                <div className="total-money totalBalanceWallet"><span className="balance" style={{ paddingRight: '7px' }}>₹</span>
                                    <p className="balance">0</p></div></div></li>
                            <li style={{ display: 'none' }}>
                                <div id="topOverviewTotalBonusWallet" className="left-overview-area">
                                    <label><ui-translate key="fe.text.bonus_wallet_balance" lang="en">Bonus Wallet</ui-translate></label>
                                    <div className="total-money totalBonusWallet">
                                        <span className="balance" style={{ paddingRight: '7px' }}>₹</span>
                                        <p>0</p></div></div></li>
                            <li>
                             {/*danger-statenormal-statesafe-state*/}
                                {/* TODO: i18n */}
                                <div className="left-overview-area danger-state">
                                    <label><ui-translate key="fe.text.security_level" lang="en">Security Level</ui-translate></label>
                                    <p id="contactVerifyStatus"><ui-translate key="fe.text.unverified" lang="en">Un-Verified</ui-translate></p></div>
                                <div className="right-overview-area" >
                                    <a id="verify-mail-link" onClick={() => (props.handlerightside())} className="verify-mail status-verify-no" />
                                    <a id="verify-phone-link" onClick={() => (props.Verifypopup())} className="verify-phone status-verify-no" /></div></li></ul>
                        </div>


                        {props.tabMenu === 3 && <Wallet />}
                        {props.tabMenu === 4 && <Bettingrecords />}
                        {props.tabMenu === 5 && <Turnover />}
                        {props.tabMenu === 6 && <TransactionRecords />}
                        {props.tabMenu === 7 && <Personalinfo />}
                        {props.tabMenu === 8 && <VerifyAccount />}
                        {props.tabMenu === 9 && <Resetpassword />}
                        {props.tabMenu === 10 && <Inbox />}
                        <Referral />

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

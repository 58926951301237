import React from 'react'

export const Announcement = () => {
    return (
        <React.Fragment>
             <div>
     
        <div class="marquee-box">
          <h4>News</h4>
          <div class="marquee">
            <div class="js-marquee-wrapper">
              <div
                class="js-marquee"
                style={{ marginRight: "0px", float: "left" }}
              >
                <a>
                  {" "}
                  <span>2 Jan 2022</span>
                  this is exchange site
                </a>
                <a  ><span>15 Sep 2020</span>gamex IS NOW POWERED BY BETFAIR .</a>{" "}
              </div>
            </div>
          </div>
        </div>
     
    </div>
        </React.Fragment>
    )
}

import React, { useState } from 'react'
import { Link } from "react-router-dom";

export const PagefooterMenu = (props) => {
    const [User, setUser] = useState(false);
    const [Password, setPassword] = useState(false);

    const loginpage = () => {
        if (User == "demo" && Password == "1234") {
            alert("ok")
            props.CheckshowLogin(true)

        } else {
            props.CheckshowLogin(false)
        }
    }

    return (
        <React.Fragment>
               {props.SHowLogin === true &&  props.tabMenu !==9 &&
               <div class="beforelogin ">

                <div class="register-button">
                    <a href="/signup">
                        Sign up
                    </a>
                </div>
                <div class="login-button">
                    <a href="/login">
                        Login
                    </a>
                </div>
            </div>}
            {props.SHowLogin === false && props.tabMenu !==9 &&
            <div className="toolbar" id="pageFooterMenu">
                <ul>
                    <li data-pagetype={0} id="mainPageLink" className="home">
                        <Link to="/">
                            <p>
                                <ui-translate key="fe.text.nav.mainPage" lang="en">Home</ui-translate>
                            </p>
                        </Link>
                    </li>
                    <li data-pagetype={9} id="promotionsLink" className={`${(props.tabMenu === 2) ? "promotion active" : "promotion"}`} >
                        <Link to="/promotions" >
                            <p>
                                <ui-translate key="fe.text.nav.promotions" lang="en">Promotions</ui-translate>
                            </p>
                        </Link>
                    </li>
                    <li data-pagetype={1} id="depositLink" className={`${(props.tabMenu === 14) ? "deposit active" : "deposit"}`} >
                        <Link to="/deposit" >
                            <p>
                                <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                            </p>
                        </Link>
                    </li>
                    <li id="memberMenuTab" className="mine" data-reloadbalance>
                        <a onClick={() => { props.Clickaccount() }}>
                            <p>
                                <ui-translate key="fe.text.nav.myAccount" lang="en">My Account</ui-translate>
                            </p>
                        </a>
                    </li>
                </ul>
            </div>}
        </React.Fragment>
    )
}

import React from 'react'

export const MobAnnouncement = (props) => {
    return (
        <React.Fragment>
            <div className="announcement-row" onClick={()=>{props.MassageClick()}}>
                <marquee style={{ whiteSpace: 'nowrap' }} >
                    <ul id="main_running_msg_container">
                        <li id="running-26423">
                            <span>
                                <p>
                                    <strong>
                                        <span style={{ fontFamily: 'Arial,Helvetica,sans-serif' }}>Exchange&nbsp;will be unavailable from 07:30 IST to 08:30 IST on 27th April&nbsp;for maintenance. Sorry for the inconvenience caused.</span>
                                    </strong>
                                </p>
                            </span>
                        </li>
                        <li id="running-25005">
                            <span>
                                <p>
                                    <span style={{ fontSize: '14px' }}>
                                        <strong>Always confirm deposit account details before transferring funds -&nbsp;कृपया पैसा जमा करने से पहले हमेशा जमा खाते के विवरण की जांच करें</strong></span></p>
                            </span>
                        </li>
                    </ul>
                </marquee>
            </div>
        </React.Fragment>
    )
}

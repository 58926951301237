import React from 'react'
import iconspeaker from "./MobileImage/icon-speaker.svg"
export const Mobinbox = () => {
    return (
        <React.Fragment>
            <div className="player-top tabBtn-section">
    <div className="tabBtn tabBtn-page" id="inboxTab">
        <div className="line" style={{width: 'calc(100%)', transform: 'translate(0%, 0px)'}} />
        <div className="btn" data-tab-current="current">
            <div className="text">
                <ui-translate key="fe.text.notification" lang="en">Notification</ui-translate>
                <div className="badge" id="inboxNoticeUnRead">4</div>
            </div>
        </div>
    </div>
</div>
<div className="tabContent tabContent-page">
    <div className="inner-wrap" style={{transform: 'translate(0%, 0px)'}}>
        <div className="inner-box" id="inboxNotice" data-content-current="current" style={{height: 'auto'}}>
            <div data-titlekey="2022/03/26" className="list list-message">
                <div className="date-title">
                    <div className="date">2022/03/26</div>
                    <div className="timeZone">GMT+5.5</div>
                </div>
            </div>
            <div className="list-content">
                <ul>
                    <li data-messagetype={2} data-title="2022/03/26" data-id={70042561} className="message-item">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>Cash Bonus Offers ready for you!</span>
                                <div className="msg-time">
                                    01:27:39
                                </div>
                            </div>
                            <div className="text">And what better time than with the start of the IPL? और IPL की शुरुआत से बेहतर समय और क्या हो सकता है? Sky247 pays for IPL sixes every day - 100 players, ₹100 every six! (Qualify by betting at least ₹5,247 on any cricket markets
                                daily). Sky247 IPL के छक्कों के लिए प्रतिदिन भुगतान करता है - 100 खिलाड़ी, हर छक्के पर 100! (किसी भी क्रिकेट बाजार पर प्रतिदिन कम से कम ₹5,247 की बेट लगाकर योग्यता प्राप्त करें)। AND THERE 'S MORE! और भी बहुत कुछ है! Win
                                Premium Free Bets every week हर हफ्ते प्रीमियम फ्री बेट जीतें Get up to ₹2,247 in Fancy Bonus every week हर हफ्ते ₹2,247 तक का फैंसी बोनस पाएं Receive Cashback up to ₹24,700 every day! प्रतिदिन ₹24,700 तक का कैशबैक प्राप्त
                                करें! Check Now! अभी जाँचे Happy bets with Sky247! Sky247 के साथ हैप्पी बेट्स!
                            </div>
                        </div>
                    </li>
                    <li data-messagetype={2} data-title="2022/03/26" data-id={69908135} className="message-item">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img  src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>Cash Bonus Offers ready for you!</span>
                                <div className="msg-time">
                                    01:10:48
                                </div>
                            </div>
                            <div className="text">And what better time than with the start of the IPL? Sky247 pays for IPL sixes every day - 100 players, ₹100 every six! (Qualify by betting at least ₹5,247 on any cricket markets daily). AND THERE 'S MORE! - Win Premium Free
                                Bets every week - Get up to ₹2,247 in Fancy Bonus every week - Receive Cashback up to ₹24,700 every day! Check Now! Happy bets with Sky247!
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div data-titlekey="2022/03/17" className="list list-message">
                <div className="date-title">
                    <div className="date">2022/03/17</div>
                    <div className="timeZone">GMT+5.5</div>
                </div>
            </div>
            <div className="list-content">
                <ul>
                    <li data-messagetype={2} data-title="2022/03/17" data-id={68637817} className="message-item read">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img  src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>Celebrate a colourful Holi!</span>
                                <div className="msg-time">
                                    20:57:30
                                </div>
                            </div>
                            <div className="text">Sky247 wishes you a Happy Holi! Enjoy this joyful season with the best Casino games at Sky247! Andar Bahar, Teen Patti&nbsp;&nbsp;&amp; much more!&nbsp;Click HERE to play!Happy bets with Sky247!&nbsp; Sky247 की ओर से आपको होली
                                की शुभकामनाएँ! Sky247 पर सर्वश्रेष्ठ कैसीनो खेलों के साथ इस आनंदमय त्यौहार का आनंद लें! अंदर बहार, तीन पत्ती और भी बहुत कुछ!&nbsp;खेलने के लिए&nbsp;यहां&nbsp;क्लिक करें!&nbsp;Sky247 के साथ हैप्पी बेट्स!
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div data-titlekey="2021/11/19" className="list list-message">
                <div className="date-title">
                    <div className="date">2021/11/19</div>
                    <div className="timeZone">GMT+5.5</div>
                </div>
            </div>
            <div className="list-content">
                <ul>
                    <li data-messagetype={2} data-title="2021/11/19" data-id={51237207} className="message-item">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img  src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>T10 runs give you cash!</span>
                                <div className="msg-time">
                                    00:45:47
                                </div>
                            </div>
                            <div className="text">Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive ₹5 for every run scored in Abu Dhabi T10! Check Terms and Conditions on promotions page and get your chance!
                                Happy Bets with Sky247!
                            </div>
                        </div>
                    </li>
                    <li data-messagetype={2} data-title="2021/11/19" data-id={51168892} className="message-item read">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img  src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>T10 runs give you cash!</span>
                                <div className="msg-time">
                                    00:43:18
                                </div>
                            </div>
                            <div className="text">Staring today, by betting daily ₹1,247 on cricket events you become eligible to take part in a draw where you receive ₹5 for every run scored in Abu Dhabi T10! Check Terms and Conditions on promotions page and get your chance!
                                Happy Bets with Sky247!
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div data-titlekey="2021/11/18" className="list list-message">
                <div className="date-title">
                    <div className="date">2021/11/18</div>
                    <div className="timeZone">GMT+5.5</div>
                </div>
            </div>
            <div className="list-content">
                <ul>
                    <li data-messagetype={2} data-title="2021/11/18" data-id={51064766} className="message-item">
                        <div className="chose-btn" />
                        <div className="icon">
                        <img  src={iconspeaker} alt="" /></div>
                        <div className="content-wrap">
                            <div className="title"> <span>Premium cricket maintenance</span>
                                <div className="msg-time">
                                    22:01:38
                                </div>
                            </div>
                            <div className="text">Priya grahak Kripya dhyan de ki SKY247 premium cricket sevaye aaj 18.11 ko server maintenance ke tahat raat 09 baje se 11 baje tak seva me uplabadh nahi hogi. Agar maintenance ke tahat bhi aap seva ka upyog nahi kar pa rahe
                                he to apne browser se cache and cookies ko clear karien. Aapki samaj ke liye dhanyavaad aur apka din subh ho. -- Valued Players, Please note that Sky247 premium cricket services is today - 18/11 - under server maintenance
                                from 21:00 to 23:00 IST. During this period premium cricket is not accessible. Please clear cache and cookies after the maintenance if you still can't access the referred product. Thank you for your understanding and have
                                a great day ahead.
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
        </React.Fragment>
    )
}

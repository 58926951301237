import React, { useState } from 'react'
import { Link } from "react-router-dom"


export const Header = (props) => {
  const [Navtab, setNavtab] = useState(false)
  const Navtouched = () => {
    setNavtab(!Navtab)
  }
  const ctime = new Date().toLocaleTimeString()
  return (
    <React.Fragment>
      <header className="mini-header">
        <div className="status-wrap">
          <div className="header-inner">
            <span id="header_inner_time" className="time" title="2022/03/19">(GMT+.5:00)
            <time> {ctime}</time>
            </span>
           
            <div className="header-online-sevice">
              <ul id="header_cs_container" className="online-sevice-inner">
                <li id="cs-header-121">
                  <a ui-key="displayText" className="icon-whatsapp">WhatsApp</a></li>
                <li id="cs-header-122">
                  <a href="mailto:support@sky247.com" target="_blank" className="icon-mail">Support</a></li>
                <li id="cs-header-183">
                  <a ui-key="displayText" className="icon-telegram">Telegram Support Channel</a></li>
                <li id="cs-header-162">
                  <a href="mailto:mailto:skypartners@skyinfotechltd.com" target="_blank" className="icon-mail">Marketing</a>
                </li>
                <li id="cs-header-161">
                  <a ui-key="displayText" className="icon-telegram">Telegram Group</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="logo-wrap">
          <div className="header-inner">
            <div className="logo data-image active" data-image="/images/site/logo.png" data-pagetype={0} />
            <dl className="header-login">
              <dt>
                <span>
                  <a className="balance-main">
                    <ui-translate key="fe.text.main_account_balance" lang="en">Main Wallet</ui-translate>
                  </a>
                </span>
                <span className="amount totalBalanceWallet">
                <span className="balance" style={{ paddingRight: '7px' }}>₹</span>
                <p className="balance">0</p></span>
                <span className="amount">
                  <a className="reload" data-reloadbalance="true" />
                </span>
              </dt>
              <dd>
                <a href="/deposit" className="btn-default" data-pagetype={1}>
                  <ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate>
                </a>
                <a className="btn-2nd" data-pagetype={6}>
                  <ui-translate key="fe.text.member_center" lang="en">Member Center</ui-translate>
                </a>
                <a className="btn-3rd" onclick="window.location='/player/logout';">
                  <ui-translate key="fe.text.logout" lang="en">Log out</ui-translate>
                </a>
              </dd>
            </dl>
          </div>
        </div>
         <div className="main-nav main-sub-nav">
          <ul className="nav-inner">
            <li className="nav-item back-index active" id="menu-index" data-pagetype={0}>
              <a className="nav-promotion">
                <i className="icon-home" />
              </a>
            </li>
            <li className="nav-item app-download">
              <a href="/page/site/se/include/appDownload.jsp">
                <i className="icon-download" />
              </a>
            </li>
            <li className="" id="menu-sport" className={`${(props.tabMenu === 12 || props.tabMenu === 13 || props.tabMenu === 14 || props.tabMenu === 15 ||props.tabMenu === 16 ||props.tabMenu === 17 ||props.tabMenu === 18 ) ? "nav-item-2" : "nav-item"}`}  >
              <Link to="/cricket">
                <ui-translate key="ui.text.nav.sport" lang="en">Sports</ui-translate>
              </Link>
              
        </li>
            <li className="nav-item" id="menu-casino" data-pagetype={17}>
              <a className="nav-promotion">
                <ui-translate key="ui.text.nav.casino" lang="en">SportsBook</ui-translate>
              </a>
            </li>
           
            <li id="menu-casino" className={`${(props.tabMenu === 19) ? "nav-item-2" : "nav-item"}`} data-pagetype={17}>
              <Link  className="nav-promotion" to="/casino">
                <ui-translate key="ui.text.nav.casino" lang="en">Casino</ui-translate>
              </Link>
            </li>
            <li className={`${(props.tabMenu === 20) ? "nav-item-2" : "nav-item"}`}  id="menu-slot" data-pagetype={10}>
              <Link className="nav-promotion" to="/skyslot">
                <ui-translate key="ui.text.nav.slot" lang="en">Slot</ui-translate>
              </Link>
            </li>
            <li className="nav-item" id="menu-table" data-pagetype={11}>
              <a className="nav-promotion">
                <ui-translate key="ui.text.nav.table" lang="en">Table</ui-translate>
              </a>
            </li>
            <li className="nav-item" id="menu-lottery">
              <a className="nav-promotion">
                <ui-translate key="ui.text.nav.lottery" lang="en">Lottery</ui-translate>
              </a>
            </li>
            <li className="nav-item" id="menu-promotions" data-pagetype={9}>
              <a className="nav-promotion">
                <ui-translate key="ui.text.nav.promotions" lang="en">Promotions</ui-translate>
              </a>
            </li>
          </ul>
        </div>
      </header>

    </React.Fragment>
  )
}

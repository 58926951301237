import React from 'react'
import promtion1 from "./MobileImage/imagemob.jpeg"
import promotion2 from "./MobileImage/mobImage.jpeg"
import promotion3 from "./MobileImage/mobimagecric.jpeg"
import promotion4 from "./MobileImage/mobimagetenn.jpeg"
import promtion5 from "./MobileImage/promotion5.jpeg"
import promotion6 from "./MobileImage/mobimage6.jpeg"
export const Promotions = () => {
    return (
        <React.Fragment>
        <div class="promotion">
            <div className="content-main">
                <div className="content-box">
                    <div className="promotion">
                        <div className="tab search-tab">
                            <ul id="content_promotionFilter">
                                <li data-filter="all" className="active">
                                    <ui-translate key="fe.text.latest_promotions" lang="en">New Promotions</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={99}>
                                    <ui-translate key="fe.text.fdb_promotions" lang="en">FDB</ui-translate>
                                </li>
                                <li style={{}} data-filter={1}>
                                    <ui-translate key="fe.text.fe.game_type.slot" lang="en">Slots</ui-translate>
                                </li>
                                <li style={{}} data-filter={2}>
                                    <ui-translate key="fe.text.fe.game_type.casino" lang="en">Casino</ui-translate>
                                </li>
                                <li style={{}} data-filter={4}>
                                    <ui-translate key="fe.text.fe.game_type.sport" lang="en">Sports</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={8}>
                                    <ui-translate key="fe.text.fe.game_type.fish" lang="en">Fishing</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={16}>
                                    <ui-translate key="fe.text.fe.game_type.card" lang="en">Card Game</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={32}>
                                    <ui-translate key="fe.text.fe.game_type.esport" lang="en">ESports</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={64}>
                                    <ui-translate key="fe.text.fe.game_type.lottery" lang="en">Lottery</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={128}>
                                    <ui-translate key="fe.text.fe.game_type.p2p" lang="en">P2P</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={256}>
                                    <ui-translate key="fe.text.fe.game_type.table" lang="en">Table</ui-translate>
                                </li>
                                <li style={{ display: 'none' }} data-filter={1024}>
                                    <ui-translate key="fe.text.fe.game_type.arcade" lang="en">Arcade</ui-translate>
                                </li>
                                <li style={{}} data-filter={512}>
                                    <ui-translate key="fe.text.fe.game_type.others" lang="en">Others</ui-translate>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div className="promotion-main inner-wrap">
  <div id="content_promotion" className="inner-box" style={{padding: '0px', position: 'relative', height: '2376px'}}>
    <div className="filtr-item" data-category={4} style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 0px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto'}}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promtion1} data-messagetype={3} data-id="bonusMsg-25233" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title"> IPL Sixes</h3>
            <p>Happy Bets with Sky247!</p>
          </div>
          <div className="times">
            <span>2022/03/30 00:00:00</span> ~ <span>2022/05/29 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{}} data-messagetype={3} data-id="bonusMsg-25233">
              <span><ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
            <div className="button button-secondary btn purchase-btn" style={{}} data-id="bonusMsg-25233" data-pagetype={1} data-bonus={6163}>
              <span><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="filtr-item" data-category={4} style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 323px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto' }}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promotion2} data-messagetype={3} data-id="bonusMsg-19452" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title">Sky247 Welcome Offer</h3>
            <p>Happy Bets with Sky247!</p>
          </div>
          <div className="times">
            <span>2019/01/01 00:00:00</span> ~ <span>2099/12/31 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{}} data-messagetype={3} data-id="bonusMsg-19452">
              <span>
              <ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
            <div className="button button-secondary btn purchase-btn" style={{}} data-id="bonusMsg-19452" data-pagetype={1} data-bonus={6163}>
              <span><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="filtr-item" data-category={4} style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 646px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto'}}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promotion3} data-messagetype={3} data-id="bonusMsg-19822" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title">Weekly Premium Free Bet</h3>
            <p>Happy Bets with Sky247!</p>
          </div>
          <div className="times">
            <span>2019/01/01 00:00:00</span> ~ <span>2099/12/31 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{}} data-messagetype={3} data-id="bonusMsg-19822">
              <span><ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
            <div className="button button-secondary btn purchase-btn" style={{}} data-id="bonusMsg-19822" data-pagetype={1} data-bonus={6163}>
              <span><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="filtr-item" data-category={1} style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 969px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto'}}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promotion4} data-messagetype={3} data-id="bonusMsg-19826" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title">Fancy Deposit Bonus</h3>
            <p>21% Deposit Bonus up to ₹2,247 every week</p>
          </div>
          <div className="times">
            <span>2021/09/19 00:00:00</span> ~ <span>2022/12/31 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{}} data-messagetype={3} data-id="bonusMsg-19826">
              <span><ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
            <div className="button button-secondary btn purchase-btn" style={{}} data-id="bonusMsg-19826" data-pagetype={1} data-bonus={7013}>
              <span><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="filtr-item" data-category="1, 2" style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 1292px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto'}}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promtion5} data-messagetype={3} data-id="bonusMsg-19821" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title">Daily Casino Cashback</h3>
            <p>Happy Bets with Sky247!</p>
          </div>
          <div className="times">
            <span>2019/01/01 00:00:00</span> ~ <span>2099/12/31 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{}} data-messagetype={3} data-id="bonusMsg-19821">
              <span><ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
            <div className="button button-secondary btn purchase-btn" style={{}} data-id="bonusMsg-19821" data-pagetype={1} data-bonus={6163}>
              <span><ui-translate key="fe.text.deposit" lang="en">Deposit</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="filtr-item" data-category={512} style={{opacity: 1, zIndex: 9, transform: 'scale(1) translate3d(0px, 1615px, 0px)', backfaceVisibility: 'hidden', perspective: '1000px', transformStyle: 'preserve-3d', position: 'absolute', transition: 'all 0.5s ease-out 0ms', pointerEvents: 'auto'}}>
      <div className="promotion-box new ">
        <div className="pic">
          <img src={promotion6} data-messagetype={3} data-id="bonusMsg-19280" />
        </div>	
        <div className="promotion-box-inner content-style">
          <div className="text-main">
            <h3 className="title">Bring Your Friends to Sky247</h3>
            <p>Grab a bonus by referring friends to Sky247</p>
          </div>
          <div className="times">
            <span>2021/08/26 00:00:00</span> ~ <span>2022/12/31 23:59:59</span>
          </div>
          <div className="button-box">
            <div className="button open-pop btn cancel-btn" style={{width: '100%'}} data-messagetype={3} data-id="bonusMsg-19280">
              <span><ui-translate key="fe.text.btn_show_detail" lang="en">Detail</ui-translate></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

        </React.Fragment>
    )
}

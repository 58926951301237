import React,{useState,useEffect} from 'react'
import Axios from 'axios';

export const Betslip = (props) => {
    const [ssstake1, setSsstake1] = useState(0);
    const [ssstake2, setSsstake2] = useState(0);
    const [ssstake3, setSsstake3] = useState(0);
    const [ssstake4, setSsstake4] = useState(0);
    const [ssstake5, setSsstake5] = useState(0);
    const [ssstake6, setSsstake6] = useState(0);
    const [defaultStake, setdefaultStake] = useState(' ');


    const handleClick = (val)=>{

        setdefaultStake(val);
      
    }
    const handleInput = (event)=>{
         
        setdefaultStake(event.target.value);
       
    }

    
   useEffect(() => {
        
    Axios.post('https://betplace247.com/api/client/clientssgetclickstake',{
   

  
  })
   .then(result => {
     if(result.status === 210){

       
        setdefaultStake(result.data[0].defaultStake);
        setSsstake1(result.data[0].SSstake1);
        setSsstake2(result.data[0].SSstake2);
        setSsstake3(result.data[0].SSstake3);
        setSsstake4(result.data[0].SSstake4);
        setSsstake5(result.data[0].SSstake5);
        setSsstake6(result.data[0].SSstake6);
         
      } 
 }
 
).catch(e => {

});
    },[])
        
        
   
    return (
        <React.Fragment>
            <div id="betSlipBoard" className="bet_slip">
    <div id="betList" className="slip-list" style={{maxHeight: 'calc(100% - 86px)'}}>
        <dd id="stakePopupList" className="col-stake_list" stakepopuplisttype="template" style={{display: 'none'}}>
            <ul>
                <li>
                    <a className="btn" id="selectStake_1" stake={30} style={{cursor: 'pointer'}}>{ssstake1}</a></li>
                <li>
                    <a className="btn" id="selectStake_2" stake={40} style={{cursor: 'pointer'}}>{ssstake2}</a></li>
                <li>
                    <a className="btn" id="selectStake_3" stake={50} style={{cursor: 'pointer'}}>{ssstake3}</a></li>
                <li>
                    <a className="btn" id="selectStake_4" stake={60} style={{cursor: 'pointer'}}>{ssstake4}</a></li>
                <li>
                    <a className="btn" id="selectStake_5" stake={70} style={{cursor: 'pointer'}}>{ssstake5}</a></li>
                <li>
                    <a className="btn" id="selectStake_6" stake={80} style={{cursor: 'pointer'}}>{ssstake6}</a>
                </li>
            </ul>
        </dd>
        <div id="oddsTipsPopup" className="tips-popup" style={{display: 'none'}}>min 1.01</div>
        <div id="stakeTipsPopup" className="tips-popup" style={{display: 'none'}}>Min 4.00</div>
        <ul id="backSlipHeader" className="slip-head" name="slipHeader">
            <li className="col-bet">Back (Bet For)</li>
            <li id="oddsHeader" className="col-odd">Odds</li>
            <li id="runsHeader" className="col-odd" style={{display: 'none'}}>Unit:Runs</li>
            <li className="col-stake">Stake</li>
            <li className="col-profit">Profit</li>
        </ul>
        <div id="header_Back_30030230" count={1} style={{display: 'block'}}>
            <h4>
                <img className="icon-in_play" src="/images/transparent.gif"/> Rajasthan Royals v Kolkata Knight Riders</h4>
            <dl id="bet_30030230_1_173400449_215817_1" className="slip-back" style={{display: 'block'}}>
            <dt>
            <a id="delete" className="delete" style={{cursor: 'pointer'}}>delete</a>
            <span className="short-amount">Rajasthan Royals</span>
            <span id="marketName">Match Odds</span>
            </dt>
                <dd className="col-odd">
                <input id="odds" type="text" maxLength={6} tabIndex={0}   />
                    <ul className="odd-add">
                        <li>
                        <a id="oddsUp"   className="up" style={{cursor: 'pointer'}}>up</a></li>
                        <li>
                        <a id="oddsDown"  className="down" style={{cursor: 'pointer'}}>down</a></li>
                    </ul>
                    <ul id="oddsTipsPoint" />
                </dd>
                <dd className="col-stake">
                <input id="inputStake" type="text"  value={defaultStake} onChange = {(event)=>{handleInput(event);}} maxLength={7} tabIndex={0} defaultValue />
                    <ul id="listPoint" />
                </dd>
                <dd id="profitLiability" className="col-profit">NaN</dd>
                <dd id="stakePopupList" className="col-stake_list" stakepopuplisttype="extended" bet="bet_30030230_1_173400449_215817_1" style={{display: 'block'}}>
                    <ul>
                        <li><a className="btn" id="selectStake_1"onClick ={()=>{handleClick(ssstake1);}} style={{cursor: 'pointer'}}>10</a></li>
                        <li><a className="btn" id="selectStake_2"onClick ={()=>{handleClick(ssstake2);}} style={{cursor: 'pointer'}}>20</a></li>
                        <li><a className="btn" id="selectStake_3" onClick ={()=>{handleClick(ssstake3);}} style={{cursor: 'pointer'}}>50</a></li>
                        <li><a className="btn" id="selectStake_4" onClick ={()=>{handleClick(ssstake4);}} style={{cursor: 'pointer'}}>100</a></li>
                        <li><a className="btn" id="selectStake_5" onClick ={()=>{handleClick(ssstake5);}} style={{cursor: 'pointer'}}>200</a></li>
                        <li><a className="btn" id="selectStake_6" onClick ={()=>{handleClick(ssstake6);}} style={{cursor: 'pointer'}}>500</a></li>
                    </ul>
                </dd>
                <dd className="keep-option">
                    <p className="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet">1</strong></p>
                </dd>
                <dd id="inningsRuns" className="inn_runs" style={{display: 'none'}}>
                    <dl><dt id="ifRuns" />
                        <dd><span id="runsPL" className="green" /></dd><dt id="ifLess" />
                        <dd><span id="lessPL" className="green" /></dd>
                    </dl>
                </dd>
            </dl>
        </div>
        <dl id="betTemplate" className="slip-back" marketid selectionid style={{display: 'none'}}><dt><a id="delete" className="delete" style={{cursor: 'pointer'}}>delete</a><span id="marketName">Match Odds</span></dt>
            <dd className="col-odd"><input id="odds" type="text" maxLength={6} defaultValue="1.83" />
                <ul className="odd-add">
                    <li><a id="oddsUp" className="up" style={{cursor: 'pointer'}}>up</a></li>
                    <li><a id="oddsDown" className="down" style={{cursor: 'pointer'}}>down</a></li>
                </ul>
                <ul id="oddsTipsPoint" />
            </dd>
            <dd className="col-stake"><input id="inputStake" type="text" maxLength={7} defaultValue />
                <ul id="listPoint" />
            </dd>
            <dd id="profitLiability" className="col-profit" />
            <dd className="keep-option">
                <p className="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet">1</strong></p>
            </dd>
            <dd id="inningsRuns" className="inn_runs" style={{display: 'none'}}>
                <dl>
                <dt id="ifRuns" />
                    <dd><span id="runsPL" className="green" />
                    </dd>
                    <dt id="ifLess" />
                    <dd>
                    <span id="lessPL" className="green" />
                    </dd>
                </dl>
            </dd>
        </dl>
    </div>
    <div id="betSlipFullBtn" className="full_btn">
        <dl className="sum">
        <dt>Liability</dt>
            <dd>
            <span id="total" className="red">0.00</span>
            </dd>
        </dl>
        <ul className="btn-wrap">
            <li><a id="cancelAll" onClick={()=>{props.ClickbetCLose()}} className="btn" tabIndex={0} style={{cursor: 'pointer'}}>Cancel All</a></li>
            <li><a id="placeBets" className="btn-send" tabIndex={0} style={{cursor: 'pointer'}}>Place Bet</a></li>
        </ul>
        <ul className="slip-option">
            <li>
            <input id="comfirmBets" type="checkbox" />
            <label htmlFor="comfirmBets">Please confirm your bets.</label></li>
        </ul>
    </div>
</div>
        </React.Fragment>
    )
}

import React, { useState } from 'react'
import { Documents } from '../MobileComponent/Documents';
import { Bankstatement } from '../MobileComponent/Bankstatement'

export const VerifyAccount = () => {
    const [Verifytab, setVerifytab] = useState(0)
    return (
        <React.Fragment>
            <div class="content player-content playerform-content">
                <div className="tabBtn-section tabBtn-wrap">
                    <div className="tabBtn tabBtn-bar">
                        <div className="line" style={Verifytab ? { width: 'calc(50%)', transform: ' translate(100%, 0px)' } : { width: ' calc(50%)', transform: 'translate(0%, 0px)' }} />
                        <div className="btn" id="documentTab" data-tab-current="current">
                            <div className="text" onClick={() => { setVerifytab(0) }}>
                                <ui-translate key="fe.text.verifyAccount.documents" lang="en">Documents</ui-translate>
                                <div className="badge" />
                            </div>
                        </div>
                        <div className="btn" id="bankTab" data-tab-current>
                            <div className="text" onClick={() => { setVerifytab(1) }}>
                                <ui-translate key="fe.text.documentType.bankStatement" lang="en">Bank Statement</ui-translate>
                                <div className="badge" />
                            </div>
                        </div>
                    </div>
                </div>

               {Verifytab === 0 && <Documents />}
               {Verifytab === 1 &&  <Bankstatement />}
            </div>
        </React.Fragment>
    )
}

import React from 'react'

export const Personalinfo = () => {
    return (
        <React.Fragment>
            <div id="mainContentBody" className="replaceable-block">
    <main className="container-player-wrapper">
        <div className="container-player-group member-day-data">
            <ul className="col-4">
                <li>
                    <label>
            <ui-translate key="fe.text.account.lastLoginTime" lang="en">Last Login Time</ui-translate>
          </label>
                    <p id="loginTime">2022/03/23 16:25:00</p>
                </li>
                <li>
                    <label>
            <ui-translate key="fe.text.account.lastDepositTime" lang="en">Last Deposit Time</ui-translate>
          </label>
                    <p id="lastDepositTime">
                        <a className="btn-player-secendary btn-5th-xs" data-pagetype={1}>
                            <ui-translate key="fe.text.deposit_now" lang="en">Deposit now</ui-translate>
                        </a>
                    </p>
                </li>
                <li>
                    <label>
            <ui-translate key="fe.text.account.lastWithdrawalTime" lang="en">Last Withdrawal Time</ui-translate>
          </label>
                    <p id="lastWithdrawalTime">
                        <ui-translate key="fe.text.noData" lang="en">No Data</ui-translate>
                    </p>
                </li>
                <li>
                    <label>
            <ui-translate key="fe.text.account.registerDate" lang="en">Date Registered</ui-translate>
          </label>
                    <p id="createTime">2021/03/03 00:18:49</p>
                </li>
            </ul>
        </div>
        <div id="profilePromotionContainer" className="container-player-group new-promotion-slider">
            <h5>
                <ui-translate key="ui.text.latest_promotions" lang="en">New Promotions</ui-translate>
            </h5>
            <div className="promotion-slide-box">
                <ul id="profile_promotion_container" className="slides slick-initialized slick-slider slick-dotted">
                    <div className="slick-list draggable">
                        <div className="slick-track" style={{opacity: 1, width: '4485px', transform: 'translate3d(-1794px, 0px, 0px)', transition: 'transform 1000ms ease 0s'}}>
                            <li style={{width: '289px'}} title="Weekly Premium Free Bet" className="slick-slide slick-cloned" data-slick-index={-3} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19822" data-pk={19822} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20350.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20350.jpg")'}} />
                                    <p ui-key="title">Weekly Premium Free Bet</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Fancy Deposit Bonus" className="slick-slide slick-cloned" data-slick-index={-2} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19826" data-pk={19826} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20353.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20353.jpg")'}} />
                                    <p ui-key="title">Fancy Deposit Bonus</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Daily Casino Cashback" className="slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19821" data-pk={19821} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20356.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20356.jpg")'}} />
                                    <p ui-key="title">Daily Casino Cashback</p>
                                </a>
                            </li>
                            <li id="slick-slide40" style={{width: '289px'}} title="IPL Warm Up" className="slick-slide" data-slick-index={0} aria-hidden="true" tabIndex={0} role="tabpanel" aria-describedby="slick-slide-control40">
                                <a data-id="promotion-24980" data-pk={24980} data-type={0} data-messagetype={3} tabIndex={0}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_22035.jpg" style={{backgroundImage: 'url("/upload/announcement/image_22035.jpg")'}} />
                                    <p ui-key="title">IPL Warm Up</p>
                                </a>
                            </li>
                            <li id="slick-slide41" style={{width: '289px'}} title="Sky247 Weekly Winning Boost" className="slick-slide" data-slick-index={1} aria-hidden="true" tabIndex={0} role="tabpanel">
                                <a data-id="promotion-23635" data-pk={23635} data-type={0} data-messagetype={3} tabIndex={0}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_19565.jpg" style={{backgroundImage: 'url("/upload/announcement/image_19565.jpg")'}} />
                                    <p ui-key="title">Sky247 Weekly Winning Boost</p>
                                </a>
                            </li>
                            <li id="slick-slide42" style={{width: '289px'}} title="Sky247 Welcome Offer" className="slick-slide" data-slick-index={2} aria-hidden="true" tabIndex={0} role="tabpanel">
                                <a data-id="promotion-19452" data-pk={19452} data-type={0} data-messagetype={3} tabIndex={0}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_19472.jpg" style={{backgroundImage: 'url("/upload/announcement/image_19472.jpg")'}} />
                                    <p ui-key="title">Sky247 Welcome Offer</p>
                                </a>
                            </li>
                            <li id="slick-slide43" style={{width: '289px'}} title="Weekly Premium Free Bet" className="slick-slide slick-current slick-active" data-slick-index={3} aria-hidden="false" tabIndex={-1} role="tabpanel" aria-describedby="slick-slide-control41">
                                <a data-id="promotion-19822" data-pk={19822} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20350.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20350.jpg")'}} />
                                    <p ui-key="title">Weekly Premium Free Bet</p>
                                </a>
                            </li>
                            <li id="slick-slide44" style={{width: '289px'}} title="Fancy Deposit Bonus" className="slick-slide slick-active" data-slick-index={4} aria-hidden="false" tabIndex={-1} role="tabpanel">
                                <a data-id="promotion-19826" data-pk={19826} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20353.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20353.jpg")'}} />
                                    <p ui-key="title">Fancy Deposit Bonus</p>
                                </a>
                            </li>
                            <li id="slick-slide45" style={{width: '289px'}} title="Daily Casino Cashback" className="slick-slide slick-active" data-slick-index={5} aria-hidden="false" tabIndex={-1} role="tabpanel">
                                <a data-id="promotion-19821" data-pk={19821} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20356.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20356.jpg")'}} />
                                    <p ui-key="title">Daily Casino Cashback</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="IPL Warm Up" className="slick-slide slick-cloned" data-slick-index={6} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-24980" data-pk={24980} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_22035.jpg" style={{backgroundImage: 'url("/upload/announcement/image_22035.jpg")'}} />
                                    <p ui-key="title">IPL Warm Up</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Sky247 Weekly Winning Boost" className="slick-slide slick-cloned" data-slick-index={7} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-23635" data-pk={23635} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_19565.jpg" style={{backgroundImage: 'url("/upload/announcement/image_19565.jpg")'}} />
                                    <p ui-key="title">Sky247 Weekly Winning Boost</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Sky247 Welcome Offer" className="slick-slide slick-cloned" data-slick-index={8} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19452" data-pk={19452} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_19472.jpg" style={{backgroundImage: 'url("/upload/announcement/image_19472.jpg")'}} />
                                    <p ui-key="title">Sky247 Welcome Offer</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Weekly Premium Free Bet" className="slick-slide slick-cloned" data-slick-index={9} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19822" data-pk={19822} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20350.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20350.jpg")'}} />
                                    <p ui-key="title">Weekly Premium Free Bet</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Fancy Deposit Bonus" className="slick-slide slick-cloned" data-slick-index={10} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19826" data-pk={19826} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20353.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20353.jpg")'}} />
                                    <p ui-key="title">Fancy Deposit Bonus</p>
                                </a>
                            </li>
                            <li style={{width: '289px'}} title="Daily Casino Cashback" className="slick-slide slick-cloned" data-slick-index={11} aria-hidden="true" tabIndex={-1}>
                                <a data-id="promotion-19821" data-pk={19821} data-type={0} data-messagetype={3} tabIndex={-1}>
                                    <div className="member-banner-slider data-image" data-image="/upload/announcement/image_20356.jpg" style={{backgroundImage: 'url("/upload/announcement/image_20356.jpg")'}} />
                                    <p ui-key="title">Daily Casino Cashback</p>
                                </a>
                            </li>
                        </div>
                    </div>
                    <ul className="slick-dots" role="tablist" style={{display: 'block'}}>
                        <li className role="presentation"><button type="button" role="tab" id="slick-slide-control40" aria-controls="slick-slide40" aria-label="1 of 2" tabIndex={0} aria-selected="true">1</button></li>
                        <li role="presentation" className="slick-active"><button type="button" role="tab" id="slick-slide-control41" aria-controls="slick-slide43" aria-label="2 of 2" tabIndex={-1}>2</button></li>
                    </ul>
                </ul>
            </div>
        </div>
        <div style={{display: 'none'}}>
            <ul>
                <li id="profile_promotion_template" style={{width: '32%'}}>
                    <a data-id data-pk data-type data-messagetype={3}>
                        <div className="member-banner-slider data-image" data-image style={{backgroundImage: 'url("")'}} />
                        <p ui-key="title" />
                    </a>
                </li>
            </ul>
        </div>
        <div className="personal-info" noValidate="novalidate">
            <ul className="personal-data">
                <li>
                    <i className="data-image" id="profile-bg" data-image="/images/communication/black/icon-member.png"  />
                    <p id="userName">dewan sha</p>
                </li>
            </ul>
            <ul className="personal-data">
                <li>
                    <i  id="phone-bg"  />
                    <p>
                        9223287238
                    </p>
                </li>
            </ul>
            <ul className="personal-data">
                <li id="email">
                    <i className="data-image" data-image="/images/communication/black/icon-mail.png" id="email-bg" />
                    <p id="email2">neetupandey969@gmail.com</p>
                </li>
            </ul>
            <ul className="personal-data">
                <li id="birthday" className="need-verify">
                    <i className="data-image" data-image="/images/communication/black/icon-birthday.png" id="birthday-bg" />
                    <p id="dateOfBirth2">
                        <ui-translate key="fe.text.profile.edit.birthday" lang="en">Edit Birthday</ui-translate>
                    </p>
                </li>
            </ul>
            <ul className="personal-data">
            </ul>
        </div>
    </main>
</div>
        </React.Fragment>
    )
}
